import React, { useEffect } from 'react';
// import why from '../../assests/Intergration/first.webp';
// import who from '../../assests/Intergration/second.webp';
// import quick from '../../assests/Intergration/third.webp';
// import banner from '../../assests/Intergration/man-wix.webp';
// import { GiRocketFlight } from 'react-icons/gi';
import { MdLocalShipping } from 'react-icons/md';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { TbHeartHandshake } from 'react-icons/tb';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Col, Container, Row } from 'react-bootstrap';
import wix from '../../assests/wix.jpeg';
import shipfrom from '../../assests/shipfrom.jpeg';
import profitwix from '../../assests/profitwix.jpeg';
function Wix() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <div className="intergration-shopify-flex">
            <div className="intergration-shopify">
              <div>
                <h1 className="Dropshipp-text" data-aos="fade-up">
                  <b> Boost Your Wix Dropshipping</b>{' '}
                  <span style={{ color: '#ff4a17' }}>
                    <b>Business</b>
                  </span>
                </h1>
                <p className="card-text py-4">
                  Connect your Wix store with Dockify and effortlessly find
                  products and sync orders.
                </p>
                <div className="connect-box">
                  <a href="https://app.dockify.co/" target="blank">
                    <h5 className="connect-store">
                      Connect My Store Now
                      <IoIosArrowRoundForward className="connect-arrow" />
                    </h5>
                  </a>
                </div>
              </div>
              <div>
                <img
                  src={wix}
                  className="shopfiy-banner"
                />
              </div>{' '}
            </div>
            <div className="intergration-features">
              <div>
                <img src={shipfrom} />
              </div>{' '}
              <div>
                <h3 className="Dropshipp-text p-3">
                  <b> Find Quality Dropship Suppliers for Your Wix Store</b>
                </h3>
                <p className="card-text p-3">
                  Wix dropshipping is easy with the right dropshipping partner
                  by your side. Dockify has gone the extra mile to increase its
                  number of vetted suppliers; with thousands of dropshipping
                  suppliers based in the USA and globally, you’ll have access to
                  high-quality products to sell with fast shipping.
                </p>
              </div>
            </div>{' '}
            <div className="intergration-features">
              <div>
                <h3 className="Dropshipp-text p-3">
                  <b> Import Winning Dropshipping Products to Your Wix Store</b>
                </h3>
                <p className="card-text p-3">
                  Dockify is a leading dropshipping site for Wix; with millions of
                  products covered 20+ categories, finding trending and
                  profitable items to sell through Wix dropshipping has never
                  been that straightforward!
                </p>
              </div>
              <div>
                <img src={profitwix} />
                
              </div>
            </div>{' '}
            {/* <div className='intergration-features'>
          <div>
            <img src={quick} />
          </div>{' '}
          <div>
            <h5 className='shopify-intro1'>
              Quickly Finding Products to Dropship on Shopify
            </h5>
            <p className='shopify-para1'>
              Finding winning Shopify dropshipping products can be challenging.
              But worry not. With millions of products available on Dockify, you
              can easily find your niche products using our advanced filter. And
              our in-house experts are here to give you insights on trending
              products from time to time as well, taking your business to the
              next level.
            </p>
          </div>
        </div> */}
            <div className="">
              <div className="Benefits-box">
                <h1 className=" Dropshipp-text text-center">
                  <b>
                    {' '}
                    Benefits of Wix Dropshipping <br /> with Dockify
                  </b>
                </h1>
              </div>
              <div className="Benefits-gird">
                {/* <div>
              <div>
                <div className='Automation Automation-img'>
                  <GiRocketFlight className='icons-fa' />
                </div>
                <div className='Automation Automation-para'>
                  Dropshipping Automation
                </div>
                <p className='shopify-para2'>
                  Shopify dropshipping success is dropshipping automation. With
                  Dockify’s integration with Shopify, we save you from
                  time-consuming work.
                </p>
              </div>
            </div> */}
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <MdLocalShipping className="icons-fa" />
                    </div>
                    <div className="Automation">
                      <h3 className="Dropshipp-text">
                        {' '}
                        <b> Fast Shipping</b>
                      </h3>
                    </div>
                    <p className="card-text p-3">
                      With fast USA local shipping and thousands of suppliers
                      globally, you can stay competitive with your Shopify
                      dropshipping store using Dockify.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <TbHeartHandshake className="icons-fa" />
                    </div>
                    <div className="Automation ">
                      <h3 className="Dropshipp-text">
                        {' '}
                        <b> Reliable Suppliers</b>
                      </h3>
                    </div>
                    <p className="card-text p-3">
                      Dockify works with reliable suppliers to ensure you
                      high-quality products and fulfill your Shopify
                      dropshipping orders timely, with fair return policies.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <FaMoneyBillAlt className="icons-fa" />
                    </div>
                    <div className="Automation">
                      <h3 className="Dropshipp-text">
                        {' '}
                        <b>Great Profit</b>
                      </h3>
                    </div>
                    <p className="card-text p-3">
                      Our suppliers provide you with discounted prices to stand
                      out from other competitors and gain maximum margins.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shopify-connect-box text-center ">
            <h1 className="Dropshipp-text py-5">
              <b className="text-white">
                {' '}
                Grow Your Wix Dropshipping Business with
                <br /> Dockify Now
              </b>
            </h1>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Wix;
