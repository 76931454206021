import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  RiQuestionAnswerFill,
  RiFlightTakeoffFill,
  RiNumbersFill,
  RiPencilRuler2Fill,
} from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import Howitswork from './Howitswork';
import Startyour from './Startyour';
import Optimize from './Optimize';
import Scale from './Scale';

function AppDropship() {
  const [state, setState] = useState(1);
  const action = (index) => {
    setState(index);
  };

  const [first, setfirst] = useState(<Howitswork />);
  function fun(i) {
    switch (i) {
      case 1:
        return setfirst(<Howitswork />);

      case 2:
        return setfirst(<Startyour />);

      case 3:
        return setfirst(<Optimize />);

      case 4:
        return setfirst(<Scale />);
    }
  }

  return (
    <div id="dropship">
      <Container>
        <Row className="mt-5">
          <Col className="mt-5">
            <div data-aos="zoom-in" data-aos-duration="1000" className="mt-3">
              <div>
                <h2 className="about">Services</h2>
                <h2 className="bor d-flex"></h2>
              </div>
              <h1 className="">INTERGRATION SERVICES</h1>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            className="col-lg-12 d-flex justify-content-evenly col-md-12 col-sm-12 box-sized"
            data-aos="zoom-in-up"
          >
            <div
              className={`'flex-container text-center cent boxed' '${
                state === 1 ? ' tab active-tab ' : ' tab '
              }`}
              role="button"
              onClick={() => {
                action(1), fun(1);
              }}
            >
              <NavLink role="button">
                <i className={state === 1 ? 'iconed-color' : 'iconed'}>
                  <RiQuestionAnswerFill />
                </i>
                <p className={state === 1 ? 'port' : 'port-color'}>
                  How Dockify Works?
                </p>
              </NavLink>
            </div>
            <div
              className={`'flex-container text-center cent boxed'${
                state === 2 ? ' tab active-tab' : ' tab'
              }`}
              role="button"
              onClick={() => {
                action(2), fun(2);
              }}
            >
              <NavLink
                role="button"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <i className={state === 2 ? 'iconed-color' : 'iconed'}>
                  <RiFlightTakeoffFill />
                </i>
                <p className={state === 2 ? 'port' : 'port-color'}>
                  Start Your E-Commerce Business
                </p>{' '}
              </NavLink>
            </div>
            <div
              className={`'flex-container text-center cent boxed'${
                state === 3 ? ' tab active-tab' : ' tab'
              }`}
              role="button"
              onClick={() => {
                action(3), fun(3);
              }}
            >
              <NavLink
                className="box-box"
                role="button"
                onClick={() => fun(3)}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <i className={state === 3 ? 'iconed-color' : 'iconed'}>
                  <RiNumbersFill />
                </i>
                <p className={state === 3 ? 'port' : 'port-color'}>
                  Optimize Your Operations
                </p>{' '}
              </NavLink>
            </div>
            <div
              className={`'flex-container text-center cent boxed'${
                state === 4 ? ' tab active-tab' : ' tab'
              }`}
              role="button"
              onClick={() => {
                action(4), fun(4);
              }}
            >
              <NavLink
                role="button"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <i className={state === 4 ? 'iconed-color' : 'iconed'}>
                  <RiPencilRuler2Fill />
                </i>
                <p className={state === 4 ? 'port' : 'port-color'}>
                  Scale Your Brands Without Limits
                </p>{' '}
              </NavLink>
            </div>
          </Col>
        </Row>
        {first}
      </Container>
    </div>
  );
}

export default AppDropship;
