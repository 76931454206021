import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import intergrate from '../../assests/Intergration/Intregration.png';

function AppHome() {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const getUrl = new URLSearchParams(window.location.search);
    const emailFromUrl = getUrl.get('email');
    if (emailFromUrl) {
      setEmail(emailFromUrl);
      fetch('https://gateway.dockify.co/EmailAutomation/SubScriber', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify({ email: emailFromUrl }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Email sent successfully:', data);
          console.log(email);
        })
        .catch((error) => {
          console.error('Error sending email:', error);
        });
    }
  }, []);

  document.addEventListener('DOMContentLoaded', function () {
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    var linkToTop = document.querySelector('a[href="/pricing"]');
    if (linkToTop) {
      linkToTop.addEventListener('click', function (event) {
        event.preventDefault();
        scrollToTop();
      });
    }

    var productElement = document.getElementById('pricing');
    if (productElement) {
      scrollToTop();
      productElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  });
  return (
    <div id="home" className="home">
      <Container>
        <Row>
          <Col md={6} sm={12} lg={6} xxl={6}>
            <div className="">
              <div>
                <h1 className="mt-5 Dropshipp-text" data-aos="fade-up">
                  <b>DROPSHIPPING</b>{' '}
                  <span style={{ color: '#ff4a17' }}>
                    <b>SIMPLIFIED</b>
                  </span>
                </h1>
                <div className="mt-5" data-aos="fade-right">
                  <h6 style={{ color: '#ff4a17' }} className="textt">
                    <div className="d-flex justify-content-between">
                      <div className="text-start" style={{ width: 130 }}>
                        CONNECT&nbsp;-&nbsp;
                      </div>
                      <div className="">
                        <span style={{ color: 'black' }}>
                          Starting your DropShipping Business is like a piece of
                          Cake by connecting with Dockify.
                        </span>
                      </div>
                    </div>
                  </h6>
                  <h6 className="textt mt-4" style={{ color: '#ff4a17' }}>
                    <div className="d-flex justify-content-between">
                      <div style={{ width: 147 }}>INTEGRATE&nbsp;-&nbsp;</div>
                      <div className="">
                        <span style={{ color: 'black' }}>
                          Dockify supports multiple Online Stores & Marketplace
                          integrations in a simple One Click process.
                        </span>
                      </div>
                    </div>
                  </h6>
                  <h6 className="textt mt-4" style={{ color: '#ff4a17' }}>
                    <div className="d-flex justify-content-between">
                      <div style={{ width: 170 }}>EVOLVE&nbsp;-&nbsp;</div>
                      <div>
                        <span style={{ color: 'black' }}>
                          Helping businesses to evolve in a realistic, friendly
                          and simple way. Come and experience the brilliance of
                          Dockify.
                        </span>
                      </div>
                    </div>
                  </h6>
                </div>
                <div className="d-flex justify-content-start mt-5 ">
                  <a
                    href="https://app.dockify.co/"
                    className="started py-2"
                    data-aos="fade-right"
                    data-aos-duration="500"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TRY FOR Free
                  </a>
                </div>
                <div
                  className="d-flex mt-5 justify-content-center"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                ></div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12} lg={6} xxl={6}>
            <div>
              <img src={intergrate} alt="Integration" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="marquee">
              <div className="marquee--inner">
                <span className="left-span">
                  <div className="orb">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnnYrYsFUbtX21hd8Uqg15noYi5-GqDSi6VAgE-Ptxxg&s"
                      alt="integration 1"
                    />
                  </div>
                  <div className="orb">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrF1sd2u-E_LUYlRvxSUwGO0w1P4tqxw-CItogfH4F&s"
                      alt="integration 2"
                    />
                  </div>
                  <div className="orb">
                    <img
                      src="https://eniture.com/wp-content/uploads/2023/11/bigcommerce-gray.png"
                      alt="integration 3"
                    />
                  </div>
                  <div className="orb">
                    <img
                      src="https://eniture.com/wp-content/uploads/2023/11/shopify-gray.png"
                      alt="integration 4"
                    />
                  </div>
                  <div className="orb">
                    <img
                      src="https://logos-world.net/wp-content/uploads/2020/10/Wix-Logo-2012-2013.png"
                      alt="integration 5"
                    />
                  </div>
                </span>
                <span className="left-span">
                  <div className="orb">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnnYrYsFUbtX21hd8Uqg15noYi5-GqDSi6VAgE-Ptxxg&s"
                      alt="integration 1"
                    />
                  </div>
                  <div className="orb">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrF1sd2u-E_LUYlRvxSUwGO0w1P4tqxw-CItogfH4F&s"
                      alt="integration 2"
                    />
                  </div>
                  <div className="orb">
                    <img
                      src="https://eniture.com/wp-content/uploads/2023/11/bigcommerce-gray.png"
                      alt="integration 3"
                    />
                  </div>
                  <div className="orb">
                    <img
                      src="https://logos-world.net/wp-content/uploads/2020/10/Wix-Logo-2012-2013.png"
                      alt="integration 5"
                    />
                  </div>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AppHome;

{
  /* <div className='navbar' id='home'>
      <div className='text-center '>
        <div data-aos='fade-up'>
          <h1 className='text mt-5'>
            <b className='text-center'>DROPSHIPPING</b>{' '}
            <span style={{ color: '#ff4a17' }}>
              <b>SIMPLIFIED</b>
            </span>
          </h1>
        </div>
        <div className='flex-column d-flex justify-content-center mt-5 px-4'>
          <h2 data-aos='fade-left' data-aos-duration='1000'>
            <b className='py-5' style={{ color: '#ff4a17' }}>
              CONNECT
            </b>{' '}
            -{' '}
            <span className='textt'>
              Starting your DropShipping Business is like a piece of Cake by
              connecting with Dockify.
            </span>
          </h2>
          <h2 className='mt-3' data-aos='fade-left' data-aos-duration='1000'>
            <b style={{ color: '#ff4a17' }}>INTEGRATE</b> -{' '}
            <span className='textt mt-3'>
              Dockify supports multiple Online Stores & Marketplace integrations
              in a simple One Click process.
            </span>
          </h2>
          <h2 className='mt-3' data-aos='fade-left' data-aos-duration='1000'>
            <b style={{ color: '#ff4a17' }}>EVOLVE</b> -{' '}
            <span className='textt mt-3'>
              Helping businesses to evolve in a realistic, friendly and simple
              way. Come and experience the brilliance of Dockify.
            </span>
          </h2>
          <div className='d-flex justify-content-center mt-5 '>
            <a
              href='#about'
              className='started py-2'
              data-aos='fade-right'
              data-aos-duration='500'
            >
              Get Started
            </a>
          </div>
          <div
            className='d-flex mt-5 justify-content-center '
            data-aos='fade-left'
            data-aos-duration='1000'
          >
            <h2>Supported Integrations</h2>
          </div>
        </div>
      </div>
      </div>
      </Col>
      </Row>
      <Row>
          <Col
            className=' imlogo col-lg-12 col-sm-12 col-12 d-flex justify-content-evenly'
            data-aos='zoom-in'
            data-aos-duration='1000'
          >
            <img src='./img/clients/client-7.png' className='web imlogo' />
            <img src='./img/clients/client-8.png' className='web imlogo' />
            <img src='./img/clients/client-9.png' className='web imlogo' />
            <img src='./img/clients/client-10.png' className='web imlogo' />
            <img src='./img/clients/client-11.png' className='web imlogo' />
            <img src='./img/clients/client-12.png' className='web imlogo' /> */
}
