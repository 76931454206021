import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Howitswork() {
  return (
    <div id="#tab">
      <Container>
        <Row className="mt-5">
          <Col className="col-lg-6 col-sm-12 col-12 " data-aos="fade-right">
            <div className="Dropship-text">
              <h3>
                Dockify makes running dropshipping business as easy as a Pie!
              </h3>
              <p className="fst-italic Dropship-text">
                Dockify is the one-stop platform to help you Start, Optimize &
                Scale Successful dropshipping business.
              </p>
              <ul className="justify-content-start Dropship-text">
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li className="listing">
                      <b className="span-text">Register</b> -{' '}
                      <span className="span-text-1">
                        Create a trial account or select a Subscription plan as
                        required.
                      </span>
                    </li>
                  </div>
                </div>{' '}
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Connect your Store</b> -{' '}
                      <span className="span-text-1">
                        Easily upload or sync products to popular e-commerce
                        platforms and marketplaces.
                      </span>
                    </li>
                  </div>
                </div>
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">
                        Sell to Customers, Buy through Dockify
                      </b>{' '}
                      -{' '}
                      <span className="span-text-1">
                        Sell the products at retail price, then pay the
                        wholesale price to the supplier through Dockify
                        platform.
                      </span>
                    </li>
                  </div>
                </div>
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Repeat for Profit</b> -{' '}
                      <span className="span-text-1">
                        Find and offer more products for your customers and
                        close more deals to get more profits!
                      </span>
                    </li>
                  </div>
                </div>
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Discover Products</b> -{' '}
                      <span className="span-text-1">
                        Look for products that match your niche from our
                        millions of product catalogue and add them to your own
                        store.
                      </span>
                    </li>
                  </div>
                </div>
              </ul>
              <p data-aos="fade-right" className="mt-5">
                Click here to know more about the Integrations Services
                &nbsp;&nbsp;
                <Link to="/intergration" className="started ">
                  Integration
                </Link>
              </p>
            </div>
          </Col>
          <Col className="col-lg-6 col-sm-12 col-12">
            <div data-aos="flip-left" data-aos-duration="1500">
              <img src="./img/features-1_v2.jpg" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Howitswork;
