import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  BsCheckAll,
  BsPencilSquare,
  BsBezier,
  BsPersonBadge,
  BsCart4,
  BsCurrencyExchange,
  BsFillSignpost2Fill,
} from 'react-icons/bs';


function Apppartnership() {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col className='navig col-12'>
            <div className='d-flex justify-content-between px-5 py-3 nav-content'>
              <h4 id='homeed' className='homeed'>
                <Link className='dockify-home' to='/'>
                  DOCKIFY
                </Link>
              </h4>
              <div>
                {/* <p className='mx-5 '>
                  <a href='' className='linked'>
                    LOGIN{' '}
                  </a>
                  &nbsp;&nbsp; / &nbsp;&nbsp;
                  <a href='' className='linked'>
                    GET STARTED
                  </a>
                </p> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <div className='retails d-flex justify-content-between align-items-center nav-content'>
            <p className='interretail mx-5 nav-content'>
              Partnership - Suppliers
            </p>
            {/* <p className='mx-5 '>
              <a href='/' className='links'>
                Home{' '}
              </a>
              &nbsp;&nbsp; / &nbsp;&nbsp;
              <a href='' className='links'>
                Integrations
              </a>
            </p> */}
          </div>
        </Row>
        <Row>
          <Col>
            <div className='d flex justify-content-start mx-5 mt-5'>
              <p className='droptext'>
                Dockify’s Vision is to help Ordinary people become
                Extra-Ordinary Entrepreneurs. Our platform connects Suppliers &
                Retailers with their Users without any hidden agenda!
                <br />
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className='mt-5'>
            <div>
              <h2>
                Dockify makes running E-Commerce business as easy as a Pie!
              </h2>
              <h2 className='bor d-flex'></h2>
              <p className='mt-3'>
                Dockify is the one-stop platform to help you Start, Optimize &
                Scale Successful E-Commerce Business.
              </p>
              <ul className='justify-content-start mt-4'>
                <li className='mt-3'>
                  {' '}
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Fulfill orders and get paid</b> - Fulfill your orders and
                  automatically get paid through Spocket’s secure payment
                  system.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Disclaimer</b> - Please note that Spocket is a curated
                  platform, therefore only qualified suppliers will be approved
                  on the platform.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Publish products </b> - Publish your selected products on
                  Spocket, and you’ll have full control over which products you
                  would like to sell online.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Upload products</b> - Upload your products to Spocket or
                  integrate your Shopify store to import products in minutes,
                  and start selling your products to retailers
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>100% FREE</b> - No set up fees, no commission fees, no
                  hidden fees at all! Spocket gets paid by our happy retailers
                  who source items from our great selection of suppliers.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Sell your products to thousands of online retailers</b> -
                  Spocket has over 60,000 retailers to sell your products online
                  at all hours of the day, it’s like having a massive sales
                  team!
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Manage your existing retailers</b> - Do you already have
                  retailers who love selling your products online? Refer them to
                  Spocket so you can both enjoy seamless product and order
                  integration. Earn commission for every new user you bring to
                  Spocket!
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>A fast and easy way to sell your products online</b> -
                  Spocket’s system integrates seamlessly with Shopify, allowing
                  automatic product import as well as inventory and order sync.
                  If you do not have a Shopify store, we can also upload your
                  products in bulk using CSV datafeed.
                </li>
                {/* <li className="mt-3"><i className="icon"><BsCheckAll/></i><b>Repeat for Profit</b> - Find and offer more products for your customers and close more deals to get more profits!</li> */}
              </ul>
            </div>
          </Col>
        </Row>

        <Row className='mt-5'>
          <div className='  ' data-aos='zoom-in' data-aos-duration='1000'>
            <h2 className='about d-flex borr'>PARTNERSHIP</h2>
            <h2 className='bor d-flex'></h2>
            <h2 className='retailers'>SUPPLIERS</h2>
          </div>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 px-3 py-3 '>
              <div></div>
              <p>
                <BsPencilSquare />
                &nbsp;&nbsp;Agreement
              </p>
              <p>
                {' '}
                Agreement is signed between Retailers and Dockify just to have
                the defined set of rules to follow and use it as a reference for
                using the services offered. The conditions are defined in the
                terms document.
              </p>
            </div>
          </Col>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 px-3 py-3'>
              <p>
                <BsBezier />
                &nbsp;&nbsp;Category Hierarchy
              </p>
              <p>
                {' '}
                All major types of product heirarchies are available to select
                from. We have multiple manufacturers of the same type to provide
                more diverse list of products to our retailers and ideally for
                their customers.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 py-3 px-3'>
              <p>
                <BsPersonBadge />
                &nbsp;&nbsp;Partner Support
              </p>
              <p>
                As a business partner, we will always be available to you and
                help you resolve any issue or situation. Whenever you need help
                or assistance, just drop us an email and we will take care of
                the same.
              </p>
            </div>
          </Col>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 py-3  px-3'>
              <p>
                <BsCart4 />
                &nbsp;&nbsp;Category Sales
              </p>
              <p>
                We have several different product categories available to select
                from. If your products do not fit in any of our categories,
                please do not worry, we will create your own category. We are
                accommodating that way!!
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 py-3 px-3'>
              <p>
                <BsCurrencyExchange />
                &nbsp;&nbsp;Price Range
              </p>
              <p>
                Dockify do not set any restrictions to the maximum price you
                could sell the product, it entirely depends on the marketing
                prowess of your team. However we do not entertain the price to
                be listed below the MAP.
              </p>
            </div>
          </Col>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 py-3  px-3'>
              <p>
                <BsFillSignpost2Fill />
                &nbsp;&nbsp;Competitors
              </p>
              <p>
                If we do not have your own favourite manufacturer listed, please
                do let us know so that we can take necessary steps to include
                them in our product catalogue so that you can use their products
                in your store.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
}

export default Apppartnership;
