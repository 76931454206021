import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Terms() {
  return (
    <div>
      <Container>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className='privacy-box px-5 py-3'>
              <h4
                className='text-center privacy-box'
                style={{ textDecoration: 'underline' }}
              >
                Terms and Use
              </h4>
              <h5>1. Acceptance of terms and Conditions; License</h5>
              <p>
                By using the Services, you are expressing your acceptance of and
                willingness to be legally bound by these Terms, as amended from
                time to time. Your use of a particular Dockify webpage included
                within the Dockify website may also be subject to additional
                terms outlined elsewhere on such webpage. You may not use the
                Services without accepting these Terms. Individuals must be 18
                years of age or older to use the Services, or, if under 18, be
                at least 13 years old and have the consent and active
                involvement of a parent or legal guardian. If you are agreeing
                to these Terms on behalf of a legal entity, you represent to
                Dockify that you have the authority to bind such entity and its
                affiliates to these terms. In such, case the term “you” or
                “your” as used herein will also refer to such entity and its
                affiliates. Subject to your compliance with these Terms, Dockify
                grants you a personal, limited, non-exclusive, non-transferable,
                non-sublicensable, revocable license to access and use the
                Services during the term of your Membership
              </p>
              <h5>2. Changes to Terms and Conditions</h5>
              <p>
                Dockify may, from time to time and in its sole and absolute
                discretion, amend or modify these Terms with or without notice
                to you; provided that Dockify will use commercially reasonable
                efforts to give you at least 14 days’ prior notice on Dockify’ s
                website or via email of any such amendment or modification. The
                most current version of these Terms will supersede all previous
                versions, and your use or continued use of the Services will
                constitute your acceptance of and willingness to be bound by
                such version of these Terms. The most current version of these
                Terms may be viewed at any time by clicking on the “Terms of
                Use” link at the bottom of Dockify’ s website.
              </p>
              <h5>3. Your Information and Content</h5>
              <p>
                As part of the Membership registration process (including free
                trials, as described below), you will be required to provide
                certain personal identifying and financial information in
                connection with your Dockify account. You may also add
                additional personal information to your account profile at your
                discretion (such information is not required to use the Services
                but may improve the user experience). All information you
                provide to Dockify, directly or indirectly, is subject to
                Dockify’ s Privacy Policy, which policy you agree to by your use
                of the Services, so please review it carefully to understand
                Dockify’ s privacy practices.
                <br />
                <br />
                Subject to Dockify’ s Privacy Policy, Dockify is free to use any
                comments, information, ideas, concepts, reviews, and/or
                techniques provided by you to Dockify or its affiliates (e.g.,
                via communications or questionnaires directly with Dockify or
                post or comments made on Dockify’ s website), directly or
                indirectly (collectively, “User Content”), without any form of
                compensation, acknowledgment or payment to you.
                <br />
                <br />
                Not withstanding anything to the contrary, Dockify shall have
                the right to collect and analyse data and other information
                relating to the provision, use, and performance of various
                aspects of the Services and related systems and technologies
                (including, without limitation, information concerning your User
                Content and data derived therefrom), and Dockify will be free
                (during and after the term hereof) to (a) use such information
                and data to improve and enhance the Services and for other
                development, diagnostic and corrective purposes in connection
                with the Services and other Dockify offerings, and (b) disclose
                such data solely in aggregate or another de-identified form in
                connection with its business. In furtherance of the foregoing,
                if you provide any suggestions, enhancement requests,
                recommendations, comments, or other feedback relating to the
                Services to Dockify, you agree that Dockify and its affiliates
                may freely use, reproduce, license, distribute, and otherwise
                commercialize the feedback in connection with the Services
                and/or for any other lawful purpose, on a royalty-free,
                worldwide, transferable, sublicensable, irrevocable, perpetual
                basis. Dockify will not be obligated to implement any feedback
                or correct any defects, bugs, or errors in the service
                identified in the feedback or otherwise.
                <br />
                <br />
                In addition to the foregoing, you hereby also grant to Dockify a
                nonexclusive, worldwide, transferable, sublicensable (to its
                subcontractors and service providers), irrevocable,
                royalty-free, fully paid-up license to access, use, display,
                reproduce, modify and process your User Content in accordance
                with these Terms and Dockify’ s Privacy Policy, as each it s
                amended from time to time.
              </p>
              <h5>4. Restrictions and Responsibilities</h5>
              <p>
                You will not, directly or indirectly: reverse engineer,
                decompile, disassemble or otherwise attempt to discover the
                source code, object code or underlying structure, ideas,
                know-how, or algorithms relevant to the Services or any
                software, documentation or data related to the Services
                (“Software”); modify, translate, or create derivative works
                based on the Services; use the Services for timesharing or
                service bureau purposes or otherwise for the benefit of a third
                party, or remove any proprietary notices or labels.
                <br />
                <br />
                Further, you may not remove or export from the United States or
                allow the export or re-export of the Services or anything
                related thereto, or any direct product thereof in violation of
                any restrictions, laws, or regulations of the{' '}
                <span style={{ color: 'red' }}>
                  United States Department of Commerce, the United States
                  Department of Treasury Office of Foreign Assets Control, or
                  any other United States or foreign agency or authority. As
                  defined in FAR section 2.101, the Software and documentation
                  are “commercial items” and according to DFAR section 252.227
                  7014(a)(1) and (5) are deemed to be “commercial computer
                  software” and “commercial computer software documentation.”
                  Consistent with DFAR section 227.7202 and FAR section 12.212,
                  any use modification, reproduction, release, performance,
                  display, or disclosure of such commercial software or
                  commercial software documentation by the U.S. Government
                </span>
                will be governed solely by the terms of these Terms and will be
                prohibited except to the extent expressly permitted by the terms
                of these Terms.
                <br />
                <br />
                You represent covenant and warrant that you will use the
                Services only in compliance with Dockify’ s standard published
                policies then in effect (the “Policy”) and all applicable laws
                and regulations. Although Dockify has no obligation to monitor
                your use of the Services, Dockify may do so and may prohibit any
                use of the Services it believes may be (or alleged to be) in
                violation of the foregoing.You shall be responsible for
                obtaining and maintaining any equipment and ancillary services
                needed to connect to, access, or otherwise use the Services,
                including, without limitation, modems, hardware, servers,
                software, operating systems, networking, web servers, and the
                like (collectively, “Equipment”). You shall also be responsible
                for maintaining the security of the Equipment, your account,
                passwords and files, and for all uses of your account or the
                Equipment with or without your knowledge or consent.You are not
                permitted to (a) transfer or otherwise make the Services
                available to any third party; (b) provide any service based on
                the Services without our prior written permission; (c) post
                links to third-party sites or use their logo, company name, etc.
                without their prior written permission; or (d) use the Services
                for spamming and/or other illegal purposes.You agree that you
                will not upload to the Services or create any content that
                depicts violence, nudity, partial nudity or is unlawful,
                sexually suggestive, pornographic, offensive, discriminatory,
                threatening, libellous, defamatory, obscene, or otherwise
                violates any third party’s rights, including intellectual
                property and/or privacy rights, or that otherwise violates these
                Terms.
              </p>
              <h5>5. Membership and billing</h5>
              <p>
                After registering for and “logging on” to your Dockify account,
                you can review and modify the specific details of your
                Membership status, purchases, and billing information at any
                time by clicking the “Pricing Plan” link in your Dockify account
                webpage. Without limiting the following, you agree to Dockify’ s
                <Link to=''> Fee and Cancellations Policy</Link>
              </p>
              <ul style={{ listStyle: 'unset' }}>
                <li>Free Trials</li>
                <p>
                  Dockify doesn’t encourages the initial use of the Services
                  through a “free trial” Membership.{' '}
                  <span style={{ color: 'red' }}>
                    Free trials are only available to first-time users of the
                    Services and cannot be combined with any other promotional
                    offer. Only one (1) free trial may be redeemed by you or
                    your affiliates (e.g., a retailer, such retailer’s
                    organization, and its agents).In order to redeem a free
                    trial offer, you must have Internet access. No charges will
                    be made against your Payment Method until the end of the
                    free trial period. The day you start your free trial (based
                    on Mountain time) is considered “Day 1” of the trial,
                    regardless of the time of day you register. Once the free
                    trial has ended, you must have a valid form of the currently
                    accepted payment method, which will be automatically billed
                    the Membership price applicable to the Plan for which you
                    were given a free trial.
                  </span>
                </p>
                <li>Membership Subscription</li>
                <p>
                  Dockify offers a number of Membership subscription plans that
                  may vary from time to time at the sole discretion of Dockify,
                  including special promotional plans with variable pricing,
                  subscription duration, and bundled products and services
                  (collectively, each a “Plan”). A given Plan may not be
                  available for your Membership at any given time, and Dockify
                  is under no obligation to offer you the same Plan offered or
                  subscribed to by another Dockify customer. Dockify reserves
                  the right to modify, terminate or otherwise amend offered
                  Plans. If a promotional Plan is made available to you during
                  your free trial and you elect to upgrade to such a promotional
                  Plan, you acknowledge that your free trial will terminate
                  immediately upon submission of payment in respect of such a
                  promotional Plan. To the extent third-party products and/or
                  services are bundled with a Plan, Dockify explicitly disclaims
                  any and all responsibility and liability with respect to such
                  third-party products and services. For Dockify Memberships
                  purchased over the phone: YOU, THE BUYER, MAY CANCEL THIS
                  CONTRACT AT ANY TIME PRIOR TO MIDNIGHT OF THE THIRD BUSINESS
                  DAY AFTER HE DATE OF THE TRANSACTION OR RECEIPT OF THE
                  PRODUCT, WHICHEVER IS LATER. All requests must be in writing
                  or email and must be postmarked or electronically date stamped
                  within the cancellation period. All requests for cancellation
                  must be sent via email to Customer Support or traditional mail
                  to <span style={{ color: 'red' }}>[ Postal address]</span>
                </p>
                <li>
                  Billing (for more information, please see Dockify’ s Fees and
                  Cancellations policy)
                </li>
                <p>
                  Upon registering for your Membership and selecting an
                  available Plan, your Payment Method will be charged an
                  automatic recurring monthly, quarterly, half yearly or yearly
                  subscription fee applicable to your selected Plan, any
                  applicable tax and any other charges you may incur in
                  connection with your use of the Services (collectively, and as
                  modified from time to time in the sole discretion of Dockify,
                  the “Subscription Fee”). The Subscription Fee is charged on
                  the monthly, quarterly, half yearly or annual anniversary of
                  the commencement of your Membership (or, if such date does not
                  occur in a given month, the last day of the applicable month)
                  automatically and without notice to you, unless and until
                  either you cancel or Dockify terminates your Membership. You
                  must cancel your Membership prior to the end of the
                  then-current billing period to avoid being charged for the
                  following billing period, and such amounts shall accrue
                  regardless of the validity of your Payment Method. You can
                  verify the date on which the next Subscription Fee will be
                  charged at any time by clicking the “Billing Details” link in
                  the “My Account” section of your Dockify account webpage.
                  <br />
                  <br />
                  ALL FEES AND CHARGES (INCLUDING SUBSCRIPTION FEES) ARE
                  NON-REFUNDABLE. Dockify reserves the right to modify
                  Subscription Fees and any other fees and charges in effect, or
                  add new fees and charges from time to time; provided that
                  Dockify will give you advance notice of these changes by email
                  or as a notification when you log in to the Dockify website.
                  You are responsible for all taxes associated with your use of
                  the Services and products purchased in connection therewith.
                  <br />
                  <br />
                  It is your responsibility to maintain a current Payment Method
                  on file.
                  <span style={{ color: 'red' }}>
                    {' '}
                    Payment Methods may be updated at any time by clicking the
                    “Change Payment Method” button in the “Pricing Plan” section
                    of your Dockify account page.
                  </span>
                  If any charge to your Payment Method fails, then (a) you grant
                  Dockify the right to re-attempt to process and bill your
                  Payment Method for up to 180 days, and (b) you will be
                  responsible to pay all fees and expenses (including
                  collections fees) incurred by Dockify in connection with
                  obtaining payment in full for all accrued but unpaid amounts.
                  Any amounts overdue by more than 30 days shall accrue interest
                  from the initial date of non-payment at a rate per annum equal
                  to the lesser of five percent (5%) and the highest rate
                  allowed under applicable law. Without limiting the foregoing,
                  Dockify may suspend or terminate your Membership and/or your
                  Dockify account at any time without notice in connection with
                  non-payment of Subscription Fees or any other amounts due to
                  Dockify hereunder
                </p>
                <li>
                  Cancellations (for more information, please see Dockify’ s
                  Fees and Cancellations policy)
                </li>
                <p>
                  <span style={{ color: 'red' }}>
                    You may cancel your free trial or your paid membership at
                    any time (a) on the Dockify website via your online
                    cancellation button, (b) via email to Dockify’ s customer
                    support, or (c) via online live chat with Customer Support
                  </span>
                </p>
                DOCKIFY DOES NOT PROVIDE REFUNDS OR CREDITS FOR ANY
                PARTIAL-MONTH PARTIAL-QUARTER OR YEAR SUBSCRIPTION PERIODS.
                Without limiting the foregoing, no refunds will be issued for
                the “Data Export” service.
                <br />
                <br />
                Dockify reserves the right to terminate your account for any or
                no reason with or without notice
              </ul>
              <h5>6. Account Access; Identity Protection</h5>
              <p>
                Subject to Dockify’ s Privacy Policy, in order to provide you
                with ease of access to your account, Dockify will place a cookie
                (a small text file) on any computer or device from which you
                access the Dockify website. When you revisit the Dockify
                website, this cookie will enable us to recognize you as a
                previous user or as the account holder and provide you with
                direct access to your account without requiring you to retype
                any password or other user identification. <br />
                <br />
                You are responsible for updating and maintaining the truth and
                accuracy of the information you provide to us relating to your
                account. You are also responsible for maintaining the
                confidentiality of your account and password and for restricting
                access to your computer and devices. You may not share your
                account with other people by providing them your password.
                However, if you decide to share your account with other people,
                you take full responsibility for their actions and liabilities
                that may arise therefrom. Users of public or shared computers
                should log out of the Dockify website at the completion of each
                visit
                <br />
                <br />
                If you discover that you are a victim of identity theft and that
                involves a Dockify account, you will notify Customer Support
                immediately. You should also report the identity theft to all of
                your credit card issuers, as well as your local law enforcement
                agency.
                <br />
                <br />
                Dockify reserves the right to suspend and terminate your account
                at any time with or without notice if Dockify believes that such
                an account is involved in fraudulent activity. You are not
                entitled to any refund or other consideration in connection with
                the suspension of your account by Dockify for any reason.
              </p>
              <h5>7. Product Sales; Other Services</h5>
              <p>
                This section applies to you in your capacity as a purchaser of
                products from Suppliers (as defined below) using the Services,
                even if you are also a Supplier. Suppliers are subject to
                additional terms and conditions which are made available to them
                in connection with qualifying to offer products through the
                Services.
              </p>
              <ul style={{ listStyle: 'unset' }}>
                <li>Product and Venue Disclaimers</li>
                <p>
                  The Services include a venue or marketplace to connect you and
                  other retailers with suppliers of wholesale and bulk products
                  (each, a “Supplier”). Although each Supplier is an authorized
                  distributor of the products it sells and is authorized to use
                  the Services to market and sell such products, Dockify does
                  not represent and explicitly disclaims, that any particular
                  product is suitable for resale by you or anyone else in any
                  given circumstance or jurisdiction.
                  <br />
                  <br />
                  <span style={{ color: 'red' }}>
                    You are solely responsible to ascertain a particular
                    product’s (a) suitability for resale under your particular
                    circumstances, no matter the resale venue you use (e.g.,
                    “brick & mortar” stores, an online store, online auctions,
                    etc.), (b) lawfulness in the jurisdiction in which you
                    desire to resell such product, (c) manufacturer terms and
                    conditions (including, without limitation, applicable
                    warranties and limitations on liability), and (d) applicable
                    manufacturer policies or minimum advertised price
                    restrictions.
                  </span>
                  <br />
                  <br />
                  In addition, Dockify may in its discretion, at any time and
                  for any or no reason, remove any product from the Services
                  without notice to you or to the applicable Supplier(s).
                </p>
                <li>Contacting Suppliers</li>
                <p>
                  <span>
                    Dockify will provide you with certain identifying
                    information about Suppliers, including a Supplier’s trade
                    name and brand(s), and may provide appropriate methods of
                    contacting a Supplier from within the Services.
                  </span>
                  <br />
                  <br />
                  Notwithstanding the foregoing, Dockify’ s agreement with a
                  Supplier may restrict Dockify from allowing you to contact
                  such Supplier directly. In such cases, Dockify will take
                  reasonable steps to acquire requested information from a
                  Supplier on your behalf
                  <br />
                  <br />
                  Regardless of whether you are permitted to contact a Supplier,
                  and in Dockify’ s sole discretion, your account may be
                  suspended or terminated if you or anyone on your behalf,
                  contacts or attempts to contact a Supplier outside of the
                  tools provided in the Services, if any, and/or attempts to
                  establish a direct relationship with a Suppliers outside of
                  the Services.
                </p>
                <li>Product Pricing</li>
                <p>
                  Dockify reserves the right to modify and adjust product prices
                  at any time and from time to time without notice or liability,
                  including, without limitation, to correct pricing and
                  typographical errors. You acknowledge that product prices
                  fluctuate as a result of Supplier pricing changes and that
                  Dockify has no control over any Supplier’s pricing, shipping
                  and handling charge policies, and other charges and fees,
                  which may result in continual and unpredictable product price
                  changes at any time
                </p>
                <li>Product Descriptions and Specifications</li>
                <p>
                  You acknowledge that all product specifications and
                  descriptions are provided solely by Suppliers and that Dockify
                  is not responsible for the accuracy of all such information
                  provided by Suppliers. Dockify makes no warranty, expressed or
                  implied, with respect to the accuracy of such information. In
                  addition, due to size, the volume of requests and resource
                  constraints, “Data Exports” may not be current at all times
                  and may be delayed or contain data that is older than what is
                  displayed on Dockify’ s website.
                  <br />
                  <br />
                  Supplier products and manufacturer names are listed for
                  identification purposes only and in no way represent an
                  endorsement of the applicable Supplier by such manufacturer.
                  It is your responsibility to ensure that a product’s
                  information is correct, authentic and aligns with the You
                  acknowledge that all product specifications and descriptions
                  are provided solely by Suppliers and that Dockify is not
                  responsible for the accuracy of all such information provided
                  by Suppliers. Dockify makes no warranty, expressed or implied,
                  with respect to the accuracy of such information. In addition,
                  due to size, the volume of requests and resource constraints,
                  “Data Exports” may not be current at all times and may be
                  delayed or contain data that is older than what is displayed
                  on Dockify’ s website.Supplier products and manufacturer names
                  are listed for identification purposes only and in no way
                  represent an endorsement of the applicable Supplier by such
                  manufacturer. It is your responsibility to ensure that a
                  product’s information is correct, authentic and aligns with
                  the manufacturer’s official description and specifications.
                  You further agree to promptly report to Dockify any incorrect
                  and/or fraudulent product listings and identify the allegedly
                  incorrect or fraudulent elements of such listing.
                </p>
                <li>Product Orders; Changes and Returns</li>
                <p>
                  When purchasing any product from a Supplier using the
                  Services, you agree that you are responsible for reading the
                  entire product listing and, by completing the product order
                  process, entering into a binding contract to purchase such
                  product from such Supplier. Dockify is not responsible for
                  losses or damages you may incur as a result of erroneous or
                  misplaced product orders or typographical errors connected to
                  any purchase or shipping information that you provide via the
                  Services. It is your sole responsibility to verify the
                  accuracy of each product order, including the actual item,
                  pricing, shipping and/or handling costs and any other
                  applicable terms prior to submitting an order. The foregoing
                  applies universally regardless of the method of order
                  placement (e.g., single orders, batch orders, APIs)
                  <br />
                  <br />
                  .Each Supplier determines its own return, replacement and
                  refund policies applicable to the products you purchase from
                  such Supplier and may modify those policies at any time. You
                  are responsible for becoming familiar with each such policy
                  prior to placing an order and agree to abide by such policies
                  with respect to applicable orders. Failure to comply with any
                  such policy may result in a rejected or reduced return,
                  preplacement or refund. You can view a Supplier’s policies at
                  any time by clicking the “Supplier Info” link in the
                  “Products” section of Dockify’ s website.
                  <span style={{ color: 'red' }}>
                    All payment for orders shall be made via your Payment Method
                    or by wire transfer; provided that Dockify may limit or
                    suspend wire transfers for orders over $2,000 or that
                    Dockify deems, in its sole and absolute discretion, to have
                    high fraud risk.
                  </span>
                  <br />
                  <br />
                  Generally, once an order is placed, it cannot be modified or
                  cancelled. However, under certain circumstances and in the
                  discretion of Dockify and, if applicable, the Supplier,
                  certain orders may be modified or cancelled; provided that you
                  email Customer Support as soon as possible with a change or
                  cancellation request. Dockify is not responsible for any
                  losses or damages you may incur as a result of any approved or
                  denied order modification or change request
                </p>
                <li>Shipping and Handling</li>
                <p>
                  Notwithstanding anything to the contrary, all product orders
                  are eligible for{' '}
                  <span style={{ color: 'red' }}>
                    shipment only within the continental United States, Alaska,
                    and Hawaii. All orders must be placed with a residential or
                    business shipping address (i.e., orders with P.O. Box, APO,
                    or FPO shipping addresses will not be processed).
                  </span>{' '}
                  Individual Suppliers may have additional shipping
                  restrictions.
                  <br />
                  <br />
                  You are responsible to pay the shipping and handling charges
                  for all products purchased through the Services, even if you
                  refuse or return a shipment. Odd-sized or excessively heavy
                  items may require custom shipping cost calculations, in which
                  event you will be required to approve such custom shipping
                  arrangements prior to any such order shipping. Dockify is not
                  responsible whatsoever for defective products, shipping
                  errors, or damage to products in transit.
                  <br />
                  <br />
                  Regardless of whether product specifications on Dockify’ s
                  website provide published shipping weight; such weights are
                  for informational purposes only and are not to be used for
                  estimating or calculating shipping costs or your financial
                  obligation with respect thereto. Please refer to the “Ship
                  Cost” amount located on product web pages and your “Shipping
                  Cart” to determine actual shipping costs
                </p>
                <li>Shipping and Handling</li>
                <p>
                  Dockify is not responsible for any warranties for products
                  purchased through the Services; please refer to the warranty
                  terms and conditions provided by the applicable product
                  manufacturers and their respective agents. All product issues,
                  claims and questions concerning warranties should be submitted
                  directly to the applicable manufacturer and/or, subject to
                  these Terms, the Supplier through use of the tools provided in
                  the Services. AS BETWEEN DOCKIFY AND YOU, THE PRODUCTS ARE
                  PROVIDED “AS IS” AND DOCKIFY DISCLAIMS ALL WARRANTIES WITH
                  RESPECT THERETO, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                  LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
                  FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                  <br />
                  <br />
                  To the extent, a product is a health-related device or
                  nutritional supplement, all listing information contained on
                  the product webpage, including information relating to medical
                  and health conditions, products, and treatments, is provided
                  by the manufacturer and/or Supplier for informational purposes
                  only and is not meant to be (a) a substitute for the advice of
                  a qualified physician or other medical professional or (b) be
                  used for prescribing medication or diagnosing a health
                  problem.{' '}
                  <span style={{ color: 'red' }}>
                    Statements regarding dietary supplements have not been
                    evaluated by the U.S. Food and Drug Administration and are
                    not intended to diagnose, treat, cure, or prevent any
                    disease or ailment
                  </span>
                </p>
                <li>Reselling; Minimum Advertised Pricing</li>
                <p>
                  As a reseller, it is your responsibility to obtain payment or
                  assurance of payment from your customers before ordering
                  products through the Services. Dockify strongly recommends
                  obtaining proper address verification systems for credit card
                  purchases from your customers and making sure all physical
                  payments therefrom are deposited and cleared before placing
                  the order through the Services. As described above, Dockify
                  product orders may or may not be cancelled and returns are
                  subject to applicable charges and fees. You agree to purchase
                  products through the Services for resale in compliance with
                  applicable law and all rules and regulations imposed by
                  applicable product manufacturers. Without limiting the
                  foregoing, some manufacturers may require strict adherence to
                  rules relating to product advertising (including use of a
                  manufacturer’s intellectual property).{' '}
                  <span style={{ color: 'red' }}>
                    {' '}
                    As such, some products may only be advertised at a “Minimum
                    Advertised Price” (a “MAP”). For all products subject to a
                    MAP, Dockify will provide two prices on a product’s webpage:
                    the wholesale cost and the MAP. As a reseller, you agree to
                    never advertise any products subject to a MAP to end
                    consumers for less than the applicable MAP. Products with a
                    MAP will be marked with an asterisk.
                  </span>
                  <br />
                  <br />
                  It is your responsibility to verify with the manufacturer a
                  product’s information, images, and descriptions prior to
                  marketing such product. Product images are subject to change
                  at any time and are not to be construed as exact
                  representations of any product
                </p>
              </ul>
              <h5>8.Reselling; Minimum Advertised Pricing</h5>
              <p>
                As a reseller, it is your responsibility to obtain payment or
                assurance of payment from your customers before ordering
                products through the Services. Dockify strongly recommends
                obtaining proper address verification systems for credit card
                purchases from your customers and making sure all physical
                payments therefrom are deposited and cleared before placing the
                order through the Services. As described above, Dockify product
                orders may or may not be cancelled and returns are subject to
                applicable charges and fees
                <br />
                <br />
                You agree to purchase products through the Services for resale
                in compliance with applicable law and all rules and regulations
                imposed by applicable product manufacturers. Without limiting
                the foregoing, some manufacturers may require strict adherence
                to rules relating to product advertising (including use of a
                manufacturer’s intellectual property).{' '}
                <span style={{ color: 'red' }}>
                  As such, some products may only be advertised at a “Minimum
                  Advertised Price” (a “MAP”). For all products subject to a
                  MAP, Dockify will provide two prices on a product’s webpage:
                  the wholesale cost and the MAP. As a reseller, you agree to
                  never advertise any products subject to a MAP to end consumers
                  for less than the applicable MAP. Products with a MAP will be
                  marked with an asterisk
                </span>
                It is your responsibility to verify with the manufacturer a
                product’s information, images, and descriptions prior to
                marketing such product. Product images are subject to change at
                any time and are not to be construed as exact representations of
                any product
              </p>
              <h5>9.Intellectual Property</h5>
              <p>
                Dockify shall own and retain all right, title, and interest in
                and to (a) the Services (including the Software), all
                improvements, enhancements or modifications thereto, (b) any
                software, applications, inventions or other technology developed
                as a result of or in connection with any support services, and
                (c) all intellectual property rights related to any of the
                foregoing. No title to or ownership of the Services, including,
                without limitation, any logos, designs, texts, graphics,
                pictures, data or content, software, platform, technologies or
                any proprietary rights related to the Services, is transferred
                under or by virtue of these Terms. Dockify reserves all rights
                in and to the service not expressly granted to you under these
                Terms. Further, these Terms do not authorize you to use any
                name, trademark or logo of Dockify. Dockify reserves the right
                to terminate your Membership without notice or liability to you
                if, in its sole and absolute discretion, Dockify believes that
                you are in violation of this paragraph.
                <br />
                <br />
                Without limiting the foregoing, reproductions and alterations of
                individual product images and descriptions may be used for
                marketing & reselling purposes only. You must receive written
                consent from Dockify for any other use or any modification of
                product images and other copyrighted materials.
                <br />
                <br />
                Any images of persons or personalities contained on the Dockify
                website shall not be an indication of endorsement of any
                particular product or the Services unless otherwise specifically
                indicated.
                <br />
                <br />
                You may not repackage, reproduce, or otherwise sell Memberships,
                the Software or any technology or data related thereto. Also,
                bulk downloading or “screen scraping” of data from the Dockify
                website is prohibited without prior express written permission.
                Any violation of the foregoing will result in your account being
                terminated without notice and, depending on the severity of the
                violation, Dockify reserves the right to press criminal charges
                and/or seek civil compensation for damages, expenses and lost
                revenue
              </p>
              <h5>10.Term and Termination</h5>
              <p>
                These Terms will remain in full force and effect while you use
                or are registered to use the Services (whether or not you
                maintain your Membership) and until terminated by either you or
                Dockify. Termination includes deletion of your user account and
                ceasing to access the Dockify website. You and Dockify may
                terminate your account and these Terms at any time and for any
                reason or no reason at all, provided that you shall remain
                liable for all fees that you accrue in connection with your
                Membership and the Services.
                <br />
                <br />
                All sections of these Terms which by their nature should survive
                termination will survive termination, including, without
                limitation, accrued rights to payment, confidentiality
                obligations, warranty disclaimers, and limitations of liability
              </p>
              <h5>11. Warranty and Disclaimer</h5>
              <p>
                Dockify shall use commercially reasonable efforts consistent
                with prevailing industry standards to maintain the Services in a
                manner that minimizes errors and interruptions in the Services.
                The Services may be temporarily unavailable for scheduled
                maintenance or for unscheduled emergency maintenance, either by
                Dockify or by third-party providers, or because of other causes
                beyond Dockify’ s reasonable control, but Dockify shall use
                commercially reasonable efforts to provide advance notice in
                writing or by e-mail of any scheduled service disruption.
                HOWEVER, DOCKIFY DOES NOT WARRANT THAT THE SERVICES WILL BE
                UNINTERRUPTED OR ERROR-FREE; NOR DOES IT MAKE ANY WARRANTY AS TO
                THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES.
                EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES AND
                IMPLEMENTATION THEREOF ARE PROVIDED “AS IS” AND DOCKIFY
                DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
                FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT
              </p>
              <h5>12. Indemnity</h5>
              <p>
                You agree to indemnify and hold Dockify, its directors,
                managers, officers, employees, equity holders and other
                representatives, harmless from any and all third-party claims
                arising out of your use of the Services or any other party
                accessing the Services through your account, including any
                liability or expenses arising from any claims, direct or
                indirect damages, lost profits, suits, judgments, litigation
                costs and attorneys’ fees
              </p>
              <h5>13. Limitation of Liability</h5>
              <p>
                NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY
                INJURY OF A PERSON,{' '}
                <span style={{ color: 'red' }}>
                  DOCKIFY AND ITS DIRECTORS, MANAGERS, OFFICERS, EQUITY HOLDERS
                  AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES
                </span>
                SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT
                MATTER OF THESE TERMS OR TERMS AND CONDITIONS RELATED THERETO
                UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER
                THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR
                INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF
                SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS;
                (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR
                CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND DOCKIFY’ S
                REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH
                AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID
                BY YOU TO DOCKIFY FOR THE SERVICES UNDER THESE TERMS IN THE 12
                MONTHS PRIOR TO THE OCCURRENCE THAT ALLEGEDLY GAVE RISE TO THE
                LIABILITY, IN EACH CASE, WHETHER OR NOT DOCKIFY HAS BEEN ADVISED
                OF THE POSSIBILITY OF SUCH DAMAGES
                <br />
                <br />
                Communications from Dockify to you may be by electronic means.
                You hereby consent to receive communications from Dockify in
                electronic form and agree that all terms and conditions,
                agreements, notices, disclosures, and other communications that
                Dockify provides to you electronically satisfy any legal
                requirement that such communications would satisfy if they were
                in writing, other than any non-waivable rights.
                <br />
                <br />
                The Services may include certain communications from Dockify,
                such as service announcements, administrative messages, and
                newsletters. You may be prompted to opt into some or all of
                these communications upon registering your Membership. After
                opting in, you may opt-out of receiving such communications at
                any time. However, you will not be able to opt out from
                receiving service announcements and administrative messages.If
                you have any questions about our website, the Services, or these
                Terms, you may email us or write to us at{' '}
                <span style={{ color: 'red' }}>[ postal address]</span>, Attn:
                Customer Support
              </p>
              <h5>14. Miscellaneous Provisions</h5>
              <ul style={{ listStyle: 'unset' }}>
                <li>Fraud Protection</li>
                <p>
                  To the extent you maintain a valid Membership, you are
                  eligible for fraud protection for dropshipping, the terms and
                  conditions of which are subject to change at any time
                </p>
                <li>Your Relationship with Dockify</li>
                <p>
                  You, as a reseller of products purchased through the Services,
                  are an independent contractor of Dockify and are not
                  considered an employee of Dockify for any reason whatsoever.
                  You are prohibited from making any unauthorized
                  representations, warranties or claims on behalf of Dockify, a
                  Supplier, or a product manufacturer or that are otherwise
                  illegal in your applicable jurisdiction. No agency,
                  partnership, joint venture, or employment is created as a
                  result of these Terms and you do not have any authority of any
                  kind to bind Dockify in any respect whatsoever.
                  <br />
                  <br />
                  Additionally, you understand that you are an absolutely
                  independent person or entity from Dockify. You are responsible
                  to pay taxes to state, federal or local authorities in
                  accordance with any and all applicable laws. This tax
                  responsibility includes collecting and remitting all
                  appropriate sales taxes to state and local authorities
                </p>
                <li>Resale and Tax Compliance</li>
                <p>
                  You understand that all information provided by Dockify must
                  be used only in accordance with its specified purpose. You
                  further agree to not provide any confidential or proprietary
                  information received from Dockify to any business entity that
                  may compete with Dockify. Any violation of confidentiality is
                  grounds for immediate termination of your Membership and you
                  will be liable for any damages arising therefrom. Dockify
                  shall have the right to enforce this confidentiality provision
                  by injunction, specific performance or other equitable relief,
                  without bond and without prejudice to any other rights and
                  remedies that Dockify may have for a breach hereof
                </p>
                <li>Service Testing</li>
                <p>
                  From time to time, Dockify tests various aspects of the
                  Services and new features and reserves the right to include
                  you in these tests without notice
                </p>
                <li>Governing Law; Venue</li>
                <p>
                  If you are a supplier(s) based in the US: These Terms and any
                  dispute or claim arising out of or related to these Terms, its
                  subject matter or its formation (in each case, including
                  non-contractual disputes or claims) shall be governed by and
                  construed in accordance with the laws of the State of{' '}
                  <span style={{ color: 'red' }}>[ state name]</span>, without
                  giving effect to any choice or conflict of law provision or
                  rule. Any legal suit, action, or proceeding arising out of or
                  related to these Terms or the Services shall be instituted
                  exclusively in the federal or state courts located in{' '}
                  <span style={{ color: 'red' }}>[ exact location]</span> and
                  you further agree that such courts shall have personal
                  jurisdiction and venue with respect to you, and you hereby
                  submit to the jurisdiction and venue of such courts and waive
                  any objection. YOU HEREBY IRREVOCABLY WAIVE THE RIGHT TO TRIAL
                  BY JURY IN ANY CLAIM ARISING OUT OF OR RELATING TO THE SUBJECT
                  MATTER HEREOF. ADDITIONALLY, BOTH YOU AND DOCKIFY AGREE THAT
                  EACH PARTY MAY ONLY BRING CLAIMS AGAINST THE OTHER PARTY ON AN
                  INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                  PURPORTED CLASS OR REPRESENTATIVE ACTION. <br />
                  <br />
                  <span style={{ color: 'red' }}>
                    If you are a supplier(s) based in China: Any disputes
                    arising from the performance of this Agreement shall be
                    resolved by the parties through friendly negotiation. If
                    such negotiation fails, the disputes should be submitted to
                    China International Economic and Trade Arbitration
                    Commission, Jiangsu Arbitration Centre for arbitration and
                    shall be governed by the laws of the People’s Republic of
                    China. BOTH YOU AND DOCKIFY AGREE THAT EACH PARTY MAY ONLY
                    BRING CLAIMS AGAINST THE OTHER PARTY ON AN INDIVIDUAL BASIS
                    AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                    CLASS OR REPRESENTATIVE ACTION
                  </span>
                </p>
                <li>General Provisions</li>
                <p>
                  If any provision of these Terms is found to be unenforceable
                  or invalid, that provision will be limited or eliminated to
                  the minimum extent necessary so that these Terms will
                  otherwise remain in full force and effect and enforceable.
                  These Terms are not assignable, transferable or sublicensable
                  by you except with Dockify’ s prior written consent. Dockify
                  may transfer and assign any of its rights and obligations
                  under these Terms without consent. These Terms are the
                  complete and exclusive statement of the mutual understanding
                  of the parties and supersede and cancel all previous written
                  and oral agreements, communications and other understandings
                  relating to the subject matter of these Terms. In any action
                  or proceeding to enforce rights under these Terms, the
                  prevailing party will be entitled to recover costs and
                  attorneys’ fees.<br/><br/>While www.Dockify.co and all of its dependent
                  pages contain far too much information to be quoted in their
                  entirety in these Terms, you are responsible for reading and
                  understanding all applicable information contained on the
                  Dockify website. Dockify is not responsible for any
                  inconvenience, loss of profit, or other complications that you
                  or your business or customers may experience as a result of
                  your failure to read and understand relevant information on
                  the Dockify website.<br/><br/>If you have any questions about the
                  Dockify website, the Services or these Terms, you may email us
                  or write to us at:<br/><br/>Dockify. Attn: Customer Support <span style={{color:'red'}}>[ postal
                  Address]</span><br/><br/>Please note, return shipments sent to the address
                  above will be refused. Please contact Customer Support for
                  instructions before returning products
                </p>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Terms;
