import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function Cookie() {
  return (
    <div>
      <Container>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className='privacy-box px-5 py-3'>
              <h4
                className='text-center privacy-box'
                style={{ textDecoration: 'underline' }}
              >
               This Cookie Policy explains how Dockify (we, us, or our) uses cookies and similar technologies when you visit our website (Site).
              </h4>
              <h5>What are cookies?</h5>
              <p>Cookies are small text files that are placed on your device (computer, smartphone, tablet) when you visit  a  website.  They  are  widely  used  to  make  websites  work  or  work  more  efficiently,  as  well  as  to provide information to the website owner.</p>
              <h5>Types of cookies we use</h5>
              <h5>We use the following types of cookies on our Site:</h5>
              <h6>1.   Necessary cookies:</h6>
              <p>These cookies are essential for the Site to function properly. They enable basic functions like page  navigation  and  access  to  secure  areas  of  the  Site.  The  Site  cannot  function  properly without these cookies.</p>
              <h6>2.   Analytical cookies:</h6>
              <p>These cookies allow us to collect information about how visitors use the Site, such as which pages they visit most often and if they receive error messages from web pages. This information helps us to improve the Site and provide a better user experience.</p>
              <h6>3.   Functional cookies:</h6>
              <p>These  cookies  enable  the  Site  to  remember  choices  you  make  (such  as  your  language  or region)  and  provide  enhanced,  more  personal  features.  These  cookies  can  also  be  used  to remember changes you have made to text size, fonts, and other customizable elements of the Site.</p>
              <h6>4.   Advertising cookies:</h6>
              <p>These  cookies  are  used  to  deliver  advertisements  that  are  more  relevant  to  you  and  your interests. They may be used to deliver targeted advertisements or to limit the number of times you see an advertisement. They may also be used to measure the effectiveness of advertising campaigns.</p>
              <h5>Third-party cookies</h5>
              <p>We  may  use  third-party  services  on  our  Site,  such  as  Google  Analytics,  to  collect  and  analyse information about how users interact with the Site. These third-party services may use cookies to collect this information. We do not control the use of these cookies and you should check the relevant third-party website for more information about their use of cookies</p>
              <h5>How to control cookies</h5>
              <p>You can control the use of cookies on our Site by adjusting your browser settings. Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can obtain up-to-date information about blocking and deleting cookies via your browser s support documentation.</p>
              <h5>Changes to this Cookie Policy</h5>
              <p>We may update this Cookie Policy from time to time to reflect changes in technology, legislation, or our practices. We will post any changes to this Cookie Policy on our Site, so please review it periodically.</p>
              <h5>Contact us</h5>
              <p>If you have any questions about this Cookie Policy, please contact us at<Link to=''>support@dockify.co</Link></p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Cookie;
