import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function AppAbout() {
  return (
    <div id='about'>
      <Container>
        <Row className=''>
          <Col className='col-lg-12 col-md-12 col-sm-12 mt-5 col-12 '>
            <div className='mt-3'>
              <div className=' ' data-aos='zoom-in' data-aos-duration='1000'>
                <h2 className='about d-flex borr'>About Us</h2>
                <h2 className='bor d-flex'></h2>
              </div>
              <p className='needs ' data-aos='zoom-in' data-aos-duration='1000'>
                <h1 className='h1-text'>
                  ULTIMATE SOLUTION FOR ALL YOUR DROPSHIPPING NEEDS
                </h1>
              </p>
              <div className='mt-5' data-aos='zoom-in' data-aos-duration='1000'>
                <p className='card-text'>
                  Dockify is the ultimate one stop solution for the new age
                  digital entrepreneur. We help them take their online business
                  to the next level by enabling them to connect with authentic
                  manufacturers and delivery centres worldwide.
                </p>
                <p className='card-text'>
                  Stop with the worrying about not having savvy technical hands
                  around to help you in establishing and setting up your
                  dropshipping business. As a partner, we will work with you and
                  help you with all the technical setup so that you can sit back
                  and relax during the Onboarding process.
                </p>
                <p className='card-text'>
                  Either you are a well established manufacturing unit or a
                  startup that just started manufacturing the products, we are
                  here to help you navigate through your dropshipping business
                  journey. Just remember, we are in this together!
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col className='d-flex justify-content-evenly container-flex col-lg-12 col-md-12 col-sm-12'>
            <Card style={{}} data-aos='fade-up' className='mx-2'>
              <Card.Img
                variant='top'
                src='./img/about-boxes-1_v2.jpg'
                className=''
              />
              <Card.Body>
                <Card.Title className='text-center text-cards'>
                  Start Your E-Com Business
                </Card.Title>
                <Card.Text className='text-cardss'>
                  With Dockify as your platform, we help you to easily import
                  all your products and start selling immediately. Our business
                  model lets you get the sales first then pay for the services
                  later.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{}} data-aos='fade-up' className='mx-2'>
              <Card.Img
                variant='top'
                src='./img/about-boxes-2_v2.jpg'
                className=''
              />
              <Card.Body>
                <Card.Title className='text-center text-cards'>
                  Optimize Your Operations
                </Card.Title>
                <Card.Text className='text-cardss'>
                  Irrespective of your activity, either testing or running
                  multiple stores, Dockify Platform will help you to streamline
                  your operations so that you can focus on growing your
                  business.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{}} data-aos='fade-up' className='mx-2'>
              <Card.Img
                variant='top'
                src='./img/about-boxes-3_v2.jpg'
                className=''
              />
              <Card.Body>
                <Card.Title className='text-center text-cards'>
                  Scale Your Brand without Limits
                </Card.Title>
                <Card.Text className='text-cardss'>
                  Our motto is to help people become successful by being a
                  Partner instead of a stranger. Private Labelling, and other
                  incredible opportunities arise in front of you, thanks to
                  Dockify.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AppAbout;
