import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function Privacy() {
  return (
    <div>
      <Container>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className='privacy-box px-5 py-3'>
              <h4
                className='text-center'
                style={{ textDecoration: 'underline' }}
              >
                Privacy Policy
              </h4>
              <p className='mt-5'>
                Privacy PolicyPlease take a minute to read the following policy
                so you understand how we use the personal information we ask you
                to submit. If you use any of Dockify’ s services, you agree to
                these terms.
              </p>
              <h1>1. Our commitment to privacy</h1>
              <p>
                Your privacy is important to us. To better protect your privacy,
                we provide this notice explaining our online information
                practices and the choices you can make about the way your
                information is collected and used. To make this notice easy to
                find, we make it available from our footer navigation that is
                found on every page of our website. When you register at
                Dockify, we ask you for personal information. We use this
                information to process your orders, conduct account
                verifications, and to communicate with you about the status of
                your orders. We also use your email address to send you
                newsletters you are subscribed to during registration, as well
                as messages about Dockify special offers, promotional
                announcements and consumer surveys. Occasionally, we send our
                customers special offers and promotional information via postal
                mail, using the shipping address provided. If you no longer want
                to receive the newsletters or other announcements simply
                unsubscribe by checking the unsubscribe links.
              </p>
              <h1>2. The information we collect</h1>
              <p>
                This notice applies to all information collected or submitted on
                the Dockify website. On some pages, you can order memberships,
                order products, make requests, and register to receive
                materials. The types of personal information collected at these
                pages are
              </p>
              <ul style={{ listStyle: 'unset' }}>
                <li>Name</li>
                <li>Billing address</li>
                <li>Email address</li>
                <li>State drivers license number</li>
                <li>Phone number</li>
                <li>Credit/debit card information</li>
                <li>
                  Alternative payment information (Wire, Prepay, PayPal, etc.)
                </li>
                <li>Unique identifiers (such as username and password)</li>
                <li>Retail site URL or marketplace name</li>
                <li>Shipping account information</li>
                <li>Federal EIN</li>
                <li>State sales tax ID</li>
              </ul>
              <p>
                On some pages, you can submit information about other people.
                For example, if you order a product and want it drop shipped
                directly to your customer, you will need to submit their
                address. In this circumstance, the types of personal information
                collected are:
              </p>
              <ul>
                <li>Name</li>
                <li>Shipping address</li>
                <li>Phone number</li>
                <li>Email</li>
              </ul>
              <h1>3. How we use information</h1>
              <p>
                The primary use of your personal information, other than
                directly in connection with a purchase, is to efficiently
                provide you with personalized, relevant information. We also use
                registration information to let you know about new features or
                other offers of interest from us and to address customer service
                needs and requests
              </p>
              <h4>
                Cookies <br />
                What are cookies?
              </h4>
              <p>
                Cookies are simple text files that are stored on your computer
                or mobile device by a website’s server. Each cookie is unique to
                your web browser. It will contain some anonymous information
                such as a unique identifier, website’s domain name, and some
                digits and numbers.
              </p>
              <h4>
                What types of cookies do we use? <br />
                Necessary cookies:
              </h4>
              <p>
                Necessary cookies allow us to offer you the best possible
                experience when accessing and navigating through our website and
                using its features. For example, these cookies let us recognize
                that you have created an account and have logged into that
                account to access the content.
              </p>
              <h4>Functionality cookies:</h4>
              <p>
                Functionality cookies let us operate the site in accordance with
                the choices you make. For example, we will recognize your
                username and remember how you customized the site during future
                visits
              </p>
              <h4>Analytical cookies:</h4>
              <p>
                These cookies enable us and third-party services to collect
                aggregated data for statistical purposes on how our visitors use
                the website. These cookies do not contain personal information
                such as names and email addresses and are used to help us
                improve your user experience of the website.How do we use
                cookies?
                <br />
                We use cookies in a range of ways to improve your experience on
                our site, including
              </p>
              <ul>
                <li>Keeping you signed in</li>
                <li>Understanding how you use our site</li>
                <li>
                  Showing you Dockify products and services that are relevant to
                  you
                </li>
                <li>
                  Working with partners to deliver to you relevant advertising
                </li>
              </ul>
              <p>
                Ultimately, this allows us to provide you with better service on
                Dockify.co.
              </p>
              <h4>How to delete cookies?</h4>
              <p>
                If you want to restrict or block the cookies that are set by our
                website, you can do so through your browser setting.
                <br />
                Alternatively, you can visit
                <a href=''> https://www.internetcookies.com/disable-cookies/</a>
                , which contains comprehensive information on how to do this on
                a wide variety of browsers and devices. You will find general
                information about cookies and details on how to delete cookies
                from your device.
              </p>
              <h4>Contacting us:</h4>
              <p>
                If you have any questions about this cookie policy or our use of
                cookies, please contact us via<a href=''> Contact@Dockify.co</a>
              </p>
              <h4>lOG files</h4>
              <p>
                Like most standard website servers, we use log files. This
                includes Internet protocol (IP) addresses, browser type,
                Internet service provider (ISP), referring/exit pages, platform
                type, date/time stamp, and number of clicks to analyse trends,
                administer thesite, track users movement in the aggregate, and
                gather broad demographic information for aggregate use. IP
                addresses, etc. are not linked to personally identifiable
                information.
              </p>
              <h1>4. Communications from Dockify</h1>
              <h4 style={{ textDecoration: 'underline' }}>
                Special offers and updates
              </h4>
              <p>
                We send all new members a welcoming email to verify password and
                username. Established members will occasionally receive
                information on products, services, special deals, and a
                newsletter. Out of respect for the privacy of our users we
                present the option to not receive these types of communications.
                We also send all registrants special offers and updates
                regarding our services. If you are a former customer, you will
                continue to receive special offers and updates regarding our
                services unless you choose to unsubscribe from such
                communications.
              </p>
              <h4 style={{ textDecoration: 'underline' }}>Newsletter</h4>
              <p>
                If a user wishes to subscribe to our newsletter, we ask for
                contact information such as name and email address. Out of
                respect for our user’s privacy, we provide a way to opt-out of
                these communications.
              </p>
              <h4 style={{ textDecoration: 'underline' }}>
                Service announcements
              </h4>
              <p>
                On rare occasions it is necessary to send out a strictly
                service-related announcement. For instance, if our service is
                temporarily suspended for maintenance, we might send users an
                email. Generally, users may not opt-out of these communications,
                though they can deactivate their account. However, these
                communications are not promotional in nature.
              </p>
              <h4 style={{ textDecoration: 'underline' }}>Customer service</h4>
              <p>
                We communicate with users on a regular basis to provide
                requested services and in regard to issues relating to their
                account we reply via email or phone, in accordance with the
                users wishes.
              </p>
              <h4 style={{ textDecoration: 'underline' }}>Other</h4>
              <p>
                During the registration process, we collect phone information so
                that we may call you for account management purposes. We also
                will call you during the first several weeks of your trial or
                service to provide answers to any questions, as well as to offer
                additional promotions only available via phone. After the first
                several weeks of your trial or service, we, or our authorized
                marketing partners, may periodically contact you for the
                purposes of improving our services by asking you to answer
                questions or complete surveys or by offering you other services
                that we feel you may benefit from.
              </p>
              <h1>5. Sharing–Third parties</h1>
              <p>
                Dockify will not disclose your personal information provided in
                connection with membership registration or purchases, except
                with your knowledge and consent as described in this Privacy
                Policy or as may be required by law, or to protect the rights or
                property of Dockify. Information submitted by you online (such
                as information about products you purchase from us, your name,
                address, email address) may be shared with companies with which
                we have a commercial relationship, including companies through
                which you reached Dockify. For example, if you make a purchase
                with us because of a special offer made through another site
                acting as a distributor of our products, in that circumstance
                you will be considered their customer as well. We will not,
                except as may be required by law, share with any other party
                your password or payment information. We may also share
                information about you (including name, email address, phone
                number, and postal address) to our marketing partners or other
                companies with whom we have a commercial relationship so they
                may contact you about information and services that we feel you
                would benefit from. We also reserve the right to disclose
                aggregated user statistics, such as “45% of our users are
                female” in order to describe our services to prospective
                partners, advertisers, and other third parties. Under protection
                of confidentiality agreements, Dockify may match common user
                information, such as name, address and phone number with
                third-party data to avoid duplication and prevent errors.
              </p>
              <h1>6. Our commitment to data security</h1>
              <p>
                Dockify takes every precaution to protect our users information.
                When users submit sensitive information via the website, their
                information is protected both online and offline.When our
                registration/order form asks users to enter sensitive
                information (such as credit card numbers), that information is
                encrypted and is protected with the best encryption software in
                the industry - SSL. While on a secure page, such as our order
                form, the lock icon on the bottom of Web browsers such as
                Netscape Navigator and Microsoft Internet Explorer becomes
                locked, as opposed to un-locked, or open, when users are just
                “surfing.”While we use SSL encryption to protect sensitive
                information online, we also do everything in our power to
                protect user-information offline. All of our users information,
                not just the sensitive information mentioned above, is
                restricted in our offices. Only employees who need the
                information to perform a specific job (for example, our billing
                clerk or a customer service representative) are granted access
                to personally identifiable information. Furthermore, ALL
                employees are kept up-to-date on our security and privacy
                practices. Finally, the servers that store personally
                identifiable information are in a secure environment, behind a
                locked cage in a locked facility.Dockify reserves the right to
                make any amendments, modifications, or changes to this Privacy
                Policy at any time. If any material change is made to this
                Privacy Policy, we will notify our users about those changes via
                email. If you do not agree to such changes, you can modify your
                notification settings to opt-out of certain notices from Dockify
                or its marketing partners. If users have any questions about the
                security at our website, please contact Customer Support.
              </p>
              <h1>7. Our commitment to childrens privacy</h1>
              <p>
                Protecting the privacy of the very young is especially
                important. For that reason, we never collect or maintain
                information at our website from those we actually know are under
                13, and no part of our website is structured to attract anyone
                under 13. In order to subscribe to Dockify’ s services, the
                member must be 18 with a valid credit card.
              </p>
              <h1>8. How you can access or correct your information</h1>
              <p>
                You can access your personally identifiable information
                collected by Dockify by logging into your account and visiting
                the “Settings” section of the website. You can correct factual
                errors in your personally identifiable information by sending us
                a request that credibly shows error. To protect your privacy and
                security, we will also take reasonable steps to verify your
                identity before granting access or making corrections.
              </p>
              <h1>9. How to contact us</h1>
              <p>
                Should you have other questions or concerns about these privacy
                policies, please contact Customer Support.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Privacy;
