import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function Faq() {
  return (
    <div>
      <Container>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className='privacy-box px-5 py-3'>
              <h4
                className='text-center privacy-box'
                style={{ textDecoration: 'underline' }}
              >
                Dockify FAQ
                <br />
                Subscription FAQs:
              </h4>
              <h5>How do I open an account?</h5>
              <p>
                From the home page click the link on get started, write your
                details and Sign Up.
              </p>
              <h5>
                Can I register and open an account if I am located outside the
                United States?
              </h5>
              <p>
                Yes, while registering for a new account, you can change the
                country in the drop down to your country.
              </p>
              <h5>
                Do I need a Federal Tax ID/EIN or social security number to sell
                your products?
              </h5>
              <p>
                Yes, you must provide a valid Federal Tax ID/EIN number or
                social security at the time you register if you’re registering
                from the United States. We require this as we are wholesalers.
              </p>
              <h5>What is a Federal Tax ID or EIN?</h5>
              <p>
                An Employer Identification Number (EIN) is also known as a
                Federal Tax Identification Number, and is used to identify a
                business entity.
              </p>
              <h5>How do I get a Federal Tax ID or EI?</h5>
              <p>
                You may apply for a Federal Tax ID or EIN in various ways, and
                now you may apply online. This is a free service offered by the
                Internal Revenue Service. You can visit
                <a href=''>
                  http://www.irs.gov/businesses/small/article/0,,id=97860,00.html
                </a>
              </p>
              <h5>
                How do you store and process my information? Is it secure?
              </h5>
              <p>
                Your information is always kept strictly confidential and fully
                secure. We will never sell any information.
              </p>
              <h5>What is Dockify’ s subscription pricing?</h5>
              <p>
                Dockify offers four subscription plans: Starter, Professional,
                Business and Enterprise. The Basic plan costs $9.99/month, the
                Professional plan costs $14.99/month, the Business plan costs
                $19.99/month, and the Enterprise plan costs $49/month.
              </p>
              <h5>What are the differences between the subscription plans?</h5>
              <ul style={{ listStyle: 'unset' }}>
                <li>
                  The starter plan includes access to 1 Connected Store, Email
                  Support, No Commission, Auto Order & Inventory Sync, 1000
                  Products and Dynamic Pricing Settings.
                </li>
                <li>
                  The Professional plan includes access to 2 Connected Stores,
                  Email Support, No Commission, Auto Order & Inventory Sync,
                  5000 Products and Dynamic Pricing Settings.
                </li>
                <li>
                  The Business plan includes access to 3 Connected Stores, Email
                  Support, No Commission, Auto Order & Inventory Sync, 10000
                  Products and Dynamic Pricing Settings.
                </li>
                <li>
                  The Enterprise plan includes access to Unlimited Connected
                  Stores, Email Support, No Commission, Auto Order & Inventory
                  Sync, Unlimited Products, Branded Invoice and Dynamic Pricing
                  Settings.
                </li>
              </ul>
              <h5>Is there a free trial available?</h5>
              <p>No, Dockify doesn’t offer free trial to any of its plans.</p>
              <h5>Can I cancel my subscription at any time?</h5>
              <p>
                Yes, you can cancel your subscription at any time by contacting
                Dockify’ s customer support team.
              </p>
              <h5>How does billing work?</h5>
              <p>
                Dockify bills your subscription fee on a monthly basis, starting
                from the date of your first payment. You can manage your billing
                information and view your billing history in your Dockify
                account dashboard.
              </p>
              <h5>Are there any additional fees I should be aware of?</h5>
              <p>
                Dockify charges No transaction fee on all orders placed through
                its platform.
              </p>
              <br />
              <h3 className='text-center'>Orders FAQs:</h3>
              <h5>Do you have a minimum order?</h5>
              <p>We do not have any minimum orders</p>
              <h5>How do I place an order on Dockify?</h5>
              <p>
                To place an order on Dockify, you need to first You must first
                create an account. Once your logged into your account find the
                product you want to sell using the search function or by
                browsing through categories. Once you ve found the product,
                select it and add it to your Inventory list. From there, you can
                enter the shipping information for your customer and complete
                the payment process.
              </p>
              <h5>
                Can I customize the packaging or include my own branding on
                orders?
              </h5>
              <p>
                Some suppliers on Dockify may offer the option to customize
                packaging or include your own branding on orders. You can check
                with the supplier to see if this option is available.
              </p>
              <h5>How long does it take for orders to ship?</h5>
              <p>
                The shipping time for orders on Dockify will vary depending on
                the supplier and the shipping method you choose. supplier
                provides estimated shipping times for each product on the
                product page, and you can also check with the supplier for more
                specific information.
              </p>
              <h5>Can I track my orders on Dockify?</h5>
              <p>
                You will be provided with the tracking number for the raised
                order with which the tracking is possible.
              </p>
              <h5>What if there is a problem with an order?</h5>
              <p>
                If there is a problem with an order, you should contact the
                supplier directly to resolve the issue. Dockify does not handle
                returns, refunds, or exchanges on behalf of the supplier, but
                they can provide assistance and guidance in resolving any issues
                that arise.
              </p>
              <h3 className='text-center'>Inventory FAQs:</h3>
              <h5>What is inventory on the Dockify platform?</h5>
              <p>
                Inventory refers to the products that are available for sale on
                the Dockify platform. These products are sourced from a variety
                of suppliers, and Dockify handles the logistics of getting them
                to customers.
              </p>
              <h5>
                Can I see how much inventory is available for a particular
                product?
              </h5>
              <p>
                Yes, you can see the current inventory levels for each product
                on the Dockify platform. This information is updated in
                real-time, so you always know how many units are available.
              </p>
              <h5>What happens if a product is out of stock?</h5>
              <p>
                If a product is out of stock, it will be marked as such on the
                Dockify platform. You can still list the product on your own
                website or marketplace, but you won t be able to fulfill orders
                until the product is back in stock.
              </p>
              <h5>How does Dockify handle backorders?</h5>
              <p>
                Dockify does not currently support backorders. If a product is
                out of stock, you ll need to wait until it s back in stock to
                fulfill orders.
              </p>
              <h5>Can I set up automatic inventory updates?</h5>
              <p>
                Yes, Dockify offers automatic inventory updates for certain
                suppliers. This means that the inventory levels for these
                products will be updated automatically in real-time, so you
                always have accurate information.
              </p>
              <h5>What happens if a supplier runs out of a product?</h5>
              <p>
                If a supplier runs out of a product, Dockify will mark it as out
                of stock on the platform. You ll need to wait until the supplier
                restocks the product before it s available for sale again.
              </p>
              <h5>Can I get alerts when a product is back in stock?</h5>
              <p>
                Yes, Dockify offers email notifications when a product is back
                in stock. You can set up these notifications on the product
                page.
              </p>
              <h5>How often is inventory updated on the Dockify platform?</h5>
              <p>
                Inventory levels are updated in real-time on the Dockify
                platform. This means that you always have the most up-to-date
                information about product availability.
              </p>
              <h5>What is inventory sync?</h5>
              <p>
                Inventory sync is the process of automatically updating the
                inventory levels of products in your online store with the
                inventory levels of those products in your Dockify account.
              </p>
              <h5>
                What should I do if I m experiencing inventory sync problems on
                Dockify?
              </h5>
              <p>
                If you re experiencing inventory sync problems on Dockify, try
                the following troubleshooting steps:
              </p>
              <ul style={{listStyle: 'unset'}}>
                <li>
                  Check that your Dockify and online store accounts are properly
                  connected.
                </li>
                <li>
                  Verify that your Dockify account has the correct inventory
                  levels for the products you re selling.
                </li>
                <li>
                  Refresh the product pages in your online store to see if the
                  inventory levels update.
                </li>
                <li>
                  Contact Dockify customer support for further assistance.
                </li>
              </ul>
              <h5>How often does Dockify sync inventory levels?</h5>
              <p>
                Dockify automatically syncs inventory levels with your online
                store every 24 hours. You can also manually sync inventory
                levels by clicking the Sync Inventory button in your Dockify
                account.
              </p>
              <h5>
                What happens if a product is out of stock in my Dockify account
                but still listed in my online store?
              </h5>
              <p>
                If a product is out of stock in your Dockify account but still
                listed in your online store, customers may purchase the product
                and you will be unable to fulfill the order. To prevent this
                from happening, it s important to regularly check and update
                your inventory levels in both your Dockify account and online
                store.
              </p>
              <h5>Can I manually update inventory levels in Dockify?</h5>
              <p>
                Yes, you can manually update inventory levels in Dockify by
                adjusting the quantities in your product listings. You can also
                use the Sync Inventory button to manually update inventory
                levels in your online store.
              </p>
              <h5>
                What should I do if Im still experiencing inventory sync
                problems after trying the troubleshooting steps?
              </h5>
              <p>
                If you re still experiencing inventory sync problems after
                trying the troubleshooting steps, contact Dockify customer
                support for assistance. They can help diagnose the problem and
                provide further guidance on how to resolve the issue.
              </p>
              <h4 className='text-center'>
                <b>Products FAQs:</b>
              </h4>
              <h5>What kind of products can I sell on Dockify?</h5>
              <p>
                Dockify offers a wide range of products across multiple
                categories, including electronics, home and garden, apparel,
                health and beauty, and more. There are over 2 million products
                available on the platform from over 200 suppliers.
              </p>
              <h5>Can I find branded products on Dockify?</h5>
              <p>
                Yes, you can find branded products on Dockify. However,
                availability and pricing may vary depending on the supplier.
              </p>
              <h5>How do I know if a product is in stock?</h5>
              <p>
                Dockify’ s platform displays real-time inventory levels for each
                product. You can also set up inventory alerts to receive
                notifications when a product is running low.
              </p>
              <h5>Can I customize the products I sell on Dockify?</h5>
              <p>
                This depends on the supplier. Some suppliers offer private
                labelling or customization options, while others do not.
              </p>
              <h5>What are the shipping options for products on Dockify?</h5>
              <p>
                Dockify suppliers offer a variety of shipping options, including
                standard, expedited, and international shipping. Shipping costs
                and delivery times may vary depending on the supplier and the
                shipping method selected.
              </p>
              <h5>
                How do I handle returns and exchanges for products purchased
                through Dockify?
              </h5>
              <p>
                Each supplier on Dockify has their own return and exchange
                policies. You should review the policies of each supplier you
                work with and communicate those policies to your customers.
              </p>
              <h5>
                What kind of support does Dockify offer for product-related
                issues?
              </h5>
              <p>
                Dockify offers customer support for issues related to orders,
                products, and suppliers. You can contact Dockify’ s support team
                via email or phone for assistance.
              </p>
              <h4 className='text-center'>
                <b>Payments FAQs:</b>
              </h4>
              <h5>
                What payment methods are accepted on the Dockify dropshipping
                platform?
              </h5>
              <p>
                Dockify accepts payments via credit card, PayPal, and wire
                transfer.
              </p>
              <h5>
                Q: Is there a transaction fee for using Dockify’ s payment
                processing service?
              </h5>
              <p>
                Dockify charges No transaction fee for all payments processed
                through their platform.
              </p>
              <h5>Q: How does Dockify handle refunds and chargebacks?</h5>
              <p>
                Dockify handles refunds and chargebacks on a case-by-case basis.
                If a customer requests a refund or initiates a chargeback,
                Dockify will work with the supplier to resolve the issue and
                ensure the customer receives a refund if appropriate.
              </p>
              <h5>Q: How often are payments processed on Dockify?</h5>
              <p>
                Payments are processed on Dockify on a daily basis, with the
                exception of weekends and holidays.
              </p>
              <h5>Q: Can I set up automatic payments on Dockify?</h5>
              <p>
                Yes, you can set up automatic payments on Dockify by adding a
                credit card to your account and selecting the Automatic Payments
                option.
              </p>
              <h5>
                Q: Is there a minimum order value for payments on Dockify?
              </h5>
              <p>
                No, there is no minimum order value for payments on Dockify. You
                can make payments for any amount, as long as the supplier
                accepts your payment method.
              </p>

              <h5>Q: How can I view my payment history on Dockify?</h5>
              <p>
                You can view your payment history on Dockify by logging into
                your account and selecting the Payment History option. This
                will show you a list of all payments made on your account,
                including the date, amount, and payment method used.
              </p>
              <h5>
                Q: What should I do if I experience payment issues on Dockify?
              </h5>
              <p>
                If you experience payment issues on Dockify, you should contact
                their customer support team for assistance. They can help you
                troubleshoot any problems and ensure that your payments are
                processed correctly.
              </p>
              <h5>How does Dockify handle payments?</h5>
              <p>
                Dockify accepts payment through all major credit cards/debit
                cards. We also accept bank wire, and PayPal. When you place an
                order, Dockify will charge the payment method on file for the
                cost of the product plus any applicable shipping and handling
                fees.
              </p>
              <h5>
                What kind of security does Dockify offers for Payment
                Information?
              </h5>
              <p>
                At Dockify, complete security for customer transactions is our
                primary concern. All information provided by you is securely
                transmitted to us using via a 128-Bit SSL security, the standard
                method accepted industry wide for your payment. Your information
                is 100% secure and confidential.
              </p>
              <h4 className='text-center'>
                <b>Returns FAQ:</b>
              </h4>
              <h5>What is Dockify’ s return policy?</h5>
              <p>
                Dockify’ s return policy varies by supplier. Each supplier has
                its own return policy, and it is important to review the
                supplier s return policy before placing an order.
              </p>
              <h5>
                What should I do if a customer wants to return an item purchased
                through my Dockify account?
              </h5>
              <p>
                If a customer wants to return an item purchased through your
                Dockify account, you should review the supplier s return policy
                and communicate the policy to the customer. You should then work
                with the customer and the supplier to initiate the return
                process and ensure that the customer receives a refund or
                replacement item, if applicable.
              </p>
              <h5>
                Can I return items to Dockify if I am not satisfied with them?
              </h5>
              <p>
                No, Dockify is a dropshipping platform and does not hold
                inventory. As such, you cannot return items to Dockify if you
                are not satisfied with them. You should contact the supplier
                directly to initiate a return or exchange.
              </p>
              <h5>Who pays for return shipping costs on Dockify?</h5>
              <p>The responsibility for paying return shipping costs typically falls on the customer. However, the supplier may offer to cover return shipping costs in certain circumstances, so it is important to review the supplier s return policy before initiating a return.</p>
              <h5>What should I do if a customer claims that they did not receive their order from Dockify?</h5>
              <p>If a customer claims that they did not receive their order from Dockify, you should work with the supplier to verify the order s tracking information and investigate the issue. If the order was lost  in  transit  or  was  not  delivered,  you  should  work  with  the  supplier  to  arrange  for  a replacement or refund.</p>
              <h5>Can I cancel an order on Dockify?</h5>
              <p>Whether  you  can  cancel  an  order  on  Dockify  depends  on  the  supplier s  order  cancellation policy. Some suppliers allow for order cancellations before the order has shipped, while others do not. It is important to review the supplier s order cancellation policy before placing an order to avoid any potential issues.</p>
              <h4 className='text-center'><b>Shipping FAQs:</b></h4>
              <h5>What is shipping on the Dockify dropshipping platform?</h5>
              <p>Shipping on the Dockify platform refers to the process of delivering products from the supplier to the end customer.</p>
              <h5>How is shipping handled on Dockify?</h5>
              <p>Shipping on Dockify is handled by the suppliers. When an order is placed on your website or marketplace, the supplier is responsible for processing and shipping the order directly to the customer.</p>
              <h5>What shipping carriers are available on Dockify?</h5>
              <p>The shipping carriers available on Dockify vary by supplier. Some common carriers include UPS, FedEx, and USPS, among others.</p>
              <h5>How can I track my shipments on Dockify?</h5>
              <p>You can track your shipments on Dockify by accessing the tracking information provided by the  supplier.  This  information  is  typically  available  through  your  Dockify  account  or  through the supplier s website.</p>
              <h5>What should I do if there is an issue with my shipment on Dockify?</h5>
              <p>If there is an issue with your shipment on Dockify, such as a delay or lost package, you should contact the supplier directly to resolve the issue. You can also reach out to Dockify’ s customer support team for assistance.</p>
              <h5>How long does shipping typically take on Dockify?</h5>
              <p>Shipping times on Dockify can vary depending on the supplier and the shipping carrier used. Some  suppliers  may  offer  expedited  shipping  options,  while  others  may  have  longer processing and shipping times.</p>
              <h5>How are shipping costs calculated on Dockify?</h5>
              <p>Shipping  costs  on  Dockify  are  determined  by  the  supplier  and  may  vary  depending  on  the size, weight, and destination of the package. You should check with the supplier to determine the shipping costs associated with each product.</p>
              <h5>Can I offer free shipping on my Dockify orders?</h5>
              <p>Whether or not you can offer free shipping on your Dockify orders depends on the supplier s policies. Some suppliers may offer free shipping on certain products or orders, while others may not. You should check with the supplier to determine their shipping policies.</p>
              <h5>How does Dockify handle order processing and fulfilment?</h5>
              <p>When a customer places an order on your website or marketplace, the order is automatically synced to Dockify’ s platform. Dockify then sends the order to the supplier for fulfilment, and the supplier ships the product directly to the customer.</p>
              <h5>Can I place an order if I am outside USA?</h5>
              <p>Yes, we ship internationally. Customers will be responsible for all duty and taxes imposed by their  country if  any.  International  shipping charges  will  be applied  to all orders. Please look into the shipping section for different shipping methods and rates.</p>
              <h4><b>Service FAQs</b></h4>
              <h5>Is Dockify suitable for beginners?</h5>
              <p>Dockify can be a good option for beginners in the world of dropshipping, as they offer a variety of resources and support to help you get started. However, it is important to carefully consider the fees and requirements before signing up for their services.</p>
              <h5>Do you have a store or showroom that I can come in and purchase or see your line?</h5>
              <p>No, we do not have a store or showroom. We sell everything over the internet & phone. This allows us to keep our overhead as low as possible in turn providing our customers the lowest prices & best service.</p>
              <h5>What are your business hours?</h5>
              <p>Monday - Thursday 10:30 AM to 5:00 PM EST. Friday 10:30 AM to 3:00 PM EST. You can email us 24 hours. Emails are often replied even outside office hours.</p>
              <h5>Do you offer technical assistance?</h5>
              <p>Yes, via email. You can create a ticket by going to our contact us and filling the form. Our tech team will get back to you within 1-2 days. We also have full documentation on our help section which is located on the top right of our website.</p>
              <h5>Does Dockify offer customer support?</h5>
              <p>Yes, Dockify offers customer support via phone and email. They also have a knowledge base and training resources available on their website.</p>
              <h4 className='text-center'><b>Dropshipping FAQs</b></h4>
              <h5>Does your company drop ship?</h5>
              <p>Yes, we offer drop shipping under our drop shipping program for our customers.</p>
              <h5>Do I need to buy any products before I sell them?</h5>
              <p>No. That s why the Dockify is here. Without buying, you can place the order for direct shipment from us to your customer.</p>
              <h5>In  what  format  do  you  give  the  information  about  your  product  under  the  drop  ship program?</h5>
              <p>We offer it in two formats. You can choose a product feed or via a CSV File Format.</p>
              <h5>How do I incorporate the product feed into my website?</h5>
              <p>You can view full documentation by clicking the help button on the top right-hand section of our website.</p>
              <h5>do you offer product title and descriptions?</h5>
              <p>Yes, we provide title and descriptions.</p>
              <h5>do you provide images of the products?</h5>
              <p>Yes, we provide you images.</p>
              <h5>Can I upload my own shipping label to be used on a drop ship order?</h5>
              <p>Yes, we do allow you do upload a label which we will use to ship your drop ship order. You will be prompted for this option when you are placing your drop ship order online.Can I upload my own invoice/packing slip to be included in my drop ship order?Yes,  you  can  upload  an  invoice/packing  slip  when  placing  a  drop  ship  order  which  we  will include in your  drop  ship  order. If  no invoice/packing slip is  uploaded we will only send the merchandise and no invoice/packing slip with the order.</p>
               <h5>Is there a watermark on your images?</h5> 
               <p>Yes, all images have an invisible watermark which allows us to track the images and protect us against unauthorized use. Rest assures your customer will not know there is any watermark on the images</p>
               <h5>What is your fulfilment rate?</h5>
               <p>Our fulfilment rate is over 90%. At times we do go out of stock on some items but we make our best effort to refill out of stock items as quick as we can often within 2-3 weeks.</p>
               <h5>Can I drop ship to a PO box?</h5>
               <p>Yes, please select the USPS option. UPS does not allow shipping to PO boxes.</p>
               <h5>Can you do a drop ship to APO address?</h5>
               <p>Yes, we can ship to APO addresses. Select USPS when placing your shipping option</p>
               <h5>Can I cancel my account anytime?</h5>
               <p>We at Dockify will never hold you to a subscription you do not want to be in. Our goal is for you  to  make  money.  When  your  goal  is  not  being  reached  you  can  cancel  your  account anytime no question asked</p>
               <h5>What tools are available to help us upload to eBay and Amazon?</h5>
               <p>Amazon  inventory  can  be  uploaded  via  their  seller  central  portal  which  is  available  to  the Amazon store owners. For eBay, our feeds are compatible with their Turbo Lister 2 tool which is available for free download.I already have an existing e-commerce site. Is it possible to link real time inventory to my existing site?Yes, once signed up , this can be incorporated with our data feed. We will provide you with instructions.  If  further  assistance  is  needed  our  technical  staff  can  work  with  your  web developer to add this functionality to your existing website.</p>
               <h5>Is there any drop ship fee that is added onto the wholesale cost of the item?</h5>
               <p>No. We do not charge any per item to do a drop ship order other than the monthly drop ship program fee.</p>
               <h5>Who owns the customer if you drop ship an order for us?</h5>
               <p>Customers will always be owned by you, the merchants. We will never contact your customer. Your customers will never know we exist. You will be responsible for all customer service to your customer.</p>
               <h5>What type of outer packaging does the customers receive?</h5>
               <p>On orders shipped via USPS we ship in a rectangular padded envelope. All UPS shipped in a high-quality craft corrugated box.</p>
               <h5>After opening an account with you, how long do we have to wait before we can start selling?</h5>
               <p>You can start selling immediately.</p>
               <h5>Do you have handling or packaging fees?</h5>
               <p>This is all incorporated into our shipping charge if you choose to select one of our shipping choices. You can also choose to upload your own shipping label.</p>
               <h5>How long do I have to wait for a reply when emailing your customer support at contact@dockify.co?</h5>
               <p>We try and reply all our emails as quick as possible usually no more than 1-2 business days after we receive it. Please note we are not open on weekends. Example if you email us on Friday, you will get a reply no later than Tuesday. If you email us on Monday, you will get a reply no later than Wednesday.</p>
               <h5>What happens when my customer wants to return an item?</h5>
               <p>Since your customer do not know we exist. Your customers will have to return the order back to you. If you choose to return it back to us follow our return policy</p>
               <h5>Do you have more than 1 image angle available?</h5>
               <p>Currently most of our gold and silver items have only 1 angle available. Many of our items do have more than 1 angle.</p>
               <h5>What are your warranty policies?</h5>
               <p>Please visit our warranty page</p>
               <h5>Do you drop ship to international customer and who would be responsible for duty and taxes?</h5>
               <p>We do drop ship international. Your customer will be responsible for all duty and taxes. Duty and taxes rates all depend on the country of import. Please have your customer contact their local customs office to get those rates.</p>
               <h5>Do you put our company name on the shipping label?</h5>
               <p>This is also called blind drop ship. Yes, we will put your company name and address on all domestic  shipping  labels  as  the  return  address.  We  get  the  return  address  from  the  billing information  you  provide  on  the  order.  Your  customers  will  never  know  we  exist.  For international customers we use our company address as the return address as we cannot ship from USA with an international address.</p>
               <h5>Do  you  send  automated/electronic  notification  upon  receipt  of  order  and  release  of shipment?</h5>
               <p>Yes, we will send you a notification so you are in the loop through the shipment process. You can also log into your account to see order status. If anytime you have an additional question, you can always call customer service or email us.</p>
               <h5>Do you provide tracking numbers for after my drop ship order has been shipped?</h5>
               <p>Yes, you will get an automated email from us with the tracking number of your order. You can always log into your account to view tracking numbers</p>
               <h5>I have a suggestion to the Dockify. Is it ok for me to let you know?</h5>
               <p>Your feedback is very important to us. The more you let us know the better we can customize our drop ship program for you. Please visit our feedback link or email info@dockify.co with your suggestion</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Faq;
