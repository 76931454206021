import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Col, Container, Row } from 'react-bootstrap';

function AppContact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_tvxv91t',
        'template_70bj2tc',
        e.target,
        'BftzF1h00bYzt_eAA'
      )
      .then(
        () => {
          console.log('Email successfully sent!');
        },
        (error) => {
          console.error('Email sending failed:', error.text);
        }
      );
  };

  return (
    <div id="contact">
      <Container>
        <Row className="mt-5 align-items-center">
          <Col xs={12} lg={6}>
            <img
              src="https://digitalexpertz.com/wp-content/uploads/2021/03/Vector-Smart-Object3.png"
              className="img-fluid py-5"
              alt="contact-image"
            />
          </Col>

          <Col xs={12} lg={6}>
            <div className="contact info-box">
              <form
                onSubmit={sendEmail}
                role="form"
                id="contactForm"
                className="contact-form php-email-form"
                data-toggle="validator"
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={handleChange}
                      value={formData.name}
                      placeholder="Your Name"
                      required
                      data-error="Please enter your Name"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                      placeholder="Your Email"
                      required
                      data-error="Please enter your Email"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    onChange={handleChange}
                    value={formData.subject}
                    placeholder="Subject"
                    required
                    data-error="Please enter your Subject"
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    rows="5"
                    name="message"
                    onChange={handleChange}
                    value={formData.message}
                    placeholder="Message"
                    required
                    data-error="Write your Message"
                  ></textarea>
                  <div className="help-block with-errors"></div>
                </div>
                <div className='text-center mt-3'>
                  <button type='submit ' className='started'>
                    Send Message
                  </button>
                  <div
                    id='msgSubmit'
                    className='with-errors-h3 text-center hidden'
                  ></div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AppContact;
