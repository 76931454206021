import { Routes, Route } from 'react-router-dom';
import './App.css';
import AppMain from './Components/Main';
import Intergrate from './Components/Intergration';
import Apppartnership from './Components/Partnership';
import Privacy from './Components/Ourpolicies/Privacy';
import Refund from './Components/Ourpolicies/Refund';
import Faq from './Components/Ourpolicies/Faq';
import Terms from './Components/Ourpolicies/Terms';
import Cookie from './Components/Ourpolicies/Cookie';
import Shopify from './Components/Intergration/Shopify';
import AppHeader from './Components/Navbar';
import Woocommerce from './Components/Intergration/Woocommerce';
import Footer from './Components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Ebay from './Components/Intergration/Ebay';
import Bigcommerce from './Components/Intergration/Bigcommerce';
import Wix from './Components/Intergration/Wix';
import Trending from './Components/TrendingProducts/Index';
import Products from './Components/data/Product.js';
import Pricing from './Components/Pricing/index';
import TrendingDummy from './Components/TrendingDummy';
import Form from './Components/Form/Form.js';

function App() {


  return (
    <div className="App">
      <AppHeader />
      <Routes>
        <Route path="/" element={<AppMain />} />
        <Route path="/intergration" element={<Intergrate />} />
        <Route path="/partnership" element={<Apppartnership />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/Refund-Return-policy" element={<Refund />} />
        <Route path="/FAQ-Page" element={<Faq />} />
        <Route path="/terms-and-use" element={<Terms />} />
        <Route path="/Cookies-Policy" element={<Cookie />} />
        <Route path="/Shopify" element={<Shopify />} />
        <Route path="/Woocommerce" element={<Woocommerce />} />
        <Route path="/eBay" element={<Ebay />} />
        <Route path="/Bigcommerce" element={<Bigcommerce />} />
        <Route path="/Wix" element={<Wix />} />
        <Route path="/Trending" element={<Trending />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/dummy" element={<TrendingDummy />} />
        <Route path="/form" element={<Form />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
