// import React, { useEffect } from 'react';
// import { Button, Col, Container, Row } from 'react-bootstrap';
// import axios from 'axios';
// import { useState } from 'react';
// import PropTypes from 'prop-types';
// import { BsChevronRight } from 'react-icons/bs';
// import { AiOutlinePlus } from 'react-icons/ai';
// import { TfiDownload } from 'react-icons/tfi';
// import { GrCheckbox } from 'react-icons/gr';
// import { IoIosTimer } from 'react-icons/io';
// import { PuffLoader } from 'react-spinners';
// // import Dropdown from 'react-bootstrap/Dropdown';
// function AllGrid({ item }) {
//   return (
//     <div key={item.id} className="grid-items">
//       <div>
//         <img src={item.image} className="images-img" />
//       </div>
//       <h6 className="grid-title">{item.title}</h6>
//       <div className="d-flex justify-content-between px-2">
//         <h6
//           className="grid-key"
//           style={{
//             color: item.stockStatus === 'Outstock' ? 'red' : 'black',
//             fontWeight: item.stockStatus === 'Outstock' ? 'bold' : null,
//           }}
//         >
//           {item.stockStatus}
//         </h6>
//         <h6 className="grid-key">
//           <span>
//             <IoIosTimer className="mx-1" />
//           </span>
//           {'1 BussinessDays'}
//         </h6>
//       </div>
//       <Button className="addtoinventory">Add to Inventory</Button>
//     </div>
//   );
// }

// function Index() {
//   const [allproducts, setAllProducts] = useState([]);
//   const [current, setCurrentPage] = useState(1);
//   const records = 16;
//   const lastIndex = current * records;
//   const firstIndex = lastIndex - records;
//   const allrecords = allproducts.slice(firstIndex, lastIndex);
//   const npages = Math.ceil(allproducts.length / records);
//   const number = [...Array(npages + 1).keys()].slice(1);
//   const [isinput, setIsInput] = useState(null);
//   const [iscategory, setCategory] = useState([]);
//   const [isloading, setIsLoading] = useState(false);
//   const [isinputcheck, setIsInputCheck] = useState(null);

//   useEffect(() => {
//     window.scroll({ top: 0, left: 0, behavior: 'smooth' });
//     axios
//       .get('https://gateway.dockify.co/api/DuplicateProduct/DuplicateProduct')
//       .then((response) => {
//         const category = response.data[0].getDuplicateProduct.map((item) => {
//           return item.category;
//         });
//         setAllProducts(response.data[0].getDuplicateProduct);
//         setCategory([...new Set(category)]);
//       });
//   }, []);

//   // const list = [
//   //   'Home, Garden & Tools',
//   //   'Beauty & Health',
//   //   'Outdoor',
//   //   'Clothing, Shoes & Jewelry',
//   //   'Toys, Kids & Baby',
//   //   'Sports',
//   //   'Pets',
//   //   'Electronics',
//   //   'Computers, Office Supplies',
//   //   'Industrial',
//   //   'Arts & Crafts',
//   //   'Automotive',
//   //   'Entertainment',
//   // ];
//   const options = [
//     'Free Shipping',
//     'New Arrivals',
//     'Prepaid Label',
//     'On Sale',
//     'UPC Available',
//     'ASIN Available',
//     'Products with Video',
//   ];
//   const restriction = [
//     ' Amazon',
//     'eBay',
//     'Newegg',
//     'Walmart',
//     'Overstock',
//     'Wish',
//     'AliExpress',
//     'Wayfair',
//   ];
//   const from = [
//     'United States',
//     'China',
//     'Australia',
//     'Canada',
//     'Hong Kong S.A.R.',
//     'Japan',
//     'Spain',
//     'United Kingdom',
//   ];
//   const to = [
//     'Ship To',
//     'United States',
//     'Australia',
//     'Canada',
//     'France',
//     'Germany',
//     'Puerto Rico',
//   ];
//   const process = [
//     'Within 1',
//     'Within 3',
//     'Within 5',
//     'Within 7',
//     'Within 10',
//     'Within 15',
//   ];
//   const shipping = [
//     'Within 3',
//     'Within 7',
//     'Within 15',
//     'Within 30',
//     'More than 30',
//   ];
//   const stockStatus = ['Instock', 'Outstock'];
//   const prepage = () => {
//     if (current !== firstIndex) {
//       setCurrentPage(current - 1);
//     }
//   };
//   const change = (id) => {
//     setCurrentPage(id);
//   };
//   const Nextpage = () => {
//     if (current !== lastIndex) {
//       setCurrentPage(current + 1);
//     }
//   };
//   const checkHandler = async (item) => {
//     setIsInput(item);
//     setIsLoading(true);
//     try {
//       const response = await axios.get(
//         `https://gateway.dockify.co/api/DuplicateProduct/DuplicateProduct?category=${item}`
//       );
//       setAllProducts(response.data[0].getDuplicateProduct);
//       setIsLoading(false);
//     } catch (error) {
//       setIsLoading(false);
//       console.error('Error fetching data:', error);
//     }
//   };
//   const StockcheckHandler = async (item) => {
//     setIsInputCheck(item);
//     setIsLoading(true);
//     try {
//       const response = await axios.get(
//         `https://gateway.dockify.co/api/DuplicateProduct/DuplicateProduct?StockStatus=${item}`
//       );
//       setAllProducts(response.data[0].getDuplicateProduct);
//       setIsLoading(false);
//     } catch (error) {
//       setIsLoading(false);
//       console.error('Error fetching data:', error);
//     }
//   };
//   return (
//     <>
//       <Container className="mt-3">
//         <Row>
//           <Col
//             sm={3}
//             lg={3}
//             xl={3}
//             xxl={3}
//             xs={3}
//             className="scroll-bar filter-display"
//           >
//             <div className="filters">
//               <h5 className="trending-products-title">Categories</h5>
//               <ul>
//                 {iscategory.map((item, index) => {
//                   return (
//                     <div key={index}>
//                       <div className="d-flex">
//                         <input
//                           type="checkbox"
//                           style={{ accentColor: '#FF4A17' }}
//                           checked={isinput === item ? true : false}
//                           onChange={() => checkHandler(item)}
//                         />
//                         <li className="trending-products-list mx-2">{item}</li>
//                       </div>
//                     </div>
//                   );
//                 })}
//               </ul>
//               <ul>
//                 {options.map((item, index) => {
//                   return (
//                     <div key={index}>
//                       <li className="trending-products-list option-box">
//                         {item}
//                       </li>
//                     </div>
//                   );
//                 })}
//               </ul>{' '}
//               <h5 className="trending-products-title">Stock Status</h5>
//               <ul>
//                 {stockStatus.map((item, index) => {
//                   return (
//                     <div key={index}>
//                       <div className="d-flex">
//                         <input
//                           type="checkbox"
//                           style={{ accentColor: '#FF4A17' }}
//                           checked={isinputcheck === item ? true : false}
//                           onChange={() => StockcheckHandler(item)}
//                         />
//                         <li className="trending-products-list mx-2">{item}</li>
//                       </div>
//                     </div>
//                   );
//                 })}
//               </ul>{' '}
//               <h5 className="trending-products-title">No Restriction</h5>
//               <ul>
//                 {restriction.map((item, index) => {
//                   return (
//                     <div key={index}>
//                       <li className="trending-products-list">
//                         <div className="d-flex">
//                           <input type="checkbox" className="mx-2" />
//                           {item}
//                         </div>
//                       </li>
//                     </div>
//                   );
//                 })}
//               </ul>{' '}
//               <h5 className="trending-products-title">Ship From</h5>
//               <ul>
//                 {from.map((item, index) => {
//                   return (
//                     <div key={index}>
//                       <li className="trending-products-list">{item}</li>
//                     </div>
//                   );
//                 })}
//               </ul>
//               <h5 className="trending-products-title">Ship To</h5>
//               <ul>
//                 {to.map((item, index) => {
//                   return (
//                     <div key={index}>
//                       <li className="trending-products-list">{item}</li>
//                     </div>
//                   );
//                 })}
//               </ul>{' '}
//               <h5 className="trending-products-title">Processing Time</h5>
//               <ul>
//                 {process.map((item, index) => {
//                   return (
//                     <div key={index}>
//                       <li className="trending-products-list">{item}</li>
//                     </div>
//                   );
//                 })}
//               </ul>{' '}
//               <h5 className="trending-products-title">Shipping Time</h5>
//               <ul>
//                 {shipping.map((item, index) => {
//                   return (
//                     <div key={index}>
//                       <li className="trending-products-list">{item}</li>
//                     </div>
//                   );
//                 })}
//               </ul>
//             </div>
//           </Col>
//           <Col
//             sm={9}
//             lg={9}
//             xl={9}
//             xxl={9}
//             xs={9}
//             className="scroll-bar container-display"
//           >
//             <h1 className="records">
//               Products
//               <BsChevronRight style={{ fontSize: '10px' }} className="mx-2" />
//               All Products <span className="mx-2">( 419,567 Results )</span>
//             </h1>
//             <div className="filter-box">
//               <div className="most-relavent">
//                 <div>
//                   <div className="filter-boxes">
//                     <span>
//                       <GrCheckbox className="filter-icon" />
//                     </span>
//                     <span className="filter-keys">Select All</span>
//                   </div>
//                   <div className="filter-boxes">
//                     <span>
//                       <AiOutlinePlus className="filter-icon" />
//                     </span>
//                     <span className="filter-keys">Add to Inventory List</span>
//                   </div>
//                   <div className="filter-boxes">
//                     <span>
//                       <TfiDownload className="filter-icon" />
//                     </span>
//                     <span className="filter-keys">Download</span>
//                   </div>
//                 </div>
//                 <div>
//                   <div className="filter-boxes">Sort by</div>
//                 </div>
//                 {/* <Dropdown>
//                     <Dropdown.Toggle
//                       variant="success"
//                       id="dropdown-basic"
//                       className="dropdown-box"
//                     >
//                       Sort by
//                     </Dropdown.Toggle>

//                     <Dropdown.Menu>
//                       <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
//                       <Dropdown.Item href="#/action-2">
//                         Another action
//                       </Dropdown.Item>
//                       <Dropdown.Item href="#/action-3">
//                         Something else
//                       </Dropdown.Item>
//                     </Dropdown.Menu>
//                   </Dropdown> */}
//               </div>
//             </div>
//             {isloading ? (
//               <div className="loader">
//                 <PuffLoader color="#FF4A17" />
//               </div>
//             ) : (
//               <div className="allproducts-card-Grid">
//                 {allrecords.map((item, index) => (
//                   <AllGrid key={index} keys={index} item={item} />
//                 ))}
//               </div>
//             )}

//             <nav className="d-flex justify-content-center mt-5">
//               <ul className="pagination">
//                 <li className="page-item">
//                   <a href="#" className="page-link" onClick={prepage}/>
//                     Previous

//                 </li>
//                 {number.map((item, index) => (
//                   <li
//                     className={`page-item ${current === item ? 'active' : ''}`}
//                     key={index}
//                   >
//                     <a
//                       href="#"
//                       className="page-link"
//                       onClick={() => change(item)}
//                     />
//                       {item}

//                   </li>
//                 ))}
//                 <li className="page-item">
//                   <a href="#" className="page-link" onClick={Nextpage}/>
//                     Next

//                 </li>
//               </ul>
//             </nav>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// }
// AllGrid.propTypes = {
//   item: PropTypes.object.isRequired,
// };
// export default Index;

import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import { AiOutlinePlus } from 'react-icons/ai';
import { TfiDownload } from 'react-icons/tfi';
import { GrCheckbox } from 'react-icons/gr';
import { IoIosTimer } from 'react-icons/io';
import { PuffLoader } from 'react-spinners';
import ReactPaginate from 'react-paginate';

function AllGrid({ item }) {
  return (
    <div key={item.title} className="grid-items">
      <div>
        <img src={item.imageUrl?.[0]} className="images-img" alt={item.title} />
      </div>
      <h6 className="grid-title">{item.title}</h6>
      <div className="d-flex justify-content-between px-2">
        <h6
          className="grid-key"
          style={{
            color: item.stockStatus === 'Outstock' ? 'red' : 'black',
            fontWeight: item.stockStatus === 'Outstock' ? 'bold' : null,
          }}
        >
          {item.stockStatus}
        </h6>
        <h6 className="grid-key">
          <span>
            <IoIosTimer className="mx-1" />
          </span>
          {'1 Business Day'}
        </h6>
      </div>
      <Button className="addtoinventory" variant="secondary">
        {' '}
        <a href="https://app.dockify.co/login" target="blank"/>
          Add to Inventory

      </Button>
    </div>
  );
}

function Index() {
  const [allProducts, setAllProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectedLineItemType, setSelectedLineItemType] = useState('');
  const [selectedSubcategories, setSelectedSubcategories] = useState({
    Gold: [],
    Diamond: [],
    Silver: [],
  });

  const [selectedStockStatus, setSelectedStockStatus] = useState([]);
  const [uniqueGoldSubCategories, setUniqueGoldSubCategories] = useState([]);
  const [uniqueDiamondSubCategories, setUniqueDiamondSubCategories] = useState(
    []
  );
  const [uniqueSilverSubCategories, setUniqueSilverSubCategories] = useState(
    []
  );
  const [isInput, setIsInput] = useState(null);
  const [iscategory, setIsCategory] = useState([]);
  const [paginationData, setPaginationData] = useState({
    productCount: 12,
    currPage: 1,
    perPage: 20,
    lastPage: 84,
    nextPage: 2,
    prevPage: null,
    totalProductCount: 1000,
    ack: 'Success',
    messages: null,
  });

  const baseUrl = 'https://gateway.dockify.co';
  const stockStatus = ['In Stock', 'OutOfStock'];

  const fetchData = async (params) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}/DockifyProduct/GetPagination`,
        { params }
      );
      console.log('API Response:', response);
      const category = response.data.list.map((item) => item.category);
      setIsCategory(category);
      setAllProducts(response.data.list);
      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        lastPage: response.data.lastPage,
        totalProductCount: response.data.totalProductCount,
      }));

      setNoDataFound(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setNoDataFound(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (selectedPage) => {
    const page = Math.max(selectedPage.selected + 1, 1);

    if (page > 0) {
      const offset = (page - 1) * paginationData.perPage;
      fetchData({
        Page: page,
        SupplierId: 1,
        CategoryName: selectedLineItemType,
        SubCategoryName: Array.isArray(
          selectedSubcategories[selectedLineItemType]
        )
          ? selectedSubcategories[selectedLineItemType].join(',')
          : '',
        StockStatus: selectedStockStatus.join(','),
        PerPage: paginationData.perPage,
        Offset: offset,
      });
    }
  };

  const handleLineItemTypeChange = (lineItemType) => {
    setSelectedLineItemType(lineItemType);
    setSelectedSubcategories({
      ...selectedSubcategories,
      [lineItemType]: [],
    });
    setSelectedStockStatus([]);

    fetchData({
      Page: 1,
      SupplierId: 1,
      CategoryName: lineItemType,
      SubCategoryName: '',
      StockStatus: '',
      PerPage: 20,
    });
  };

  useEffect(() => {
    handleLineItemTypeChange('Gold');
  }, []);

  const handleSubCategoryChange = (subCategory) => {
    const updatedSubcategories = [subCategory];

    setSelectedSubcategories((prevSelectedSubcategories) => ({
      ...prevSelectedSubcategories,
      [selectedLineItemType]: updatedSubcategories,
    }));

    fetchData({
      Page: 1,
      SupplierId: 1,
      CategoryName: selectedLineItemType,
      SubCategoryName: subCategory,
      StockStatus: selectedStockStatus.join(','),
      PerPage: 20,
    });
  };

  const handleCheckboxChange = async (
    lineItemType,
    subCategory,
    setSelectedItems,
    handler
  ) => {
    const updatedSubcategories = {
      ...selectedSubcategories,
      [lineItemType]: [subCategory],
    };

    setSelectedItems(updatedSubcategories);
    handler(updatedSubcategories[lineItemType]);

    setSelectedStockStatus([]);

    fetchData({
      Page: 1,
      SupplierId: 1,
      CategoryName: lineItemType,
      SubCategoryName: updatedSubcategories[lineItemType].join(','),
      StockStatus: '',
      PerPage: 20,
    });
  };

  const checkHandler = async (item) => {
    setIsLoading(true);

    try {
      // If the same category is clicked again, toggle the checkbox state
      if (isInput === item) {
        setIsInput(null);
        setSelectedLineItemType('');
        setSelectedSubcategories({
          Gold: [],
          Diamond: [],
          Silver: [],
        });
        setSelectedStockStatus([]);
        setPaginationData({
          ...paginationData,
          lastPage: 0,
          totalProductCount: 0,
        }); // Reset pagination data
      } else {
        // Fetch products based on the selected category
        const page = 1;
        const subCategoryName = selectedSubcategories[item]?.join(',') || '';
        const stockStatus = selectedStockStatus.join('');

        const apiUrl = `${baseUrl}/DockifyProduct/GetPagination?Page=${page}&SupplierId=${1}&CategoryName=${item}&SubCategoryName=${subCategoryName}&MinPrice=${''}&MaxPrice=${''}&StockStatus=${stockStatus}&PerPage=${12}`;

        console.log('API URL:', apiUrl);

        const response = await axios.get(apiUrl);

        console.log('API Response:', response);

        setAllProducts(response.data.list);
        setSelectedLineItemType(item);
        setIsInput(item); // Set the state to the clicked category
        setPaginationData({
          ...paginationData,
          lastPage: response.data.lastPage,
          totalProductCount: response.data.totalProductCount,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const StockcheckHandler = (item) => {
    let updatedStockStatus;

    if (item === 'In Stock') {
      updatedStockStatus = ['In Stock'];
    } else if (item === 'OutOfStock') {
      updatedStockStatus = ['OutOfStock'];
    } else {
      updatedStockStatus = [];
    }

    setSelectedStockStatus(updatedStockStatus);

    fetchData({
      Page: 1,
      SupplierId: 1,
      CategoryName: selectedLineItemType || '',
      SubCategoryName: Array.isArray(
        selectedSubcategories[selectedLineItemType]
      )
        ? selectedSubcategories[selectedLineItemType].join(',')
        : '',
      StockStatus: updatedStockStatus.join(','),
      PerPage: 20,
    });
  };

  useEffect(() => {
    const apiUrl =
      'https://gateway.dockify.co/DockifyProduct/GetSupplierProduct?SupplierId=1';

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(apiUrl);
        const data = response.data;

        if (Array.isArray(data.list)) {
          const goldProducts = data.list.filter(
            (item) => item.lineItemType === 'Gold'
          );
          const diamondProducts = data.list.filter(
            (item) => item.lineItemType === 'Diamond'
          );
          const silverProducts = data.list.filter(
            (item) => item.lineItemType === 'Silver'
          );

          setUniqueGoldSubCategories([
            ...new Set(goldProducts.map((item) => item.subCategory)),
          ]);
          setUniqueDiamondSubCategories([
            ...new Set(diamondProducts.map((item) => item.subCategory)),
          ]);
          setUniqueSilverSubCategories([
            ...new Set(silverProducts.map((item) => item.subCategory)),
          ]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    fetchData({
      Page: 1,
      SupplierId: '',
      CategoryName: '',
      SubCategoryName: '',
      MinPrice: '',
      MaxPrice: '',
      StockStatus: '',
      PerPage: 20,
    });
  }, []);

  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col
          sm={2}
          lg={2}
          xl={2}
          xxl={2}
          xs={2}
          className="scroll-bar filter-display"
        >
          <div className="filters">
            <h5 className="trending-products-title">Categories</h5>
            <ul>
              {iscategory.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        style={{ accentColor: '#FF4A17', display: 'none' }}
                        checked={isInput === item ? true : false}
                        onChange={() => checkHandler(item)}
                      />
                      <li className="trending-products-list mx-2">{item}</li>
                    </div>
                  </div>
                );
              })}
            </ul>
            <div className="catagories-data">
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <label className="trending-products-list">
                    <input
                      type="checkbox"
                      style={{ accentColor: '#FF4A17', marginRight: '5px' }}
                      checked={selectedLineItemType === 'Gold'}
                      onChange={() => handleLineItemTypeChange('Gold')}
                    />
                    Gold
                  </label>
                </li>

                <li>
                  <label className="trending-products-list">
                    <input
                      type="checkbox"
                      style={{ accentColor: '#FF4A17', marginRight: '5px' }}
                      checked={selectedLineItemType === 'Diamond'}
                      onChange={() => handleLineItemTypeChange('Diamond')}
                    />
                    Diamond
                  </label>
                </li>

                <li>
                  <label className="trending-products-list">
                    <input
                      type="checkbox"
                      style={{ accentColor: '#FF4A17', marginRight: '5px' }}
                      checked={selectedLineItemType === 'Silver'}
                      onChange={() => handleLineItemTypeChange('Silver')}
                    />
                    Silver
                  </label>
                </li>
              </ul>
            </div>
            <h5 className="trending-products-title">Sub Categories</h5>
            <div className="subcategories">
              <ul>
                {selectedLineItemType === 'Diamond' && (
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {uniqueDiamondSubCategories.map((subCategory, index) => (
                      <li key={index} className="trending-products-list">
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              accentColor: '#FF4A17',
                              marginRight: '5px',
                            }}
                            checked={
                              selectedSubcategories.Diamond &&
                              selectedSubcategories.Diamond.includes(
                                subCategory
                              )
                            }
                            onChange={() =>
                              handleCheckboxChange(
                                'Diamond',
                                subCategory,
                                setSelectedSubcategories,
                                () => handleSubCategoryChange(subCategory)
                              )
                            }
                          />
                          {subCategory}
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </ul>
              <ul>
                {selectedLineItemType === 'Gold' && (
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {uniqueGoldSubCategories.map((subCategory, index) => (
                      <li key={index} className="trending-products-list">
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              accentColor: '#FF4A17',
                              marginRight: '5px',
                            }}
                            checked={
                              selectedSubcategories.Gold &&
                              selectedSubcategories.Gold.includes(subCategory)
                            }
                            onChange={() =>
                              handleCheckboxChange(
                                'Gold',
                                subCategory,
                                setSelectedSubcategories,
                                () => handleSubCategoryChange(subCategory)
                              )
                            }
                          />

                          {subCategory}
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </ul>
              {selectedLineItemType === 'Silver' && (
                <ul style={{ listStyle: 'none' }}>
                  {uniqueSilverSubCategories.map((subCategory, index) => (
                    <li key={index} className="trending-products-list">
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="checkbox"
                          style={{
                            accentColor: '#FF4A17',
                            marginRight: '5px',
                          }}
                          checked={
                            selectedSubcategories.Silver &&
                            selectedSubcategories.Silver.includes(subCategory)
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              'Silver',
                              subCategory,
                              setSelectedSubcategories,
                              () => handleSubCategoryChange(subCategory)
                            )
                          }
                        />

                        {subCategory}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <h5 className="trending-products-title">Stock Status</h5>
            <ul>
              {stockStatus.map((item, index) => (
                <div key={index} className="subcategories">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      style={{ accentColor: '#FF4A17' }}
                      checked={selectedStockStatus.includes(item)}
                      onChange={() => StockcheckHandler(item)}
                    />
                    <li
                      className={`trending-products-list mx-2 ${
                        selectedStockStatus.includes(item)
                          ? 'selected-filter'
                          : ''
                      }`}
                    >
                      {item}
                    </li>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </Col>
        <Col
          sm={10}
          lg={10}
          xl={10}
          xxl={10}
          xs={10}
          className="scroll-bar container-display"
        >
          <h1 className="records">
            Products
            <BsChevronRight style={{ fontSize: '10px' }} className="mx-2" />
            All Products{' '}
            <span className="mx-2">
              ( {paginationData.totalProductCount} Results )
            </span>
          </h1>
          <div className="filter-box">
            <div className="most-relavent">
              <div>
                <div className="filter-boxes">
                  <span>
                    <GrCheckbox className="filter-icon" />
                  </span>
                  <span className="filter-keys">Select All</span>
                </div>
                <div className="filter-boxes">
                  <span>
                    <AiOutlinePlus className="filter-icon" />
                  </span>
                  <span className="filter-keys">Add to Inventory List</span>
                </div>
                <div className="filter-boxes">
                  <span>
                    <TfiDownload className="filter-icon" />
                  </span>
                  <span className="filter-keys">Download</span>
                </div>
              </div>
              <div>
                <div className="filter-boxes">Sort by</div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="loader">
              <PuffLoader color="#FF4A17" />
            </div>
          ) : noDataFound ? (
            <div className="no-data-found-message">
              <img src="/img/notfound.7766fc0.svg" alt="No Data Found" />
              <p>No data</p>
            </div>
          ) : (
            <div className="allproducts-card-Grid">
              {allProducts.map((item, index) => (
                <AllGrid key={index} keys={index} item={item} />
              ))}
            </div>
          )}
        </Col>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <div className="align-items-center d-flex justify-content-center">
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                pageCount={paginationData.lastPage}
                marginPagesDisplayed={3}
                pageRangeDisplayed={6}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
                activeLinkClassName={'active-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disableInitialCallback={true}
              />
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

AllGrid.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Index;

// import React from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';

// const Index = () => {
//   return (
// <Container>
// <Row>
//   <Col className='productspricingsdata m-2'>
//     <div className="filter-box">
//       <div className="most-relavent">
//         <div>
//           <div className="filter-boxes">
//             <span>
//               <GrCheckbox className="filter-icon" />
//             </span>
//             <span className="filter-keys">Select All</span>
//           </div>
//           <div className="filter-boxes">
//             <span>
//               <AiOutlinePlus className="filter-icon" />
//             </span>
//             <span className="filter-keys">Add to Inventory List</span>
//           </div>
//           <div className="filter-boxes">
//             <span>
//               <TfiDownload className="filter-icon" />
//             </span>
//             <span className="filter-keys">Download</span>
//           </div>
//         </div>
//         <div>
//           <div className="filter-boxes">Sort by</div>
//         </div>
//       </div>
//     </div>
//   </Col>
// </Row>
// </Container>

// export default Index;
// import React from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import headset from '../../assests/studio-monitor-dj-headphones.webp';
// import shoe from '../../assests/shoe-hiking.webp';
// import sweater from '../../assests/men-sweater.webp';
// import kitchen from '../../assests/kitchen-pantry.webp';
// import bedAdjust from '../../assests/bed-with-adjustable.webp';
// import Wheels from '../../assests/wheels.webp';
// import Doll from '../../assests/doll-pillow-childrens.webp';
// import DogRope from '../../assests/dog-rope.webp';
// import ClothHanging from '../../assests/clothing-hanging.webp';
// import Chair from '../../assests/office-chair.webp';
// import bluetooth from '../../assests/bluetooth-earphones.webp';
// import Watch from '../../assests/quartz-watch-mens.webp';

// const Index = () => {
//   const cardData = [
//     {
//       imgSrc: headset,
//       alt: 'img',
//       title: 'Bluetooth 5.2 Headphones',
//       text: 'A70 Bluetooth 5.2 Headphones Stereo Over Ear Wireless Headset Professional Recording Studio Monitor DJ Headphones',
//     },
//     {
//       imgSrc: shoe,
//       alt: 'img',
//       title: 'Kids Running Shoes Anti-Skid Sports',
//       text: 'Winter Kids Running Shoes Anti-Skid Sports Shoe Outdoor Warm Plus Fur Sneakers Light Soft Flat Children Shoe Hiking Waterproof',
//     },
//     {
//       imgSrc: sweater,
//       alt: 'img',
//       title: 'Winter Sweater Men',
//       text: 'Dropship Winter Sweater Men Turtleneck Thick Warm Fleece Cardigan Men Sweater Cashmere Wool Men Sweater Pull Homme Sueter Hombre Share to: Notice a problem Winter Sweater Men Turtleneck Thick Warm Fleece Cardigan Men Sweater Cashmere Wool Men Sweater Pull Homme Sueter Hombre',
//     },
//     {
//       imgSrc: kitchen,
//       alt: 'img',
//       title: 'Food Storage Containers Set',
//       text: 'Food Storage Containers Set, Kitchen Pantry Organization and Storage with Easy Lock Lids, 8 Pieces',
//     },
//     {
//       imgSrc: bedAdjust,
//       alt: 'img',
//       title: 'Modern velvet double sofa',
//       text: '55.1-inch 3-in-1 convertible sofa bed, modern velvet double sofa Futon sofa bed with adjustable back, storage bag and pillow, for living room, bedroom (blue)',
//     },
//     {
//       imgSrc: Wheels,
//       alt: 'img',
//       title: 'Wheels for Boys & Girls',
//       text: '"12"/14" Kids Bike with Training Wheels for Boys Girls Ages 3-8 Years"',
//     },
//     {
//       imgSrc: Doll,
//       alt: 'img',
//       title: 'Cute Rabbit Plush Toy Doll Pillow Children',
//       text: '8.27inch Cute Rabbit Plush Toy Doll Pillow Children s Holiday Gift Easter Bunny',
//     },
//     {
//       imgSrc: DogRope,
//       alt: 'img',
//       title: 'Dog Rope Pet Pulling Rope',
//       text: 'Dog Rope Pet Pulling Rope Puppy Strap Traction Rope Heavy Duty Belt Large Dog Leash Dog Collar Strap Dog Training Pet Harness Hands-Free Leash For Small Dogs Multicolor Pet Supplies',
//     },
//     {
//       imgSrc: ClothHanging,
//       alt: 'img',
//       title: 'Clothing Hanging Rail Pillow',
//       text: 'Garment Hanging Rack Clothing Hanging Rail Pillow Shoe Display Organizer Stand Rolling Wheel Clothes Organizer',
//     },
//     {
//       imgSrc: Chair,
//       alt: 'img',
//       title: 'Big and Tall Office Chair',
//       text: 'Big and Tall Office Chair, Back Support Office Chair, Glossy PU Leather Executive Office Chair, Reclining Office Chair, Office Chair with Retractable Footrest and Liftable Padded Armrest',
//     },
//     {
//       imgSrc: bluetooth,
//       alt: 'img',
//       title: 'Air Pro 6 TWS',
//       text: 'Air Pro 6 TWS Wireless Headphones with Mic Bluetooth Earphones Sport Earbuds Pro6 J6 Headset for Apple iPhone Xiaomi Huawei',
//     },
//     {
//       imgSrc: Watch,
//       alt: 'img',
//       title: 'Watch Leather Military Sports',
//       text: ' Mens Circular Pointer Watch Leather Military Sports Date Quart Watch Mens',
//     },
//   ];
//   return (
//     <Container className="py-5 bg-white">
//       {cardData
//         .reduce((rows, item, index) => {
//           if (index % 4 === 0) rows.push([]);
//           rows[rows.length - 1].push(item);
//           return rows;
//         }, [])
//         .map((row, rowIndex) => (
//           <Row key={rowIndex} className="mb-4">
//             {row.map((item, colIndex) => (
//               <Col key={colIndex} xs={12} sm={6} md={6} lg={3} className="mb-4">
//                 <Card>
//                   <div className="image__wrapper">
//                     <div className="card__shadow--1"></div>
//                     <Card.Img
//                       variant="top"
//                       src={item.imgSrc}
//                       alt={item.alt}
//                       className="img-fluid"
//                     />
//                   </div>
//                   <Card.Body className="pb-4" style={{ height: '130px' }}>
//                     <Card.Title className="Cardtitle-text">
//                       {item.title}
//                     </Card.Title>
//                     <Card.Text className="CardText-text">{item.text}</Card.Text>
//                   </Card.Body>
//                   <a
//                     href="https://app.dockify.co/"
//                     className="d-flex justify-content-center addtoinventory rounded-bottom"
//                   >
//                     Add to Inventory
//                   </a>
//                 </Card>
//               </Col>
//             ))}
//           </Row>
//         ))}
//     </Container>
//   );
// };

// export default Index;
