import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

function Refund() {
  return (
    <div>
      <Container>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className='privacy-box px-5 py-3'>
              <h4
                className='text-center'
                style={{ textDecoration: 'underline' }}
              >
                Return & Refund Policy
              </h4>
              <p className='mt-5'>
                To create a great trading environment and improve the services
                of Dockify.co, Dockify hereby issues this general return and
                refund policy. This Policy applies to all users on Dockify.co.
              </p>
              <h1>General Terms:</h1>
              <p>
                Dockify reserves the right to update the rules when necessary,
                and the updated version will be published on Dockify.co with
                immediate effect. If you refuse to accept the policy, you are
                deemed to have waived the use of the services provided by
                Dockify immediately. All buyers or suppliers are deemed to
                accept the rules if they are using the services provided by
                Dockify. Supplier would publish their own Return & Refund Policy
                on the product detail page. Dockify’ s policy shall prevail in
                case of conflict with supplier’s policy.
              </p>
              <br />
              <p>
                For after-sales issues, buyers should file a return & refund
                request within 30 days of the delivery date (except for several
                suppliers whose after-sales period was agreed by Dockify). The
                period of Return & Refund request filed exceeds, the supplier
                reserves the right not to provide any after-sales service after
                that time.
              </p>
              <br />
              <p>
                Before an order is shipped out, the buyer has the right to apply
                to cancel the order by clicking the request refund button in
                Dockify buyer centre, or by contacting Dockify customer service
                to cancel the order. Please note some supplier policies do not
                accept order cancellations before the order is shipped out. The
                supplier should agree or reject the cancellation request within
                3 business days.
              </p>
              <br />
              <p>
                If the supplier cannot fulfill the buyers order within the order
                processing time indicated on Dockify’ s product page, under the
                condition, but not limited to no stock or other conditions, the
                supplier has the right to cancel and refund the order.If the
                buyer receives goods damaged or broken and applies for a refund
                with compelling evidence, and the goods need to be returned, the
                supplier has the responsibility to offer the buyer a prepaid
                return label within 3 business days to return damaged/broken
                goods. The supplier should refund the buyer when the return
                shows as in transit on the logistic website with the suppliers
                provided prepaid return label.
              </p>
              <p>
                Dockify shall not require supplier to accept after-sale request
                for non-defective remorse returns, supplier has the right to
                accept or reject the request as his policy show as on the
                Dockify product page. Dockify will abide by the suppliers policy
                under this condition.
                <br />
                <br />
                If supplier accepts the after-sale request for the reason type
                of non-defective remorse returns and require to deduct a
                restocking & repacking fee, under Dockify’ s policy restriction,
                the restocking & repacking fee in total supplier deducted shall
                not exceed 35% of order total price.
                <br />
                <br />
                Dockify shall not require suppliers on warranty, like whether to
                provide warranty or not, or the way of warranty handling with.
                <br />
                <br />
                Dockify shall restrict with the refund time and redelivery time
                under the condition, included but not limited to order should
                refund based on suppliers policy or Dockify’ s policy, agree on
                refund or redelivery between supplier and buyer or others,
                supplier must refund not more than 3 business days, or
                redelivery not more than 5 business days.
                <br />
                <br />
                Once the buyer submits the after-sales request, the buyer needs
                to provide evidence (as the clause states below) to the supplier
                or Dockify. If the buyer cannot provide compelling evidence
                within the order after-sales period policy indicated, the
                supplier and or Dockify has the right to reject the return and
                refund request.
                <br />
                <br />
                Whether the evidence is compelling or not will ultimately be
                determined by Dockify.Under Various Circumstances:
              </p>
              <h4>1. Refund Request Timing</h4>
              <Table bordered hover>
                <thead>
                  <tr className='text-center'>
                    <th colSpan={2}>Return & Refund Type</th>
                    <th>Dockify Rules</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>For Unshipped Orders</td>
                    <td>
                      The Return & Refund request is made after the stated
                      processing time.
                    </td>
                    <td>Dockify will refund the order.</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      The Return & Refund request is made within the stated
                      processing time.
                    </td>
                    <td>
                      The supplier has 3 business days to process the request
                      after the buyer submits it. If the supplier fails to
                      process (accept or reject) the refund request within 3
                      business days, Dockify will refund the order Please note:
                      Although the order is still within the stated processing
                      time, any refund request needs to be processed within the
                      time listed above.
                    </td>
                  </tr>
                  <tr>
                    <td>For Shipped Orders</td>
                    <td>
                      The Return & Refund request is made after the order has
                      shipped.
                    </td>
                    <td>
                      The supplier has 10 business days to process the request
                      after the buyer submits it. If the supplier fails to
                      process (accept or reject) the refund request within 10
                      business days, Dockify will refund the order.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h4>2. Goods Returned in Transit</h4>
              <p>
                If the package is sent back to the supplier during transit for
                any reason, and the buyer applies for a refund, the supplier
                should refund within 3 business days of the refund request.
                Under the same cases, if the buyer requests redelivery, the
                supplier should begin redelivery within 5 business days of the
                request date.
              </p>
              <h4>3. Goods Destroyed in Transit</h4>
              <p>
                If the package/goods are destroyed in transit, and buyer has
                proof from the logistics website such as but not limited to a
                screenshot from the logistics website that the order was
                destroyed, the supplier should refund the order within 3
                business days of buyers refund request, or redelivery within 5
                business days of the request.
              </p>
              <h4>4. Wrong Receipt Information</h4>
              <p>
                If the buyer puts wrong receipt information on an order, the
                buyer should contact the supplier via Contact Seller or contact
                Dockify customer service to request the supplier update the
                order with the correct receipt information. Please note the
                receipt information correction or order cancellation are not
                guaranteed.
                <br />
                <br />
                If the order has already shipped out, it is the buyers
                responsibility to contact the logistics company with the correct
                information.
                <br />
                <br />
                If the goods have been delivered to the wrong receipt, the buyer
                can attempt to send back the products at his/her own expense. If
                the goods are returned in good condition, the supplier shall
                refund the order within 3 business days of receiving the goods,
                as buyer’s refund request, or begin redelivery within 5 business
                days of receiving the goods. Please note buyer will additionally
                be in charge of the redelivery fee.
                <br />
                <br />
                If the order cannot be corrected or returned, the buyer will
                bear the losses.
              </p>
              <h4>
                5. Defective Products (Package breakage/significantly not as
                described/ missing or wrong goods/ quality problems)
              </h4>
              <p>
                If the buyer detects any problem upon receipt (damaged
                package/significantly not as described/missing or wrong goods or
                other quality problems), buyer should provide a refund or
                redelivery request with valid proof (photos/videos of goods
                received that shows the problem clearly). After the buyer files
                a request on Dockify, the supplier should check the proof and
                give a response within 3 business days.
                <br />
                <br />
                If the proof checked by the supplier is not showing the problem
                clearly and or if the proof checked by Dockify is not showing
                the problem clearly, the proof is invalid, and the
                supplier/Dockify has the right to reject the buyers refund
                request.
                <br />
                <br />
                If the buyers proof shows a problem clearly after being checked
                by the supplier/Dockify, the proof checking time shall not more
                than 3 business days, and if the goods need to be returned to
                get a refund, the supplier should provide the buyer with a
                prepaid return label within 3 business days of proof approval,
                and refund the order when the return shows in transit on the
                logistics website searched by tracking number buyer uploaded
                within 3 business days. If the goods neednt be returned, the
                supplier should refund the order within 3 business days of the
                refund request.
                <br />
                <br />
                If proof is valid, and buyer requests redelivery, the supplier
                should redelivery within 5 business days of proof approval.
                Please note suppliers have the right to refund order if the
                redelivery products have no stocks, or buyers are able to
                reorder products when the products are restocked.
              </p>
              <h4>6. Logistics Issues</h4>
              <h4>6.1 Invalid tracking information</h4>
              <p>
                If Dockify verifies the tracking number is wrong, and the
                supplier fails to provide the correct one within 4 business
                days, Dockify will refund the order.
              </p>
              <h4>6.2 Untimely Tracking Info Updates</h4>
              <p>
                If the transit time exceeds the estimated delivery time by 10
                days or more and the supplier fails to provide any valid
                tracking updated info, the buyer may apply for a refund. Dockify
                require supplier refund order within 3 business days as buyer
                request.Note: this clause is not applicable for special
                time/periods such as holidays, covid-19, abnormal weather, or
                other abnormal situations.
              </p>
              <h4>7. Other</h4>
              <p>
                When encountering holidays, covid-19, abnormal weather, or other
                abnormal situations, the buyer shall contact help@Dockify.co or
                contact the supplier through their Dockify account with the
                order Contact Message option to solve the goods or logistics
                issue.
                <br />
                <br />
                Dockify will try its best to assist buyers in these special
                circumstances in a timely manner. After notifying Dockify,
                Buyers should expect a decision from Dockify on these types of
                issues no later than two weeks.
              </p>
              <h4>8. Attention</h4>
              <p>
                If buyers are unsatisfied with the solution of the order
                after-sale issues, buyers have the responsibility to open a
                dispute with Dockify to help deal with the after-sale issues..
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Refund;
