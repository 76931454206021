import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Startyour() {
  return (
    <div>
      <Container>
        <Row className="mt-5">
          <Col
            className="col-lg-6 col-sm-12 col-12 Dropship-text"
            data-aos="fade-right"
          >
            <div>
              <h3>
                Dockify makes it easy for people to start and become successful
                in their dropshipping Business
              </h3>
              <p className="fst-italic jus">
                Dockify is the one-stop platform to help you Start, Optimize &
                Scale Successful dropshipping business.
              </p>
              <ul className="justify-content-start Dropship-text">
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li className="listing">
                      <b className="span-text">Business Model</b> -{' '}
                      <span className="span-text-1">
                        DropShiping allows you to first get an order from your
                        customer and then place an order with your Supplier thus
                        helping you to avoid any market risks.
                      </span>
                    </li>
                  </div>
                </div>{' '}
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Thorndike’s Theory</b> -{' '}
                      <span className="span-text-1">
                        DropShipping model allows you to make test runs with ‘n’
                        number of products for ‘n’ number of days to help you
                        read and understand the current market situation. Thus,
                        becoming the easiest, fastest and risk free model to
                        help you find what fits your business strategy.
                      </span>
                    </li>
                  </div>
                </div>
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Cashflow</b> -{' '}
                      <span className="span-text-1">
                        Budget has always been a blocker for new entrepreneurs.
                        With Dockify and DropShipping, you eliminate this by
                        sell first and buy later model.
                      </span>
                    </li>
                  </div>
                </div>
                {/* <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Repeat for Profit</b> -{' '}
                      <span className="span-text-1">
                        Find and offer more products for your customers and
                        close more deals to get more profits!
                      </span>
                    </li>
                  </div>
                </div> */}
                {/* <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Discover Products</b> -{' '}
                      <span className="span-text-1">
                        Look for products that match your niche from our
                        millions of product catalogue and add them to your own
                        store.
                      </span>
                    </li>
                  </div>
                </div> */}
              </ul>
              <p className="mt-5" data-aos="fade-right">
                Click here to know more about the Integrations Services
                &nbsp;&nbsp;
                <Link to="/intergration" className="started ">
                  Integration
                </Link>
              </p>
            </div>
          </Col>
          <Col className="col-lg-6 col-sm-12 col-12">
            <div data-aos="flip-left" data-aos-duration="1500">
              <img src="./img/features-2_v2.jpg" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Startyour;
