import React, { useEffect, useState, useRef } from 'react';
import { Card, Container, Row, Modal, Col } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { message } from 'antd';

const stripePromise = loadStripe(
  'pk_test_51OUjplHnHjydHcHgxY35BMKEnbXr7hHhBtlkfbD2WXBXuHUlBN055ndW5LSEzgOPN8jRZHGBkHEdKKdmQVeHfbmg00YHQKiPWV'
);

function AppPricing() {
  const [isPlan, setIsPlan] = useState([]);
  const [isAnnual, setIsAnnual] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [cardElement, setCardElement] = useState(null);
  const cardElementRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const baseUrl = 'https://gateway.dockify.co';

  useEffect(() => {
    fetchPlans('Monthly');
    fetchStripeData();
  }, []);

  useEffect(() => {
    if (cardElement && selectedPlan) {
      cardElement.mount('#card-element');
    }
  }, [selectedPlan, cardElement]);

  const fetchPlans = (validity) => {
    axios(`${baseUrl}/SellerPaymentPlan/Validity?Validity=${validity}`).then(
      (response) => {
        setIsPlan(response.data.lists);
      }
    );
  };

  const fetchStripeData = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        console.error('Stripe elements not available.');
        return;
      }
      const elements = stripe.elements();
      const card = elements.create('card');

      if (!card) {
        console.error('Error creating card Element.');
        return;
      }

      setCardElement(card);
    } catch (error) {
      console.error('Error initializing Stripe:', error);
    }
  };

  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe || !cardElement) {
        console.error('Stripe or card element is not available');
        return;
      }
      const result = await stripe.createToken(cardElement);
      if (result.error) {
        console.error(result.error.message);
        return;
      }
      const token = result.token;
      if (token) {
        await processPayment(token.id, token.card?.id);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const processPayment = async (tokenId, cardToken) => {
    try {
      const sessionDataString = localStorage.getItem('SESSION_DATA');
      const SESSION_DATA = JSON.parse(sessionDataString);

      const accessToken = SESSION_DATA.accessToken;

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(
        'https://gateway.dockify.co/api/PaymentGateway/StripeCreatePayment',
        {
          tokenId: tokenId,
          cardToken: cardToken,
          planId: planId.toString(),
        },
        { headers }
      );

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      const responseJson = response.data;
      message.success(responseJson);
    } catch (error) {
      message.error('Error:', error);
    }
  };

  const planHandler = () => {};

  const skipHandler = () => {};

  const handleToggle = (e) => {
    const validity = e.target.value;
    setIsAnnual(validity === 'Yearly');
    fetchPlans(validity);
  };

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setSelectedPlanPrice(plan.price);
    setIsModalOpen(true);
    setPlanId(plan.id);
  };

  const closeModal = () => {
    setSelectedPlan(null);
    setIsModalOpen(false);
  };

  return (
    <div id="pricing">
      <Container>
        <Row className="mt-5">
          <Col className="mt-5">
            <div className="mt-3">
              <div>
                <h2 className="about">PRICING</h2>
                <h2 className="bor d-flex"></h2>
              </div>
              <h1 className="intergration">
                FLEXIBLE PLANS FOR ANY BUSINESS SIZE
              </h1>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="Dockify-banner">
            <div className="d-flex align-items-center justify-content-around h-100">
              <div>
                <div className="d-flex align-items-center">
                  <div className="mx-3 Elevate">
                    Elevate your plan, enhance your benefits
                  </div>
                  <div className="upgrade" onClick={planHandler}>
                    Upgrade
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p className="showing">Maximize your benefits with Dockify</p>
          </div>
          <div>
            <div className="choose-plan">
              <FontAwesomeIcon
                icon={faTimes}
                className="fax"
                onClick={skipHandler}
              />
              <div className="form-check form-switch switch-toggle-container">
                <div className="container">
                  <div className="switches-container">
                    <input
                      type="radio"
                      id="switchMonthly"
                      name="switchPlan"
                      value="Monthly"
                      checked={!isAnnual}
                      onChange={handleToggle}
                    />
                    <input
                      type="radio"
                      id="switchYearly"
                      name="switchPlan"
                      value="Yearly"
                      checked={isAnnual}
                      onChange={handleToggle}
                    />
                    <label htmlFor="switchMonthly">Monthly</label>
                    <label htmlFor="switchYearly">Yearly</label>
                    <div className="switch-wrapper">
                      <div className="switch">
                        <div>Monthly</div>
                        <div>Yearly</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="pricing">
                <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 ">
                  {isPlan.map((item, index) => (
                    <Col key={item.id}>
                      <Card
                        className={`plan-cards box ${
                          index === 1 || index === 2
                            ? 'plan-cards-highlight'
                            : ''
                        }`}
                      >
                        <h3 className="mt-3 item-name">{item.name}</h3>
                        <p>
                          <span
                            className={` decimal ${
                              index === 1 || index === 2 ? ' decimal' : ''
                            }`}
                          >
                            $
                          </span>{' '}
                          <span
                            className={`price-item  decimal  ${
                              index === 1 || index === 2
                                ? 'price-item-tag decimal'
                                : ''
                            }`}
                          >
                            {item.price}
                          </span>{' '}
                          /{' '}
                          <span
                            className={`price-item ${
                              index === 1 || index === 2 ? 'price-item-tag' : ''
                            }`}
                          >
                            {item.validity}
                          </span>
                        </p>
                        <p>
                          {index === 3
                            ? item.connectedStore
                            : `Connected Store:${item.connectedStore}`}
                        </p>
                        <p>{item.automatedInventorySync}</p>
                        <p>{item.automatedOrderSync}</p>
                        <p>{item.dynamicPriceSettings}</p>
                        <p>{item.productUpdates}</p>
                        <p>products : {item.products}</p>
                        <p>{item.support}</p>
                        <div
                          className={`started${
                            index === 1 || index === 2 ? 'nav getstared' : ''
                          }`}
                          onClick={() => {
                            openModal(item);
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <div
                            id="msgSubmit"
                            className="with-errors-h3 text-center hidden"
                          ></div>
                          Buy now
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      {selectedPlan && (
        <Modal show={isModalOpen} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Payment Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="payment-modal">
              <form>
                <div className="form-group">
                  <label htmlFor="card-element"></label>
                  <div
                    id="card-element"
                    ref={cardElementRef}
                    className="card-element"
                  />
                </div>
                <br />
                {selectedPlanPrice !== null && (
                  <p className="modal-price">{selectedPlan.validity}</p>
                )}
                <br />
                <div className="dockify-pay">
                  <div className="nav getstared" onClick={handlePayment}>
                    <div
                      id="msgSubmit"
                      className="with-errors-h3 text-center hidden"
                    >
                      {' '}
                    </div>
                    <span>Pay: ${selectedPlanPrice}</span>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default AppPricing;
