import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function AppProduct() {
  const responsive = {
    superLargeDesktop: {
   
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div id='product'>
      <Container className='mt-5'>
        <Row>
          <Col className='mt-5'>
            <div className=' mt-3' data-aos='zoom-in' data-aos-duration='1000'>
              <h2 className='about d-flex borr'>Products</h2>
              <h2 className='bor d-flex'></h2>
              <h1>TRENDING HOT PRODUCTS</h1>
            </div>

            <div className='product mt-5'>
              <Carousel responsive={responsive}>
                <div className='product-card'>
                  <img src='./img/portfolio/portfolio-8.jpg' />
                </div>
                <div className='product-card'>
                  <img src='./img/portfolio/portfolio-9.jpg' />
                </div>
                <div className='product-card'>
                  <img src='./img/logo-12.jpg' />
                </div>
                <div className='product-card'>
                  <img src='./img/logo-11.jpg' />
                </div>
                <div className='product-card'>
                  <img src='./img/logo-5.jpg' />
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AppProduct;
