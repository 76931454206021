import { React, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  RiTwitterFill,
  RiFacebookCircleFill,
  RiInstagramFill,
  RiYoutubeFill,
  RiLinkedinBoxFill,
} from 'react-icons/ri';
import { BiChevronRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <Container fluid>
        <Row className="mt-5 foot py-5 px-5 ">
          <Col className="col-lg-3 mt-3 footer-top col-sm-12 col-md-12 col-12">
            <div className="footer-info foots">
              <h3>DOCKIFY</h3>
              <p className="text-justify">
                Dockify simplifies dropshipping. Our platform connects
                businesses with products, categories, and suppliers across top
                e-commerce platforms like WooCommerce, BigCommerce, Wix,
                Shopify, eBay, and more. Say farewell to manual searching.
                Elevate your dropshipping experience effortlessly with Dockify.
                <br />
                <br />
                
                <br />
                <strong>Email:</strong>contact@dockify.co
                <br />
              </p>
            </div>
            <div className="social-links mt-3 tiw">
              <a
                href="https://twitter.com/contactdoc26679"
                className="twitter tiww"
              >
                <RiTwitterFill
                  style={{ fontSize: 30 }}
                  className="icon-style"
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100083576586367"
                className="facebook  tiww px-2"
              >
                <RiFacebookCircleFill
                  style={{ fontSize: 30 }}
                  className="icon-style"
                />
              </a>
              <a
                href="https://www.instagram.com/officialdockify/"
                className="instagram tiww px-2"
              >
                <RiInstagramFill
                  style={{ fontSize: 30 }}
                  className="icon-style"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCaOy7iILpHVwMCiRfonFcUw"
                className="youtube tiww px-2"
              >
                <RiYoutubeFill
                  style={{ fontSize: 30 }}
                  className="icon-style"
                />
              </a>
              <a href="https://www.linkedin.com/in/dockify-898742229/" className=" px-2 tiww">
                <RiLinkedinBoxFill
                  style={{ fontSize: 30 }}
                  className="icon-style"
                />
              </a>
            </div>
          </Col>
          <Col className="col-lg-3 footer-links mt-3 ">
            <div className="">
              <h4 className="foots ">Our Policies</h4>

              <ul>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <Link
                    to="/terms-and-use"
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Terms of Use
                  </Link>
                </li>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <i className="bx bx-chevron-right"></i>
                  <Link
                    to="/Refund-Return-policy"
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Refund / Return Policy
                  </Link>
                </li>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <i className="bx bx-chevron-right"></i>
                  <Link
                    to="Cookies-Policy"
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Cookie Policy
                  </Link>
                </li>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <i className="bx bx-chevron-right"></i>
                  <Link
                    to="/privacy-policy"
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col className="col-lg-3 footer-links col-12">
            <div>
              <h4 className="foots mt-3">Our Company</h4>
              <ul>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <a
                    href="#about"
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <a
                    href="#contact"
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <a
                    href="#contact"
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col className="col-lg-3 footer-links col-12">
            <div>
              <h4 className="foots mt-3">Our Resources</h4>
              <ul>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <a
                    href=""
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Case Studies
                  </a>
                </li>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <Link
                    to="/FAQ-Page"
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    FAQ
                  </Link>
                </li>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <a
                    href=""
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Tutorials
                  </a>
                </li>
                <li>
                  <BiChevronRight style={{ fontSize: 20, color: '#ff4a17' }} />
                  <a
                    href=""
                    style={{ textDecoration: 'none', color: '#ff4a17' }}
                    className="foots footss span-text-1"
                  >
                    Webinar
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="text-center p-2 footer-bg">
          <Col className="">
            <div className="">
              &copy; {currentYear} Dockify. All Rights Reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
