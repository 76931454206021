import React from 'react';
import AppHome from '../Home';
import AppAbout from '../About';
import AppDropship from '../Dropship';
import AppPartner from '../Partner';
import AppPricing from '../Pricing';
import AppContact from '../Contact';
import AppProduct from '../Product';
import AppFaq from '../Faq/Faq';

function AppMain() {
  return (
    <>
      <div>
        <AppHome />
        <AppAbout />
        <AppDropship />
        <AppPartner />
        <AppProduct />
        <AppPricing />
        <AppFaq />
        <AppContact />
      </div>
    </>
  );
}

export default AppMain;
