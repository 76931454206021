import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Technology() {
  return (
    <div>
      <Container>
        <Row className="mt-5">
          <Col className="col-lg-6  col-sm-12 col-12">
            <div
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <img src="./img/services-2_v2.jpg" />
            </div>
            <p className="mt-3" data-aos="fade-right">
              Click here to know more about the Partnership Services
              &nbsp;&nbsp;
              <Link to="/partnership" className="started ">
                Partnership
              </Link>
            </p>
          </Col>
          <Col
            className="col-lg-6 col-sm-12 col-12 Dropship-text"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <h3>Expand Your Assortment with New Suppliers</h3>
            <p className="fst-italic">
              Dockify stays relevant with the market by adding new suppliers and
              trending products to the available catalog.
            </p>
            <ul className="justify-content-start Dropship-text">
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li className="listing">
                    <b className="span-text">Find & Filter Products</b> -{' '}
                    <span className="span-text-1">
                      Sort and Filter through the products catalog by price,
                      product category, product content, quantity, country of
                      origin etc.,
                    </span>
                  </li>
                </div>
              </div>{' '}
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Inventory Management</b> -{' '}
                    <span className="span-text-1">
                      Automate the Sync of products catalog based on supplier
                      schedule so that the Inventory of the store is always
                      updated.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Curated Product Lists</b> -{' '}
                    <span className="span-text-1">
                      Share a list of high–margin, in–demand products in your
                      niche to update your inventory to meet the market
                      requirements.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Proactive Alerts</b> -{' '}
                    <span className="span-text-1">
                      Dockify alerts you proactively when your products hit
                      specific thresholds, thus reducing the risk of refunds or
                      undercharging orders.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Seamless API Integration</b> -{' '}
                    <span className="span-text-1">
                      Seamless integrations with Shopify, eBay, WooCommerce and
                      robust API save you time andmoney.
                    </span>
                  </li>
                </div>
              </div>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Technology;
