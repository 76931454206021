import React from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import {
  BsCheckAll,
  BsFiles,
  BsShare,
  BsGearWide,
  BsCashCoin,
  BsCreditCard2Front,
  BsPeople,
} from 'react-icons/bs';

import Container from 'react-bootstrap/Container';

import { Link } from 'react-router-dom';

function Intergrate() {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col className='navig'>
            <div className='d-flex justify-content-between px-5 py-3 nav-content'>
              <h4 id='homeed' className='homeed'>
                <Link className='dockify-home' to='/'>
                  DOCKIFY
                </Link>
              </h4>
              {/* <div>
                <p className='mx-5 '>
                  <a href='' className='linked'>
                    LOGIN{' '}
                  </a>
                  &nbsp;&nbsp; / &nbsp;&nbsp;
                  <a href='' className='linked'>
                    GET STARTED
                  </a>
                </p>
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <div className='retails d-flex justify-content-between align-items-center col-12 nav-content'>
            <p className='interretail mx-5 nav-content'>
              Integrations - Retailers
            </p>
            {/* <p className='mx-5 '>
              <a href='/' className='links'>
                Home{' '}
              </a>
              &nbsp;&nbsp; / &nbsp;&nbsp;
              <a href='' className='links'>
                Integrations
              </a>
            </p> */}
          </div>
        </Row>
        <Row>
          <Col>
            <div className='d-flex justify-content-start mx-5 mt-5'>
              <p className='droptext'>
                Dropshipping apps for seamless integration with your favourite
                eCommerce platforms and start selling immediately.
              </p>
            </div>
          </Col>
        </Row>
        <Row className=''>
          <Col>
            <div className='d-flex justify-content-evenly cardes'>
              <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-Shopify.png'
                  className='shopify'
                />
                <Card.Body className='text-center mt-3'>
                  <Card.Title className='content'>Shopify</Card.Title>

                  <Button variant='success' className='buttons'>
                    Connect
                  </Button>
                </Card.Body>
              </Card>
              <Card style={{ maxwidth: 85 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-ebay.png'
                  className=''
                />
                <Card.Body className='text-center mt-3'>
                  <Card.Title className='content'>Ebay</Card.Title>

                  <Button variant='success' className='buttons'>
                    Connect
                  </Button>
                </Card.Body>
              </Card>
              {/* <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-amazon.png'
                  className=''
                />
                <Card.Body className='text-center mt-3'>
                  <Card.Title className='content'>Amazon</Card.Title>

                  <Button variant='success'>Connect</Button>
                </Card.Body>
              </Card> */}

              <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-BigCommerce.png'
                  className=''
                />
                <Card.Body className='text-center mt-3'>
                  <Card.Title className='content'>BigCommerce</Card.Title>

                  <Button variant='success' className='buttons'>
                    Connect
                  </Button>
                </Card.Body>
              </Card>
              <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-woocommerce.png'
                  className=''
                />
                <Card.Body className='text-center mt-3'>
                  <Card.Title className='content'>WooCommerce</Card.Title>

                  <Button variant='success button-btn' className='buttons'>
                    Connect
                  </Button>
                </Card.Body>
              </Card>
              {/* <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-etsy.png'
                  className=''
                />
                <Card.Body className='text-center mt-3'>
                  <Card.Title className='content'>Soon...</Card.Title>

                  <Button variant='success'>Connect</Button>
                </Card.Body>
              </Card> */}
              {/* <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-etsy.png'
                  className=''
                />
                <Card.Body className='text-center'>
                  <Card.Title className='content'>Soon...</Card.Title>

                  <Button variant='success'>Connect</Button>
                </Card.Body>
              </Card> */}
            </div>
          </Col>
        </Row>
        <Row className=''>
          <Col>
            {/* <div className='d-flex justify-content-evenly mt-3'>
              <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-woocommerce.png'
                  className=''
                />
                <Card.Body className='text-center'>
                  <Card.Title className='content'>WooCommerce</Card.Title>

                  <Button variant='success'>Connect</Button>
                </Card.Body>
              </Card>
              <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-BigCommerce.png'
                  className=''
                />
                <Card.Body className='text-center'>
                  <Card.Title className='content'>BigCommerce</Card.Title>

                  <Button variant='success'>Connect</Button>
                </Card.Body>
              </Card>
              <Card style={{ maxwidth: 65 }} className='card-width'>
                <Card.Img
                  variant='top'
                  src='./img/icon-etsy.png'
                  className=''
                />
                <Card.Body className='text-center'>
                  <Card.Title className='content'>Soon...</Card.Title>

                  <Button variant='success'>Connect</Button>
                </Card.Body>
              </Card>
            </div> */}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className='mt-5'>
            <div className='Dropship-text'>
              <h2>
                Dockify makes running E-Commerce business as easy as a Pie!
              </h2>
              <h2 className='bor d-flex'></h2>
              <p className='mt-3 '>
                To connect your favourite marketplace with Dockify just click on
                Connect button and complete the steps to add and link your
                store. Dont have time to do the steps, just give us a call or
                start the chat, our LIVE agents will help you in linking your
                store with Dockify as simple as 1, 2, 3...
              </p>
              <ul className='justify-content-start mt-4'>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Branded Invoicing</b> - Make your mark with a unique brand
                  identity- your invoice represents your brand.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>No Upfront Cost</b> - Start using Dockify for free- No
                  requirement of credit card details, or registration fees.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Available Integrations</b> - Dockify integrates with all
                  your favorite eCommerce platforms. Get started dropshipping
                  today!
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Maximize your Profit</b> - Our vetted dropshipping
                  suppliers discount their products up to 30% so you can gain a
                  competitive advantage and maximize your profit.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Partner Support</b> - Our dedicated support team is
                  available to chat 24/7. You’ll always have someone to assist
                  you and answer your questions about Shopify dropshipping with
                  Dockify.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>US Original Suppliers</b> - Dockify houses reliable US
                  suppliers based in the USA, you will have access to unique
                  high-quality winning products to sell - with fast shipping and
                  a fair return policy. From handmade handbags to unique custom
                  rings, our suppliers pride themselves on quality products.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Quality Suppliers</b> - With pre-vetted dropshipping
                  suppliers, all products are high-quality with dependable and
                  easy to track shipping. Our dropshipping products have
                  generous discounts so you can generate a generous profit
                  margin.
                </li>
                <li className='mt-3'>
                  <i className='icon'>
                    <BsCheckAll />
                  </i>
                  <b>Fast Shipping</b> - Appealing to US and EU markets is
                  easier when shipping meets their expectations. With fast
                  shipping and thousands of suppliers globally, you can stay
                  competitive with your Shopify dropshipping store using
                  Dockify.
                </li>
                {/* <li className="mt-3"><i className="icon"><BsCheckAll/></i><b>Repeat for Profit</b> - Find and offer more products for your customers and close more deals to get more profits!</li> */}
              </ul>
            </div>
          </Col>
        </Row>

        <Row className='mt-5'>
          <div className='  ' data-aos='zoom-in' data-aos-duration='1000'>
            <h2 className='about d-flex borr'>PARTNERSHIP</h2>
            <h2 className='bor d-flex'></h2>
            <h2 className='retailers'>RETAILERS</h2>
          </div>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 px-3 py-3 '>
              <div></div>
              <p>
                <BsFiles />
                &nbsp;&nbsp;Product Category
              </p>
              <p>
                We have several different product categories available to select
                from. If your products do not fit in any of our categories,
                please do not worry, we will create your own category. We are
                accommodating that way!!
              </p>
            </div>
          </Col>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 px-3 py-3'>
              <p>
                <BsGearWide />
                &nbsp;&nbsp;Automation
              </p>
              <p>
                {' '}
                Updating products list or price change list is not manual
                process in Dockify. We help you in automating the product
                listing updates so that you can work on your business strategy
                and promoting your business.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 py-3 px-3'>
              <p>
                <BsShare />
                &nbsp;&nbsp;Store Intergration
              </p>
              <p>
                During initial phase of our platform, we offer 3 popular
                marketplaces to integrate with. We are working on adding more
                and more stores often to cater to everyone who wants to try our
                service and platform.
              </p>
            </div>
          </Col>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 py-3  px-3'>
              <p>
                <BsCashCoin />
                &nbsp;&nbsp;Pricing Markup
              </p>
              <p>
                As per our business model, we do not take commission from
                manufacturers which can reduce your profits. Instead we offer
                the same pricing from manufacturer thus helping you to get all
                the profits.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 py-3 px-3'>
              <p>
                <BsCreditCard2Front />
                &nbsp;&nbsp;Subscription Fee
              </p>
              <p>
                Our mission is to help all small businesses to medium businesses
                to grow and achieve more. Our pricing model is comparatively low
                with regards to other service providers starting from $9.99 per
                month.
              </p>
            </div>
          </Col>
          <Col className='col-lg-6 col-12'>
            <div className='tab1 mt-3 py-3  px-3'>
              <p>
                <BsPeople />
                &nbsp;&nbsp;Partner Support
              </p>
              <p>
                As a business partner, we will always be available to you and
                help you resolve any issue or situation. Whenever you need help
                or assistance, just drop us an email and we will take care of
                the same.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className='foot mt-5'>
          <Col className='col-lg-12 col-12'>
            {/* <Footer /> */}
            {/* <div className='footer-info foots'>
                <h3>DOCKIFY</h3>
                <p>
             First Street <br/>
             Second Lane <br/>
             Chennai 600001, IN<br/>
            <br/>
            <strong>Phone:</strong> +91 XXXXX XXXXX<br/>
            <strong>Email:</strong> info@dockify.co<br/>
            </p>
                </div>
                <div className="social-links mt-3 tiw">
            <a href="https://twitter.com/OfficialDockify" className="twitter tiww"><RiTwitterFill style={{fontSize:30}} className='tiw'/></a>
            <a href="https://www.facebook.com/OfficialDockify" className="facebook  tiww px-2"><RiFacebookCircleFill style={{fontSize:30}}/></a>
            <a href="https://www.instagram.com/OfficialDockify" className="instagram tiww px-2"><RiInstagramFill style={{fontSize:30}}/></a>
            <a href="https://www.youtube.com/channel/UCaOy7iILpHVwMCiRfonFcUw" className="youtube tiww px-2"><RiYoutubeFill  style={{fontSize:30}}/></a>
            <a href="#" className=" px-2 tiww"><RiLinkedinBoxFill style={{fontSize:30}}/></a>
          </div>
                
                </Col>
                <Col className='col-lg-3 footer-links mt-3 '>
                <div className=''>
        <h4 className='foots ' >Our Policies</h4>

                <ul>
          <li><BiChevronRight style={{fontSize:20,color:"#ff4a17" }}/><a href="http://staging.dockify.co/files/Terms_Of_Use.pdf" style={{textDecoration: 'none',color:"#ff4a17"}}  className='foots footss'>Terms of Use</a></li>
          <li><BiChevronRight style={{fontSize:20,color:"#ff4a17"}} /><i class="bx bx-chevron-right"></i><a href="http://staging.dockify.co/files/Refund_Return_Policy.pdf" style={{textDecoration: 'none',color:"#ff4a17"}}  className='foots footss'>Refund / Return Policy</a></li>
          <li><BiChevronRight style={{fontSize:20,color:"#ff4a17"}}/><i class="bx bx-chevron-right"></i><a href="http://staging.dockify.co/files/Cookie_Policy.pdf" style={{textDecoration: 'none',color:"#ff4a17"}}  className='foots footss'>Cookie Policy</a></li>
          <li><BiChevronRight style={{fontSize:20,color:"#ff4a17"}}/><i class="bx bx-chevron-right"></i><a href="http://staging.dockify.co/files/Privacy_Policy.pdf" style={{textDecoration: 'none',color:"#ff4a17"}}  className='foots footss'>Privacy Policy</a></li>
        </ul>
                </div>
                </Col>
                <Col className='col-lg-3 footer-links '>
                <div>
                <h4  className='foots mt-3'>Our Company</h4>
        <ul>
          <li ><BiChevronRight style={{fontSize:20,color:"#ff4a17" }}/><a href="#about-boxes" style={{textDecoration: 'none',color:"#ff4a17"}}  className='foots footss'>About Us</a></li>
          <li><BiChevronRight style={{fontSize:20,color:"#ff4a17" }}/><a href="#contact" style={{textDecoration: 'none',color:"#ff4a17"}}  className='foots footss'>Contact Us</a></li>
          <li><BiChevronRight style={{fontSize:20,color:"#ff4a17" }}/><a href="#" style={{textDecoration: 'none',color:"#ff4a17"}}  className='foots footss'>FAQ Page</a></li>
          <li><BiChevronRight style={{fontSize:20,color:"#ff4a17" }}/><a href="#contact" style={{textDecoration: 'none',color:"#ff4a17"}}  className='foots footss'>Support</a></li>
        </ul>
                </div>
                </Col>
            </Row>
            </Container>
            <Container fluid >
            <Row className=' text-center copy'>
                <Col className=''>
                <div className="copyright">
     &copy; Copyright <strong><span style={{color:"#ff4a17"}}>Dockify</span></strong>. All Rights Reserved
  </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Intergrate;
