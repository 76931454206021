import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Model() {
  return (
    <div id="partner">
      <Container>
        <Row className="mt-5">
          <Col className="col-lg-6 col-sm-12 col-12 ">
            <div
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <img src="./img/services-1_v2.jpg" />
            </div>
            <p className="mt-3" data-aos="fade-right">
              Click here to know more about the Partnership Services
              &nbsp;&nbsp;
              <Link to="/partnership" className="started ">
                Partnership
              </Link>
            </p>
          </Col>
          <Col
            className="col-lg-6 col-sm-12 col-12 Dropship-text"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <h3>Dockify’s Unique Business Model helps Retailers</h3>
            <p className="fst-italic">
              Dockify’s Vision is to help Ordinary people become Extra-Ordinary
              Entrepreneurs. Our platform connects Suppliers & Retailers with
              their Users without any hidden agenda!
            </p>
            <ul className="justify-content-start Dropship-text">
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li className="listing">
                    <b className="span-text">AI Model</b> -{' '}
                    <span className="span-text-1">
                      At Dockify, We use our proprietary AI Engine to analyzed
                      the products perform connects Suppliers & Retailers with
                      their Users without any hidden agenda!
                    </span>
                  </li>
                </div>
              </div>{' '}
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Technology Partner</b> -{' '}
                    <span className="span-text-1">
                      Dockify ensures that you have all the Technology available
                      at your disposal to help you run your Business.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Optimized Process</b> -{' '}
                    <span className="span-text-1">
                      To use Dockify, you do not have to be a Technical Expert.
                      With basic computer skills, anyone can Start and run an
                      Online Store!!
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Less Manual Work</b> -{' '}
                    <span className="span-text-1">
                      Our Dockify platform facilitates Suppliers to Import all
                      their products by using Streamlined Process, Systems and
                      Tools.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">No Commission</b> -{' '}
                    <span className="span-text-1">
                      At Dockify, we do not take commission either from
                      Retailers or from Manufacturers thus increasing the profit
                      to both.
                    </span>
                  </li>
                </div>
              </div>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Model;
