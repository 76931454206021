import React, { useEffect } from 'react';
// import why from '../../assests/Intergration/first.webp';
// import who from '../../assests/Intergration/second.webp';
// import quick from '../../assests/Intergration/third.webp';
// import banner from '../../assests/Intergration/man-ebay.webp';
import { GiRocketFlight } from 'react-icons/gi';
import { MdLocalShipping } from 'react-icons/md';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { TbHeartHandshake } from 'react-icons/tb';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Col, Container, Row } from 'react-bootstrap';
import ebay from '../../assests/ebay.jpeg';
import shipfrom from '../../assests/shipfrom.jpeg';
import profitebay from '../../assests/profitebay.jpeg';

function Ebay() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <div className="intergration-shopify-flex">
            <div className="intergration-shopify">
              <div>
                <h1 className="Dropshipp-text" data-aos="fade-up">
                  <b> eBay Dropshipping</b>{' '}
                  <span style={{ color: '#ff4a17' }}>
                    <b>Automated</b>
                  </span>
                </h1>
                <p className="card-text py-4">
                  Start an automatic eBay dropshipping business with our
                  easy-to-use products listing tool.
                </p>
                <div className="connect-box">
                  <a href="https://app.dockify.co/" target="blank">
                    <h5 className="connect-store">
                      Connect My Store Now
                      <IoIosArrowRoundForward className="connect-arrow" />
                    </h5>
                  </a>
                </div>
              </div>{' '}
              <div>
                <img
                  src={ebay}
                  className="shopfiy-banner"
                />
              </div>{' '}
            </div>
            <div className="intergration-features">
              <div>
                <img src={shipfrom} />
              </div>{' '}
              <div>
                <h3 className="Dropshipp-text p-3">
                  <b>
                    {' '}
                    Find the Best Dropshipping Suppliers to Dropship on eBay
                  </b>
                </h3>
                <p className="card-text p-3">
                  With Dockify’s thousands of vetted dropshipping suppliers based
                  in the USA and globally, you will gain the upper hand on
                  competition through fast shipping and competitive prices. In
                  addition, Dockify is one of the best eBay dropshipping software
                  that allows batch listing products and syncing orders!
                </p>
              </div>
            </div>{' '}
            <div className="intergration-features">
              <div>
                <h3 className="Dropshipp-text p-3">
                  <b>Import Winning Dropshipping Products to Your eBay Store</b>
                </h3>
                <p className="card-text p-3">
                  Dockify is a leading dropshipping site for eBay, with its
                  constantly updated product database, finding trending and
                  profitable items to sell through eBay dropshipping has never
                  been that straightforward!
                </p>
              </div>
              <div>
                <img src={profitebay} />
       
              </div>
            </div>{' '}
            {/* <div className='intergration-features'>
          <div>
            <img src={quick} />
          </div>{' '}
          <div>
            <h5 className='shopify-intro1'>
              Quickly Finding Products to Dropship on Shopify
            </h5>
            <p className='shopify-para1'>
              Finding winning Shopify dropshipping products can be challenging.
              But worry not. With millions of products available on Dockify, you
              can easily find your niche products using our advanced filter. And
              our in-house experts are here to give you insights on trending
              products from time to time as well, taking your business to the
              next level.
            </p>
          </div>
        </div> */}
            <div className="">
              <div className="Benefits-box">
                <h1 className=" Dropshipp-text text-center">
                  <b>
                    {' '}
                    Benefits of eBay Dropshipping <br /> with Dockify
                  </b>
                </h1>
              </div>
              <div className="Benefits-gird">
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <GiRocketFlight className="icons-fa" />
                    </div>
                    <div className="Automation">
                      <h3 className="Dropshipp-text">
                        {' '}
                        <b> Dropshipping Automation</b>
                      </h3>
                    </div>
                    <p className="card-text p-3">
                      Shopify dropshipping success is dropshipping automation.
                      With Dockify’s integration with Shopify, we save you from
                      time-consuming work.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <MdLocalShipping className="icons-fa" />
                    </div>
                    <div className="Automation">
                      <h3 className="Dropshipp-text">
                        {' '}
                        <b> Fast Shipping</b>
                      </h3>
                    </div>
                    <p className="card-text p-3">
                      With fast USA local shipping and thousands of suppliers
                      globally, you can stay competitive with your Shopify
                      dropshipping store using Dockify.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <TbHeartHandshake className="icons-fa" />
                    </div>
                    <div className="Automation">
                      <h3 className="Dropshipp-text">
                        {' '}
                        <b> Reliable Suppliers</b>
                      </h3>
                    </div>
                    <p className="card-text p-3">
                      Dockify works with reliable suppliers to ensure you
                      high-quality products and fulfill your Shopify
                      dropshipping orders timely, with fair return policies.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <FaMoneyBillAlt className="icons-fa" />
                    </div>
                    <div className="Automation">
                      <h3 className="Dropshipp-text">
                        {' '}
                        <b> Great Profit</b>
                      </h3>
                    </div>
                    <p className="card-text p-3">
                      Our suppliers provide you with discounted prices to stand
                      out from other competitors and gain maximum margins.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shopify-connect-box text-center ">
            <h1 className="Dropshipp-text py-5">
              <b className="text-white">
                {' '}
                Grow Your eBay Dropshipping Business with
                <br /> Dockify Now
              </b>
            </h1>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Ebay;
