import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Optimize() {
  return (
    <div>
      <Container>
        <Row className="mt-5">
          <Col
            className="col-lg-6 col-sm-12 col-12 flex Dropship-text"
            data-aos="fade-right"
          >
            <div>
              <h3>
                Optimize your Business Operations and Processes to help you
                scale vastly
              </h3>
              <p className="fst-italic jus Dropship-text">
                Dockify is the one-stop platform to help you Start, Optimize &
                Scale Successful E-Commerce Business.
              </p>
              <ul className="justify-content-start Dropship-text">
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li className="listing">
                      <b className="span-text">Delivery Time</b> -{' '}
                      <span className="span-text-1">
                        He delivery time of an order mostly depends upon the
                        distance between the available supplier and the delivery
                        location.
                      </span>
                    </li>
                  </div>
                </div>{' '}
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Returning Customers</b> -{' '}
                      <span className="span-text-1">
                        The probability of a customer becoming a regular
                        customer is directly proportional to the kind of
                        experience they go through. A Happy customer always
                        become a brand ambassador for your business by referring
                        their family and friends and help you dramatically grow
                        your business.
                      </span>
                    </li>
                  </div>
                </div>
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Content Optimisation</b> -{' '}
                      <span className="span-text-1">
                        You can optimise your content by updating the title,
                        images and description of the products placed for sales.
                      </span>
                    </li>
                  </div>
                </div>
                <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Optimised Pricing</b> -{' '}
                      <span className="span-text-1">
                        Setting minimum advertised pricing (MAP) for a product
                        is simple and ensure that the pricing does not go below
                        MAP.
                      </span>
                    </li>
                  </div>
                </div>
                {/* <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Discover Products</b> -{' '}
                      <span className="span-text-1">
                        Look for products that match your niche from our
                        millions of product catalogue and add them to your own
                        store.
                      </span>
                    </li>
                  </div>
                </div> */}
              </ul>
              <p data-aos="fade-right" className="mt-5">
                Click here to know more about the Integrations Services
                &nbsp;&nbsp;
                <Link to="/intergration" className="started ">
                  Integration
                </Link>
              </p>
            </div>
          </Col>
          <Col className="col-lg-6 col-sm-12 col-12">
            <div data-aos="flip-left" data-aos-duration="1500">
              <img src="./img/features-3_v2.jpg" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Optimize;
