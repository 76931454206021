import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Relations() {
  return (
    <div>
      <Container>
        <Row className="mt-5">
          <Col className="col-lg-6  col-sm-12 col-12">
            <div
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <img src="./img/services-3_v2.jpg" />
            </div>
            <p className="mt-3" data-aos="fade-right">
              Click here to know more about the Partnership Services
              &nbsp;&nbsp;
              <Link to="/partnership" className="started ">
                Partnership
              </Link>
            </p>
          </Col>
          <Col
            className="col-lg-6 col-sm-12 col-12 Dropship-text"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <h3>How Dockify Empowers Your Business</h3>
            <p className="fst-italic">
              Dockify integrates your Online Store with quality suppliers,
              fulfillment warehouses and logistics providers to handle your
              Orders.
            </p>
            <ul className="justify-content-start Dropship-text">
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li className="listing">
                    <b className="span-text">Trusted Dropship Suppliers</b> -{' '}
                    <span className="span-text-1">
                      Dockify provides instant access to hundreds of vetted
                      suppliers with millions of products. Any supplier can be
                      part of Dockify as long as they go through our vetting
                      process and get authorized.
                    </span>
                  </li>
                </div>
              </div>{' '}
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Integrate with Marketplaces</b> -{' '}
                    <span className="span-text-1">
                      Dockify supports integrations with Shopify, eBay and
                      WooCommerce. Support is extended for new marketplaces
                      during product releases. You can easily import your
                      products with Dockify Platform.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Expanding Assortment</b> -{' '}
                    <span className="span-text-1">
                      Dockify stays relevant with the market by adding new
                      suppliers and trending products to the available catalog.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Minimum Subscription Fee</b> -{' '}
                    <span className="span-text-1">
                      Dockify wants to reach out to as many people as possible
                      hence the fees are kept very minimal..
                    </span>
                  </li>
                </div>
              </div>
              {/* <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Seamless API Integration</b> -{' '}
                    <span className="span-text-1">
                      Seamless integrations with Shopify, eBay, WooCommerce and
                      robust API save you time andmoney.
                    </span>
                  </li>
                </div>
              </div> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Relations;
