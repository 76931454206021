import React from 'react';
import intergrate from '../assests/Intergration/Intregration.png';
function TrendingDummy() {
  return (
    <div>
      <div className="d-flex">
        <div>
          <div className="d-flex">
            <h6 style={{ width: 200 }} className="text-start">
              CONNECT&nbsp;&nbsp;
            </h6>
            <h6 className="text-start">
              Starting your DropShipping Business is like a piece of Cake by
              connecting with Dockify.
            </h6>
          </div>
          <div className="d-flex">
            <h6 style={{ width: 200 }} className="text-start">
              INTEGRATE&nbsp;&nbsp;
            </h6>
            <h6 className="text-start">
              Dockify supports multiple Online Stores & Marketplace integrations
              in a simple One Click process.
            </h6>
          </div>
          <div className="d-flex">
            <h6 style={{ width: 200 }} className="text-start">
              EVOLVE&nbsp;&nbsp;
            </h6>
            <h6 className="text-start">
              Helping businesses to evolve in a realistic, friendly and simple
              way. Come and experience the brilliance of Dockify.
            </h6>
          </div>
        </div>
        <div>
          <img src={intergrate} height={100} />
        </div>
      </div>
    </div>
  );
}

export default TrendingDummy;
{
  /* <h6 style={{ color: '#ff4a17' }} className="textt">
                    <div className="d-flex justify-content-between">
                      <div className="border pr-5 text-start">
                        CONNECT&nbsp;-&nbsp;
                      </div>
                      <div className="px-5">
                        <span
                          // className="texttx mt-3"
                          style={{ color: 'black' }}
                        >
                          Starting your DropShipping Business is like a piece of
                          Cake by connecting with Dockify.
                        </span>
                      </div>
                    </div>
                  </h6>
                  <h6 className="textt mt-4" style={{ color: '#ff4a17' }}>
                    <div className="d-flex justify-content-between">
                      <div className="pr-5">INTEGRATE&nbsp;-&nbsp;</div>
                      <div className="px-5">
                        <span className="" style={{ color: 'black' }}>
                          Dockify supports multiple Online Stores & Marketplace
                          integrations in a simple One Click process.
                        </span>
                      </div>
                    </div>
                  </h6>
                  <h6 className="textt mt-4" style={{ color: '#ff4a17' }}>
                    <div className="d-flex justify-content-between">
                      <div className="pr-5">EVOLVE&nbsp;-&nbsp;</div>
                      <div className="px-5">
                        <span className="" style={{ color: 'black' }}>
                          Helping businesses to evolve in a realistic, friendly
                          and simple way. Come and experience the brilliance of
                          Dockify.
                        </span>
                      </div>
                    </div>
                  </h6> */
}
