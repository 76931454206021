import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  RiBlazeFill,
  RiPsychotherapyFill,
  RiTeamFill,
  RiTreasureMapFill,
} from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import Model from './Model';
import Relations from './Relations';
import Technology from './Technology';
import Distribution from './Distribution';
import Carousel from 'react-bootstrap/Carousel';

function AppPartner() {
  const [state, setState] = useState(1);
  const action = (index) => {
    setState(index);
  };
  const [first, setfirst] = useState(<Model />);
  function fun(i) {
    switch (i) {
      case 1:
        return setfirst(<Model />);

      case 2:
        return setfirst(<Technology />);

      case 3:
        return setfirst(<Relations />);

      case 4:
        return setfirst(<Distribution />);
    }
  }

  return (
    <div id="partner">
      <Container>
        <Row className="mt-5">
          <Col className="mt-5">
            <div data-aos="zoom-in" data-aos-duration="1000" className="mt-3">
              <div>
                <h2 className="borr about ">Services</h2>
                <h2 className=" bor d-flex"></h2>
              </div>
              <h1 className="">PARTNERSHIP SERVICES</h1>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-lg-3 " data-aos="zoom-in"></Col>
          <Col
            className="col-lg-12 d-flex justify-content-evenly col-12 "
            data-aos="zoom-in"
          >
            <div
              className={`'flex-container text-center cent boxed' '${
                state === 1 ? ' tab active-tab ' : ' tab '
              }`}
              role="button"
              onClick={() => {
                action(1), fun(1);
              }}
            >
              <NavLink
                role="button"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <i className={state === 1 ? 'iconed-color' : 'iconed'}>
                  <RiBlazeFill />
                </i>
                <p className={state === 1 ? 'port' : 'port-color'}>
                  Dockify AI Model
                </p>{' '}
              </NavLink>
            </div>
            <div
              className={`'flex-container text-center cent boxed' '${
                state === 2 ? ' tab active-tab ' : ' tab '
              }`}
              role="button"
              onClick={() => {
                action(2), fun(2);
              }}
            >
              <NavLink
                role="button"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <i className={state === 2 ? 'iconed-color' : 'iconed'}>
                  <RiPsychotherapyFill />
                </i>
                <p className={state === 2 ? 'port' : 'port-color'}>
                  Technology Partner
                </p>{' '}
              </NavLink>
            </div>
            <div
              className={`'flex-container text-center cent boxed' '${
                state === 3 ? ' tab active-tab ' : ' tab '
              }`}
              role="button"
              onClick={() => {
                action(3), fun(3);
              }}
            >
              <NavLink
                role="button"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <i className={state === 3 ? 'iconed-color' : 'iconed'}>
                  <RiTeamFill />
                </i>
                <p className={state === 3 ? 'port' : 'port-color'}>
                  Partner Relations
                </p>{' '}
              </NavLink>
            </div>
            <div
              className={`'flex-container text-center cent boxed' '${
                state === 4 ? ' tab active-tab ' : ' tab '
              }`}
              onClick={() => {
                action(4), fun(4);
              }}
            >
              <NavLink
                role="button"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <i className={state === 4 ? 'iconed-color' : 'iconed'}>
                  <RiTreasureMapFill />
                </i>
                <p className={state === 4 ? 'port' : 'port-color'}>
                  Distribution Partner
                </p>{' '}
              </NavLink>
            </div>
          </Col>
        </Row>
        {first}
      </Container>
      <Container fluid>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 Carousel-img"
              src="./img/wholeimage.png"
              alt="First slide"
            />
            <Carousel.Caption>
              <img
                src="./img/testimonials/3_icon-ebay_v1.png"
                className="img-size"
              />

              <p className="brand-name">ebay</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 Carousel-img"
              src="../img/wholeimage.png"
              alt="Second slide"
            />

            <Carousel.Caption>
              <img
                src="./img/testimonials/1_icon-Shopify_v1.png"
                className="img-size"
              />
              <p className="brand-name ">Shopify</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 Carousel-img"
              src="./img/wholeimage.png"
              alt="Third slide"
            />

            <Carousel.Caption>
              <img
                src="./img/testimonials/2_icon-woocommerce_v1.png"
                className="img-size"
              />
              <p className="brand-name mx-1">Woo-Commerce</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  );
}

export default AppPartner;
