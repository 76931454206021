import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Scale() {
  return (
    <Container>
      <Row className="mt-5">
        <Col
          className="col-lg-6 col-sm-12 col-12 Dropship-text"
          data-aos="fade-right"
        >
          <div>
            <h3>
              Scale Your dropshipping Logistics Operations Borderless &
              Limitless
            </h3>
            <p className="fst-italic jus">
              Dockify provides custom solutions tailored to your unique needs
              and requirements. We help you scale your brand and business to
              next level with our expertise
            </p>
            <ul className="justify-content-start Dropship-text">
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li className="listing">
                    <b className="span-text">Multiple Manufacturers</b> -{' '}
                    <span className="span-text-1">
                      Dockify does not depend on a single source of manufacturer
                      which can lead to the risk of limited production
                      capabilities. We will help you build a better supply chain
                      model, while scaling your brand.
                    </span>
                  </li>
                </div>
              </div>{' '}
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Fulfillment Centers</b> -{' '}
                    <span className="span-text-1">
                      When you are scaling your brand, we need to have
                      fulfilment centres in multiple locations to allow us to
                      sustain local demand.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Shipping Carriers</b> -{' '}
                    <span className="span-text-1">
                      Dockify does not restrict Suppliers from having multiple
                      carriers. Suppliers can choose and operate with any
                      carrier they want until they are in our approved list of
                      shipment carriers. This vetting process is to ensure
                      better experience for the customers.
                    </span>
                  </li>
                </div>
              </div>
              {/* <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Repeat for Profit</b> -{' '}
                      <span className="span-text-1">
                        Find and offer more products for your customers and
                        close more deals to get more profits!
                      </span>
                    </li>
                  </div>
                </div> */}
              {/* <div className="list-flex">
                  <div>
                    <i className="icon ">
                      <BsCheckAll />
                    </i>
                  </div>
                  <div>
                    <li>
                      <b className="span-text">Discover Products</b> -{' '}
                      <span className="span-text-1">
                        Look for products that match your niche from our
                        millions of product catalogue and add them to your own
                        store.
                      </span>
                    </li>
                  </div>
                </div> */}
            </ul>
            <p className="mt-5" data-aos="fade-right">
              Click here to know more about the Integrations Services
              &nbsp;&nbsp;
              <Link to="/intergration" className="started ">
                Integration
              </Link>
            </p>
          </div>
        </Col>
        <Col className="col-lg-6 col-sm-12 col-12">
          <div data-aos="flip-left" data-aos-duration="1500">
            <img src="./img/features-4_v2.jpg" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Scale;
