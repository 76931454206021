import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { message, Modal } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Form/form.css';
import { useLocation } from 'react-router-dom';

const Form = () => {
  const [formData, setFormData] = useState({
    email: '',
    originalEmail: '',
    firstName: '',
    phoneNumber: '',
    emailValid: true,
    successModalVisible: false,
    companyName: '',
    website: '',
  });

  const location = useLocation();
  useEffect(() => {
    const getUrl = new URLSearchParams(location.search);
    const email = getUrl.get('email');
    setFormData((prevData) => ({ ...prevData, email, originalEmail: email }));
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const { firstName, email, phoneNumber, companyName, website,originalEmail } = formData;

    const requestBody = {
      name: firstName,
      companyName: companyName,
      emailId: email ? email : '',
      contact: phoneNumber,
      website: website,
      originalMail: originalEmail,
    };

    const apiUrl = `https://gateway.dockify.co/EmailAutomation`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        console.log('Form data submitted successfully');
        message.success('Submitted successfully');
        setFormData((prevData) => ({ ...prevData, successModalVisible: true }));
      } else {
        const errorMessage = await response.text();
        message.error(`Error submitting form data: ${errorMessage}`);
      }
    } catch (error) {
      message.error('Error submitting form data:', error);
    }
  };

  const validateForm = () => {
    const isValidEmail = validateEmail(formData.email);
    setFormData((prevData) => ({ ...prevData, emailValid: isValidEmail }));
    return isValidEmail;
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const {
    firstName,
    email,
    // phoneNumber,
    companyName,
    website,
    emailValid,
    successModalVisible,
  } = formData;

  return (
    <Container>
      <Row className="justify-content-center mt-5 px-5">
        <Col sm={12} md={8} lg={6}>
          <div>
            <div>
              <p className="text-justify">
                We&apos;re excited that you&apos;re interested in joining
                Dockify! Please fill out the form below, and we&apos;ll get back
                to you as soon as possible to discuss how we can help you grow
                your business.
              </p>
            </div>
          </div>
          <div className="form-container shadow border p-4">
            <form className="form" onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  className="form-control outline-none"
                  id="name"
                  name="firstName"
                  required
                  placeholder="Name"
                  type="text"
                  value={firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <input
                  className={`form-control outline-none ${
                    emailValid ? '' : 'is-invalid'
                  }`}
                  id="email"
                  name="email"
                  required
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleChange}
                />
                {!emailValid && (
                  <div className="invalid-feedback">
                    Please enter a valid email address.
                  </div>
                )}
              </div>
              {/* <div className="mb-3">
                <input
                  className="form-control outline-none"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Mobile No"
                  type="text"
                  value={phoneNumber}
                  onChange={handleChange}
                />
              </div> */}
              <div className="mb-3">
                <input
                  className="form-control outline-none"
                  id="companyName"
                  name="companyName"
                  placeholder="Company Name"
                  type="text"
                  value={companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <input
                  className="form-control outline-none"
                  id="website"
                  name="website"
                  placeholder="Website (optional)"
                  type="text"
                  value={website}
                  onChange={handleChange}
                />
              </div>
              <div>
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <button type="submit" className="started">
                      Submit
                    </button>
                    <div
                      id="msgSubmit"
                      className="with-errors-h3 text-center hidden"
                    ></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
      <Modal visible={successModalVisible} closable={false} footer={null}>
        <div>
          <img
            src="https://media.gettyimages.com/id/1213707743/video/modern-thank-you-line-icon-animation-on-white-background.jpg?s=640x640&k=20&c=sJbSVpsvJ4UsxqvdFUjbjOdS6F6jFfEhm4K4zwDLhNg="
            alt="Thank you"
          />
          <span className=" mt-2">
            Thank you for reaching out to Dockify! We look forward to connecting
            with you soon.
          </span>
          <div style={{ textAlign: 'center' }}>
            <button
              className="started mt-2"
              onClick={() =>
                setFormData((prevData) => ({
                  ...prevData,
                  successModalVisible: false,
                }))
              }
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default Form;
