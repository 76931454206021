import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Distribution() {
  return (
    <div>
      <Container>
        <Row className="mt-5">
          <Col className="col-lg-6 col-sm-12 col-12">
            <div
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <img src="./img/services-4_v2.jpg" />
            </div>
            <p className="mt-3" data-aos="fade-right">
              Click here to know more about the Partnership Services
              &nbsp;&nbsp;
              <Link to="/partnership" className="started ">
                Partnership
              </Link>
            </p>
          </Col>
          <Col
            className="col-lg-6 col-sm-12 col-12 Dropship-text"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <h3>Streamline Your Dropshipping Process</h3>
            <p className="fst-italic">
              With Dockify’s vetted suppliers, you can rest easy knowing your
              orders are in good hands.
            </p>
            <ul className="justify-content-start Dropship-text">
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li className="listing">
                    <b className="span-text">All in One Place</b> -{' '}
                    <span className="span-text-1">
                      Integrate all of your Online Stores with Dockify. Managing
                      all of your Products and Orders in one place saves you
                      time & money.
                    </span>
                  </li>
                </div>
              </div>{' '}
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Hassle-free Order Fulfillment</b> -{' '}
                    <span className="span-text-1">
                      With Dockifys Order Life Cycle Process both you and your
                      Customer will always know the status of an Order.
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Email Support</b> -{' '}
                    <span className="span-text-1">
                      Dockifys in-house Support Team truly supports and enhances
                      your Sourcing and Selling experience. You can reach
                      Dockify’s support via Email (Coming Soon - Chat Bot and
                      more!!).
                    </span>
                  </li>
                </div>
              </div>
              <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">
                      Robust Automation for High-Volume Sellers
                    </b>{' '}
                    -{' '}
                    <span className="span-text-1">
                      Dockify helps you in building a Custom API integration,
                      Dedicated Account Support and much more! If you’re an
                      established retailer looking for the best DropShipping
                      Partner, then your serach ends here at Dockify!
                    </span>
                  </li>
                </div>
              </div>
              {/* <div className="list-flex">
                <div>
                  <i className="icon ">
                    <BsCheckAll />
                  </i>
                </div>
                <div>
                  <li>
                    <b className="span-text">Seamless API Integration</b> -{' '}
                    <span className="span-text-1">
                      Seamless integrations with Shopify, eBay, WooCommerce and
                      robust API save you time andmoney.
                    </span>
                  </li>
                </div>
              </div> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Distribution;
