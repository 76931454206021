import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function Faq() {
  const faq = [
    {
      question: 'What is included on the free trial?',
      answer:
        'Trial memberships are limited. They have some of the features of Start-up level access, but not all. You can become a paid member at any time to enjoy full features.',
    },
    {
      question: 'How do I change the plan I am on?',
      answer:
        'First, log in your Dockify account & click on Pricing Plan in the right dropdown list',
    },
    {
      question: 'How do I terminate my renewal?',
      answer:
        'To cancel your automatic renewal, you will first click on Pricing Plan in the right dropdown list',
    },
  ];
  const [input] = useState(faq);
  const [istrue, setTrue] = useState(null);
  const faqHandler = (index) => {
    setTrue(istrue === index ? null : index);
  };
  return (
    <>
      <Container>
        <Row>
          <Col>
            <div data-aos="zoom-in" data-aos-duration="1000" className="mt-3">
              <div>
                <h2 className="about">FAQ</h2>
                <h2 className="bor d-flex"></h2>
              </div>
              <h1 className="intergration">Frequently Asked Questions</h1>
            </div>
            <div className="accordion-box">
              {input.map((item, index) => {
                return (
                  <div key={index} className="accordion-boxed">
                    <div
                      onClick={() => faqHandler(index)}
                      className="d-flex justify-content-between"
                    >
                      {item.question}
                      <span style={{ cursor: 'pointer' }}>
                        {istrue === index ? '-' : '+'}
                      </span>
                    </div>
                    <div
                      className={istrue === index ? 'show-menu' : 'hide-menu'}
                    >
                      <div className="faq-answer">{item.answer}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Faq;
