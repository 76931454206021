import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';

function AllGrid({ item }) {
  return (
    <div>
      <div>
        <img src={item.imageUrl[0]} alt={item.title} className="images-img" />
      </div>
      <h6 className="grid-title">{item.title}</h6>
      <div className="d-flex justify-content-between px-2">
        <h6 className="grid-key">{item.stockStatus}</h6>
        <h6 className="grid-key">1 Business Days</h6>
      </div>
      <Button variant='secondary' className="addtoinventory">Add to Inventory</Button>
    </div>
  );
}

AllGrid.propTypes = {
  item: PropTypes.shape({
    imageUrl: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    stockStatus: PropTypes.string,
  }).isRequired,
};

function Sidebar() {
  const list = [
    1, 2, 3, 4, 5, 6, 7, 8, 1, 2, 3, 4, 5, 6, 7, 8, 1, 2, 3, 4, 5, 6, 7, 8,
  ];
  return (
    <div>
      <Container>
        <Row>
          {list.map((item, index) => {
            return (
              <Col key={index} md={2} lg={3}>
                {item}
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

function MainContent({ allproducts }) {
  return (
    <Container>
      <Row>
        {allproducts.map((item, index) => (
          <Col key={index} md={2} lg={3}>
            <AllGrid item={item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

MainContent.propTypes = {
  allproducts: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      stockStatus: PropTypes.string,
    })
  ).isRequired,
};

function Index() {
  const [allproducts, setAllProducts] = useState([]);

  useEffect(() => {
    axios
      .get(
        'https://gateway.dockify.co/DockifyProduct/GetSupplierProduct?SupplierId=4'
      )
      .then((response) => {
        setAllProducts(response.data.list);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className="d-flex">
      <Sidebar />
      <MainContent allproducts={allproducts} />
    </div>
  );
}

export default Index;
