import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import shopify from '../../assests/Intergration/icon-shopify.png';
import woo from '../../assests/Intergration/icon-woo.png';
import big from '../../assests/Intergration/icon-big.png';
import ebay from '../../assests/Intergration/icon-ebay.png';
import wix from '../../assests/Intergration/icon-wix.png';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AppHeader() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHoveringDropdown, setIsHoveringDropdown] = useState(false);
  const [isHoveringLink, setIsHoveringLink] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isHoveringDropdown && !isHoveringLink) {
        setIsDropdownOpen(false);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [isHoveringDropdown, isHoveringLink]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
    setIsHoveringDropdown(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringDropdown(false);
  };

  const handleLinkMouseEnter = () => {
    setIsHoveringLink(true);
  };

  const handleLinkMouseLeave = () => {
    setIsHoveringLink(false);
  };

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div className="orange-stripe"></div>
      <Navbar
        className="bg border-bottom navbar-container"
        expand="lg"
        style={{ backgroundColor: 'white', position: 'sticky' }}
        variant=""
        fixed="top"
      >
        <Container className="">
          <Navbar.Brand href="/" className="navi">
            <img src="./img/Dockify-Brand.01.png" className="dockify-img" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto align-items-center">
              <Link to={'/Trending'}>
                <a className="nav px-3 mx-3">PRODUCT</a>
              </Link>
              <div
                className="d-flex align-items-center"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <a
                  style={{ cursor: 'pointer' }}
                  className="nav mx-2 align-items-center"
                  onClick={handleDropdownToggle}
                  onMouseEnter={handleLinkMouseEnter}
                  onMouseLeave={handleLinkMouseLeave}
                >
                  INTERGRATION
                </a>
                <FontAwesomeIcon
                  icon={faAngleDown}
                  style={{ cursor: 'pointer' }}
                  onClick={handleDropdownToggle}
                  onMouseEnter={handleLinkMouseEnter}
                  onMouseLeave={handleLinkMouseLeave}
                />
                {isDropdownOpen && (
                  <div
                    className="intergration-box"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="dropdown-intergration shadow">
                      <div>
                        <div>
                          <Link to={'/Shopify'} onClick={handleLinkClick}>
                            <div className="dropdown-intergration-flex">
                              <div className="border-intergration">
                                <img
                                  src={shopify}
                                  className="intergration-img"
                                />
                              </div>
                              <span>Shopify </span>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <div>
                            <Link to={'/Woocommerce'} onClick={handleLinkClick}>
                              <div className="dropdown-intergration-flex">
                                <div className="border-intergration">
                                  <img src={woo} className="intergration-img" />
                                </div>
                                <span>Woocommerce </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div>
                          <Link to={'/eBay'} onClick={handleLinkClick}>
                            <div className="dropdown-intergration-flex">
                              <div className="border-intergration">
                                <img src={ebay} className="intergration-img" />
                              </div>
                              <span>ebay </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div>
                        <div>
                          <Link to={'/Bigcommerce'} onClick={handleLinkClick}>
                            <div className="dropdown-intergration-flex">
                              <div className="border-intergration">
                                <img src={big} className="intergration-img" />
                              </div>
                              <span>Bigcommerce </span>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <div>
                            <Link to={'/Wix'} onClick={handleLinkClick}>
                              <div className="dropdown-intergration-flex">
                                <div className="border-intergration">
                                  <img src={wix} className="intergration-img" />
                                </div>
                                <span>Wix </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <a href="/#pricing" className="nav px-3 mx-3">
                PRICING
              </a>
              <a href="https://app.dockify.co/" className="nav px-3 mx-3">
                LOGIN
              </a>
              <a href="https://app.dockify.co/" className="nav getstared">
                GET STARTED FREE
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AppHeader;
// import React, { useState } from 'react';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import { Link } from 'react-router-dom';
// import shopify from '../../assests/Intergration/icon-shopify.png';
// import woo from '../../assests/Intergration/icon-woo.png';
// import big from '../../assests/Intergration/icon-big.png';
// import ebay from '../../assests/Intergration/icon-ebay.png';
// import wix from '../../assests/Intergration/icon-wix.png';
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// function AppHeader() {
//   // const [istrued, setIsTrued] = useState(true);
//   const [istrue, setIsTrue] = useState(false);
//   // useEffect(() => {
//   //   if (sessionStorage.getItem('popup')) {
//   //     setIsTrued(false);
//   //   }
//   //   window.scroll({ top: 0, left: 0, behavior: 'smooth' });
//   //   const handleClickOutside = (event) => {
//   //     if (!event.target.closest('.navbar-container')) {
//   //       setIsTrue(false);
//   //     }
//   //   };
//   //   document.body.addEventListener('click', handleClickOutside);
//   //   return () => {
//   //     document.body.removeEventListener('click', handleClickOutside);
//   //   };
//   // }, []);

//   const clickHandler = () => {
//     setIsTrue(!istrue);
//   };
//   const handleLinkClick = () => {
//     setIsTrue(false);
//   };

//   return (
//     <>
//       <div className="orange-stripe"></div>
//       <Navbar
//         className="bg border-bottom navbar-container"
//         expand="lg"
//         style={{ backgroundColor: 'white', position: 'sticky' }}
//         variant=""
//         fixed="top"
//       >
//         <Container className="">
//           <Navbar.Brand href="/" className="navi ">

//             <img src='./img/Dockify-Brand.01.png' className='dockify-img'/>
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="ml-auto align-items-center">
//               <Link to={'/Trending'}>
//                 <a className="nav px-3 mx-3">PRODUCT </a>
//               </Link>
//               <div className="d-flex align-items-center">
//                 <a
//                   style={{ cursor: 'pointer' }}
//                   className="nav mx-2 align-items-center"
//                   onClick={clickHandler}
//                 >
//                   INTERGRATION
//                 </a>
//                 <FontAwesomeIcon
//                   icon={faAngleDown}
//                   onClick={clickHandler}
//                   style={{ cursor: 'pointer' }}
//                 />
//               </div>
//               <div className="intergration-box">
//                 {istrue && (
//                   <div className="dropdown-intergration">
//                     <div>
//                       <div>
//                         <Link to={'/Shopify'} onClick={handleLinkClick}>
//                           <div className="dropdown-intergration-flex">
//                             <div className="border-intergration">
//                               <img src={shopify} className="intergration-img" />
//                             </div>
//                             <span>Shopify </span>
//                           </div>
//                         </Link>
//                       </div>
//                       <div>
//                         <div>
//                           <Link to={'/Woocommerce'} onClick={handleLinkClick}>
//                             <div className="dropdown-intergration-flex">
//                               <div className="border-intergration">
//                                 <img src={woo} className="intergration-img" />
//                               </div>
//                               <span>Woocommerce </span>
//                             </div>
//                           </Link>
//                         </div>
//                       </div>
//                       <div>
//                         <Link to={'/eBay'} onClick={handleLinkClick}>
//                           <div className="dropdown-intergration-flex">
//                             <div className="border-intergration">
//                               <img src={ebay} className="intergration-img" />
//                             </div>
//                             <span>ebay </span>
//                           </div>
//                         </Link>
//                       </div>
//                     </div>
//                     <div>
//                       <div>
//                         <Link to={'/Bigcommerce'} onClick={handleLinkClick}>
//                           <div className="dropdown-intergration-flex">
//                             <div className="border-intergration">
//                               <img src={big} className="intergration-img" />
//                             </div>
//                             <span>Bigcommerce </span>
//                           </div>
//                         </Link>
//                       </div>
//                       <div>
//                         <div>
//                           <Link to={'/Wix'} onClick={handleLinkClick}>
//                             <div className="dropdown-intergration-flex">
//                               <div className="border-intergration">
//                                 <img src={wix} className="intergration-img" />
//                               </div>
//                               <span>Wix </span>
//                             </div>
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>
//               <a href="/#pricing" className="nav px-3 mx-3">
//                 PRICING
//               </a>

//               <a
//                 href="https://app.dockify.co/"
//                 className="nav px-3 mx-3"
//                 target="blank"
//               >
//                 LOGIN{' '}
//               </a>
//               <a
//                 href="https://app.dockify.co/"
//                 className="nav getstared"
//                 target="blank"
//               >
//                 GET STARTED FREE{' '}
//               </a>
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//     </>
//   );
// }

// export default AppHeader;
