import React, { useEffect } from 'react';
// import why from '../../assests/Intergration/first.webp';
// import who from '../../assests/Intergration/second.webp';
// import quick from '../../assests/Intergration/third.webp';
// import banner from '../../assests/Intergration/man-big.webp';
// import { GiRocketFlight } from 'react-icons/gi';
import { MdLocalShipping } from 'react-icons/md';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { TbHeartHandshake } from 'react-icons/tb';
import { IoIosArrowRoundForward } from 'react-icons/io';
import bigecom from '../../assests/bigecom.jpeg';
import shipfrom from '../../assests/shipfrom.jpeg';
import profitbigcom from '../../assests/profitbigcom.jpeg';
import { Col, Container, Row } from 'react-bootstrap';
function Bigcommerce() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <div className="intergration-shopify-flex">
            <div className="intergration-shopify">
              <div>
                <h1 className="Dropshipp-text" data-aos="fade-up">
                  <b>BigCommerce Dropshipping</b>{' '}
                  <span style={{ color: '#ff4a17' }}>
                    <b>Made Easy</b>
                  </span>
                </h1>
                <p className="card-text py-4">
                  All-in-one platform for your BigCommerce dropshipping needs,
                  including reliable suppliers, quality products and store
                  connection.
                </p>{' '}
                <div className="connect-box">
                  <a href="https://app.dockify.co/" target="blank">
                    <h5 className="connect-store">
                      Connect My Store Now
                      <IoIosArrowRoundForward className="connect-arrow" />
                    </h5>
                  </a>
                </div>
              </div>
              <div>
                <img
                  src={bigecom}
                  className="shopfiy-banner"
                />
              </div>
            </div>
            <div className="intergration-features">
              <div className='rounded'>
                <img src={shipfrom} className='rounded' />
              </div>{' '}
              <div>
                <h3 className="Dropshipp-text p-3">
                  <b>
                    Discover Reliable Dropshipping Suppliers for Your
                    BigCommerce Store
                  </b>
                </h3>
                <p className="card-text p-3">
                  With thousands of dropshipping suppliers based in the USA and
                  globally, you’ll have access to high-quality products to sell
                  with fast shipping. Dockify constantly augments its number of
                  vetted suppliers to provide you with trending wholesale
                  products.
                </p>
              </div>
            </div>{' '}
            <div className="intergration-features">
              <div>
                <h3 className="Dropshipp-text p-3">
                  <b>
                    Import Winning Dropshipping Products to Your BigCommerce
                    Store
                  </b>
                </h3>
                <p className="card-text p-3">
                  Dockify is a leading dropshipping site for BigCommerce; with its
                  constantly updated product database, finding trending and
                  profitable items to sell through BigCommerce dropshipping has
                  never been that straightforward!
                </p>
              </div>
              <div>
                <img src={profitbigcom} />
               
              </div>
            </div>{' '}
            <div>
              <div className="Benefits-box">
                <h1 className=" Dropshipp-text text-center">
                  <b>
                    {' '}
                    Benefits of Shopify Dropshipping <br /> with Dockify
                  </b>
                </h1>
              </div>
              <div className="Benefits-gird">
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <MdLocalShipping className="icons-fa" />
                    </div>
                    <div className="Automation Automation-para">
                      Fast Shipping
                    </div>
                    <p className="card-text p-3">
                      With fast USA local shipping and thousands of suppliers
                      globally, you can stay competitive with your Shopify
                      dropshipping store using Dockify.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <TbHeartHandshake className="icons-fa" />
                    </div>
                    <div className="Automation Automation-para">
                      Reliable Suppliers
                    </div>
                    <p className="card-text p-3">
                      Dockify works with reliable suppliers to ensure you
                      high-quality products and fulfill your Shopify
                      dropshipping orders timely, with fair return policies.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="Automation Automation-img">
                      <FaMoneyBillAlt className="icons-fa" />
                    </div>
                    <div className="Automation Automation-para">
                      Great Profit
                    </div>
                    <p className="card-text p-3">
                      Our suppliers provide you with discounted prices to stand
                      out from other competitors and gain maximum margins.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shopify-connect-box text-center ">
            <h1 className="Dropshipp-text py-5">
              <b className="text-white">
                {' '}
                Grow Your BigCommerce Dropshipping Business with
                <br /> Dockify Now
              </b>
            </h1>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Bigcommerce;
